<template>
    <div>
        <News/>
    </div>
</template>
<script>
import News from '@/components/pages/home/news-view/news-board/news-board.vue'
export default {
    components: {
        News
    }
}
</script>