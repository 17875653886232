<template>
    <div class="row bg-wave py-4">

        <div class="w-layout-1020-limit mx-auto">

            <h5 class="text-title-color my-4">{{ $t('pdpa-title') }}</h5>

            <div class="accordion mx-auto my-4" id="accordion">

                <div class="container">
                    <main>
                        <h3>藍白藍白航運股份有限公司 (以下簡稱本公司)為協助旅客搭乘「藍白航運」之訂位相關業務並遵循個人資料保護法（以下簡稱個資法）規定，向台端告知下列事項，請台端詳閱:
                        </h3>
                        <section class="section">
                            <h4>一、蒐集目的：</h4>
                            <p> 協助旅客搭乘「藍白航運」航班訂票、購票、查詢預定、取消預定及投保旅客搭船保險等相關事宜。
                            </p>
                            <h4>二、蒐集之個人資料類別：</h4>
                            <p>中英文姓名、身分證字號、護照號碼、連絡電話。</p>
                            <h4>三、個人資料利用之期間、地區、對象及方式：</h4>
                            <ul>
                                <li>(一)期間：僅於售票櫃台核對使用。相關旅客訂位資料儲存於「藍白航運訂位系統」內，歸屬藍白航運管理。</li>
                                <li>(二)地區：台端之個人資料將於台灣地區內利用。</li>
                                <li>(三)對象：搭乘藍白航運之乘客。</li>
                                <li>(四)方式：本公司蒐集、處理及利用台端之個人資料以紙本、電腦系統、電子檔案、電話、email、傳真等方式，或其他合法及合理方式使用。</li>
                            </ul>
                            <h4>四、依據個資法第三條規定，台端就本公司保有台端之個人資料得行使下列權利：
                            </h4>
                            <ul>
                                <li>(一)得向本公司申請查詢、請求閱覽或請求製給複製本。
                                </li>
                                <li>(二)得向本公司請求補充或更正，惟依法台端應為適當之釋明。
                                </li>
                                <li>(三)得向本公司請求停止蒐集、處理或利用及請求刪除，惟依法本公司因執行業務所必須者，得不依台端請求為之。
                                </li>
                                <li>台端得於營業時間內向本公司行使上述權利，聯絡方式： 08-8334866 （蔡小姐）。</li>
                            </ul>
                            <h4>五、台端不提供個人資料所致權益之影響：</h4>
                            <p>台端得自由選擇是否提供相關個人資料，惟台端若拒絕提供相關個人資料，本公司將無法提供您蒐集目的所包含之完整服務或資訊，致使台端權益受損。</p>
                            <h4>六、如有未盡事宜，請台端自行詳閱本公司公告或通知有關個人資料利用之相關內容。</h4>
                            <p>乘客表示拒絕行銷或請求閱覽、製給複製本、補通或更正、停止、搜集、處理、利用或刪除其個人資料時，聯絡窗口為：業務部 電話為：08-8334866 （蔡小姐）。</p>
                        </section>
                        <!-- 继续添加其他部分的内容 -->
                    </main>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    watch: {

    },
    methods: {
    },
    mounted() {
    }
}
</script>
<i18n src="./locale/locale.json"></i18n>
<style scope>
@import './style/pdpa.css';
</style>
