<template>
    <div class="row bg-wave">
        <!-- <ParallaxComponent
            :imgsrc="imgPath('img1')"
            :text="$t('section-para1-text')"
        /> -->
        <!-- <ShippingMapSection /> -->
        <!-- <NavSection 
            :navItems="[
                { 
                    imgsrc: 'nav_img1',
                    name: $t('route-leucosapphire'),
                    to: '/introduce-leucosapphire',
                },
                { 
                    imgsrc: 'nav_img2',
                    name: $t('route-explore-liuqiu'),
                    to: '/explore-liuqiu',
                },
                { 
                    imgsrc: 'nav_img3',
                    name: $t('route-cooperate'),
                    to: '/cooperate',
                },
                { 
                    imgsrc: 'nav_img4',
                    name: $t('route-qa'),
                    to: '/q-a',
                },
            ]"
        /> -->
        <NewsSection
            
        />
        <!-- :main_scenery="[
                { 
                    imgsrc: 'news_main_img1',
                },
                { 
                    imgsrc: 'news_main_img2',
                },
                { 
                    imgsrc: 'news_main_img3',
                },
                { 
                    imgsrc: 'news_main_img4',
                },
                
            ]" -->
        <!--{ 
                    imgsrc_main: 'news_main_img4',
                    imgsrc_sub: 'news_sub_img4',
                    content: $t('section-news-content-4'),
                    date: '2021 - 8 - 16',
                },-->
        
    </div>
</template>
<script>
// import NavSection from './components/nav-section/nav-section.vue';
// import ShippingMapSection from './components/shipping-map-section/shipping-map-section.vue';
import NewsSection from './components/news-section/news-section.vue';

export default {
    components: {
        // NavSection,
        // ShippingMapSection,
        NewsSection
    },
    data() {
        return {

        }
    },
    methods: {
        imgPath( fileName ) {
            return require('@/assets/1920/1920_' + fileName + '.png' );
        },
        imgWTPath( fileName ) {
            // WT: with text;
            // console.log(require('@/assets/375/img_with_text/' + this.$i18n.locale + '/375_' + fileName + '.png' ));
            return require('@/assets/375/img_with_text/' + this.$i18n.locale + '/375_' + fileName + '.png' );
        },
        moblieImgMPath( fileName ) {
            return require('@/assets/375/375_' + fileName + '.png' );
        },
        
        
    },
    mounted(){
        
        // console.log(this.shopsData[0]);
    },
    
}
</script>
<i18n>
{
    "en": {
        "section-news-content-1":"💙 藍白航運 船班公告\n受強烈颱風璨樹影響，考量航行安全。\n本日9/11（六) 停航 ❌\n明天9/12（日) 停航 ❌\n後天9/13  (一) 將依氣象局氣象資訊於明天(12日)12時前公告\n💗藍白航運感謝您💗",
        "section-news-content-2":"💙 藍白航運機車載運量說明\n藍白客輪船艙設計為平艙式的客艙\n主要為服務輪椅的乘客能進入客艙固定於輪椅區，改變了以往輪椅乘客搭船都在後甲板吃風喝浪的乘坐環境。\n囿於平艙式的設計，而限縮了後甲板載運機車的空間。\n基於照顧身心障礙朋友搭船的尊嚴，本公司只有犧牲部分機車族的權益。\n經航港局測量核定本公司藍白客輪可載運機車10輛，向隅者敬請見諒🙏\n藍白航運為讓身障者往返東、琉線間能有更好的乘船體驗並提供民眾更友善、高品質、舒適的航行服務，本公司全體員工會持續努力💪\n造成您的不便，請多見諒🙇🏻‍♀️🙇🏻🙇🏻‍♀️🙇🏻\n💗藍白航運感謝您的支持與鼓勵💗",
        "section-news-content-3":"藍白2號即將啟航\n藍白服務船隊專業新穎服務熱誠滿載成軍\n為旅遊愛好者、生態大使和廣大的鄉親朋友報到\n藍白船隊與您在藍色公路上同行飛揚",
        "section-news-content-4":"💙《藍白航運公告》\n即日起，藍白一號可載運機車10輛，如有載運貨物時則減為7輛。\n⚠️機車請直接至碼頭向服務人員購票\n☎️聯絡資訊☎️\n➢東港售票口\n(08)8334168\n➢ 小琉球售票口\n(08)8614858\n💗藍白航運感謝您的搭乘💗",
        "section-news-content-5":"🚢藍白二號船報到🚢\n藍白航運中秋運能大提升\n趕快來體驗\n全新的設施\n首屈一指的服務\n最方便的手機通關\n超密集的航班\n絕對放鬆舒適的旅程\n💙唯有藍白💙\n才能許您一個用心的連假真心的款待"
    },
    "zh-TW": {    
        "section-news-content-1":"💙 藍白航運 船班公告\n受強烈颱風璨樹影響，考量航行安全。\n本日9/11（六) 停航 ❌\n明天9/12（日) 停航 ❌\n後天9/13  (一) 將依氣象局氣象資訊於明天(12日)12時前公告\n💗藍白航運感謝您💗",
        "section-news-content-2":"💙 藍白航運機車載運量說明\n藍白客輪船艙設計為平艙式的客艙\n主要為服務輪椅的乘客能進入客艙固定於輪椅區，改變了以往輪椅乘客搭船都在後甲板吃風喝浪的乘坐環境。\n囿於平艙式的設計，而限縮了後甲板載運機車的空間。\n基於照顧身心障礙朋友搭船的尊嚴，本公司只有犧牲部分機車族的權益。\n經航港局測量核定本公司藍白客輪可載運機車10輛，向隅者敬請見諒🙏\n藍白航運為讓身障者往返東、琉線間能有更好的乘船體驗並提供民眾更友善、高品質、舒適的航行服務，本公司全體員工會持續努力💪\n造成您的不便，請多見諒🙇🏻‍♀️🙇🏻🙇🏻‍♀️🙇🏻\n💗藍白航運感謝您的支持與鼓勵💗",
        "section-news-content-3":"藍白2號即將啟航\n藍白服務船隊專業新穎服務熱誠滿載成軍\n為旅遊愛好者、生態大使和廣大的鄉親朋友報到\n藍白船隊與您在藍色公路上同行飛揚",
        "section-news-content-4":"💙《藍白航運公告》\n即日起，藍白一號可載運機車10輛，如有載運貨物時則減為7輛。\n⚠️機車請直接至碼頭向服務人員購票\n☎️聯絡資訊☎️\n➢東港售票口\n(08)8334168\n➢ 小琉球售票口\n(08)8614858\n💗藍白航運感謝您的搭乘💗",
        "section-news-content-5":"🚢藍白二號船報到🚢\n藍白航運中秋運能大提升\n趕快來體驗\n全新的設施\n首屈一指的服務\n最方便的手機通關\n超密集的航班\n絕對放鬆舒適的旅程\n💙唯有藍白💙\n才能許您一個用心的連假真心的款待"
    }
}
</i18n>
<style scope>
@import './style/introduce.css';
</style>