<template>
    <div 
     class="col-md-8 col-12 my-2" 
     id="post"
    >
        <div class="news-content">
            <div class="d-flex justify-content-end date mx-4">
                {{ getDateString( content.created_time )}}
            </div>
            <img 
             :src="content.full_picture"
             class="img-fluid img"
            >
            <div class="mx-4 my-4 text-pre-wrap text-start content">{{ content.message }}
            </div>
            <ArrowLR
            @emitLeft="changePost('next')"
            @emitRight="changePost('pre')"
            :hasLeft="hasNextPost()"
            :hasRight="hasPrePost()"
            />
        </div>
    </div>
</template>
<script>
import ArrowLR from '@/components/layout-components/arrow-left-right-buttons/arrow-left-right-buttons.vue';
export default {
    props: {
        lists: Array,
    },
    components: {
        ArrowLR,
    },
    data() {
        return {
            content: {},
            y_index: 0,
            m_index: 0,
            e_message: "",
        }
    },
    watch: {
        '$route': function() {
            this.updateContent();
        }
    },
    methods: {
        updateContent() {
            for ( let i = 0 ; i < this.lists.length ; i++ ) {
                if ( this.lists[i].year === parseInt(this.$route.params.year) ) {
                    for ( let monthIndex = 0 ; monthIndex < this.lists[i].list.length ; monthIndex++ ) {
                        if ( this.lists[i].list[monthIndex].month === parseInt(this.$route.params.month) ) {
                            this.content = this.lists[i].list[monthIndex].content[this.$route.params.id];
                            this.m_index = monthIndex;
                        }
                        
                    }
                    this.y_index = i;
                }
            }
        },
        getDateString( date ) {
            let dateObject = new Date(date);
            return dateObject.getFullYear() + ' - ' + 
                   (dateObject.getMonth()+1) + ' - ' + 
                   dateObject.getDate();
        },
        changePost( cmd ) {
            let id = parseInt(this.$route.params.id) ;

            try { 
                if ( cmd === 'pre' ) {
                    id--;
                }
                else if ( cmd === 'next' ) {
                    id++;
                }
                let y_i = this.y_index;
                let m_i = this.m_index;
                // console.log(id, this.lists[y_i].list[m_i].content.length)

                if ( id < 0 ) {
                    m_i--;
                    id = 0;
                }
                else if ( id >= this.lists[y_i].list[m_i].content.length ) {
                    m_i++;
                    id = 0;
                }

                if ( m_i < 0 ) {
                    y_i--;
                    m_i = this.lists[y_i].list.length-1;
                    id = this.lists[y_i].list[m_i].content.length-1;
                }
                else if ( this.lists[y_i].list[m_i] === undefined ) {
                    y_i++;
                    m_i = 0;
                }

                // console.log(y_i,m_i,id,this.lists[y_i].year,this.lists[y_i].list[m_i].month);
                this.$router.push({
                    name: "News",
                    params: {
                        year: this.lists[y_i].year,
                        month: this.lists[y_i].list[m_i].month,
                        id: id
                    }
                })

            } catch( e ) {
                this.e_message = e;
            }

        },
        hasPrePost: function() {
            try { 
                let id = parseInt(this.$route.params.id) ;
                id--;
                let y_i = this.y_index;
                let m_i = this.m_index;
                if ( id < 0 ) {
                    m_i--;
                    id = 0;
                }
                if ( m_i < 0 ) {
                    y_i--;
                    m_i = this.lists[y_i].list.length-1;
                    id = this.lists[y_i].list[m_i].content.length-1;
                }
                console.log('pre-post', {
                    year: this.lists[y_i].year,
                    month: this.lists[y_i].list[m_i].month,
                    id: id
                });
                return true;

            } catch( e ) {
                return false;
            }
        },
        hasNextPost: function() {
            try { 
                let id = parseInt(this.$route.params.id) ;
                id++;
                let y_i = this.y_index;
                let m_i = this.m_index;
                if ( id >= this.lists[y_i].list[m_i].content.length ) {
                    m_i++;
                    id = 0;
                }
                if ( this.lists[y_i].list[m_i] === undefined ) {
                    y_i++;
                    m_i = 0;
                }
                console.log('next-post', {
                    year: this.lists[y_i].year,
                    month: this.lists[y_i].list[m_i].month,
                    id: id
                });
                return true;

            } catch( e ) {
                this.e_message = e;
            }

        }
    },
    mounted() {
        this.updateContent();
    },
    
}
</script>
<style scope>
@import './style/news-content.css';
</style>