<template>
    <div>
        <Pay />
    </div>
</template>
<script>
import Pay from '@/components/pages/shop/order/pay/pay-creditcard.vue';
export default {
    components: {
        Pay,
    }
}
</script>