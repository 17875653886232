<template>
    <div class="row bg-wave py-4 w-layout-1020-limit mx-auto">
        <nav class="mx-auto" aria-label="breadcrumb">
            <ol class="breadcrumb justify-content-lg-start justify-content-center ">
                <li class="breadcrumb-item link-leuco-blue"><router-link class="no-text-decoration" to="/explore-liuqiu">{{ $t("route-explore-liuqiu") }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t("route-cooperate") }}</li>
            </ol>
        </nav>
        <h5 class="text-title-color my-4">{{$t('route-cooperate')}}</h5>
        <div class="row justify-content-center mx-auto">
            <Store 
            v-for="(item, index) in takeListRange( parseInt($route.query.p), pageCapacity, stores )" :key="index"
            :image="item.image"
            :name="item.name"
            :desc="item.desc"
            :link="item.link"
            :isEnd="parseInt(index)+1===pageCapacity"
            />
        </div>
        
        <Pagination 
         :totalCount="stores.length"
         :capacity="pageCapacity"
        />
    </div>
</template>
<script>
import Store from './components/store-component/store.vue';
import Pagination from '@/components/layout-components/pagination/pagination.vue';
export default {
    components: {
        Store,
        Pagination,
    },
    data() {
        return {
            stores: [],
            pageCapacity: 8
        }
    },
    methods: {
        init() {
            this.setStores();
        },
        setStores() {
            this.stores = this.getArrayFromJsonText('stores');
        }
    },
    mounted() {
        this.init();
    },
}
</script>
<i18n src="./locale/cooperation-list.json"></i18n>
<style scope>
@import './style/cooperate-stores.css';
</style>