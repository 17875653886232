<template>
    <div class="py-4 fs-2">
        {{ $t("modal-paying-status-waiting-body-text-1") }}<br>
        {{ $t("modal-paying-status-waiting-body-text-2") }}
    </div>
</template>
<script>
export default {
    
}
</script>
<i18n>
{
    "en": {
        "modal-paying-status-waiting-body-text-1": "Transaction processing",
        "modal-paying-status-waiting-body-text-2": "Please wait"
    },
    "zh-TW": {
        "modal-paying-status-waiting-body-text-1": "交易進行中",
        "modal-paying-status-waiting-body-text-2": "請稍候"
    }
}
</i18n>