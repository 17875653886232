var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-mobile"},[_c('splide',{attrs:{"options":_vm.options}},_vm._l((_vm.infos),function(item,index){return _c('splide-slide',{key:index},[_c('img',{staticClass:"info-image-width",attrs:{"src":require('@/assets/375/375_leuco_intro_img' +
            (parseInt(index) + 1) +
            '.png'),"alt":""}}),_c('h5',{staticClass:"text-title-color my-4"},[_vm._v(_vm._s(item.title))]),_vm._l((item.datas),function(subItem,subIndex){return _c('div',{key:subIndex},[(subItem.type === 'default')?_c('div',{staticClass:"info"},[_c('div',{staticClass:"title my-2"},[_vm._v(" "+_vm._s(subItem.sub_title)+" ")]),(subItem.content !== '')?_c('p',{staticClass:"content"},[_vm._v(_vm._s(subItem.content)+" ")]):_vm._e(),(item.datas[parseInt(subIndex) + 1] !== undefined)?_c('hr'):_c('div',{staticClass:"my-4 text-white"},[_vm._v(".")])]):(subItem.type === 'default-title')?_c('div',{staticClass:"info"},[_c('div',{staticClass:"title my-2"},[_vm._v(" "+_vm._s(subItem.sub_title)+" ")])]):_vm._e(),(subItem.type === 'list')?_c('div',{staticClass:"info"},[_c('div',{staticClass:"title my-2"},[_vm._v(" "+_vm._s(subItem.sub_title)+" ")]),_c('ul',{staticClass:"content"},_vm._l((subItem.content),function(listItem,listIndex){return _c('li',{key:listIndex},[_vm._v(" "+_vm._s(listItem)+" ")])}),0),(item.datas[parseInt(subIndex) + 1] !== undefined)?_c('hr'):_c('div',{staticClass:"my-4 text-white"},[_vm._v(".")])]):_vm._e(),(subItem.type === 'ticket')?_c('table',{staticClass:"info w-100",attrs:{"cellpadding":"4"}},_vm._l((subItem.content),function(ticketItem,ticketIndex){return _c('tr',{key:ticketIndex,staticClass:"content"},_vm._l((ticketItem),function(rowItem,rowIndex){return _c('td',{key:rowIndex,class:{
                'ticket-tr':
                  subItem.content[parseInt(ticketIndex) + 1] !== undefined,
              },attrs:{"colspan":parseInt(rowIndex) === 0 ? 2 : 1}},[_c('span',{class:{
                  'ticket-title': rowItem.type === 'title',
                },staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(rowItem.name)+" ")])])}),0)}),0):_vm._e(),(subItem.type === 'ticket')?_c('div',{staticClass:"p-1 text-start"},[_c('ul',{staticStyle:{"color":"red","font-weight":"bold","list-style-type":"none","padding-left":"0"}},[_c('li',{staticClass:"custom-item"},[_vm._v("* 團體票價請洽客服")]),_c('li',{staticClass:"custom-item"},[_vm._v("* 國旅卡請至現場購票（線上訂票不配合國旅卡）")])])]):_vm._e()])})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }