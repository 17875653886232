import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home/Home.vue'
import Home_MainView from '@/views/Home/childView/main.vue';
import Home_LeucoIntro_View from '@/views/Home/childView/leuco-intro.vue';
import Home_ExploreLiuqiu_View from '@/views/Home/childView/explo-liuqiu.vue';
import Home_CooperateStores from '@/views/Home/childView/coope-stores.vue';
import Home_Leuco_QA_View from '@/views/Home/childView/leuco-qa.vue';
import Home_Leuco_PDPA_View from '@/views/Home/childView/leuco-pdpa.vue';

import Home_News from '@/views/Home/childView/news.vue';

import Shop from '@/views/Shop/Shop.vue';
import Shop_Booking_Date from '@/views/Shop/childView/booking/ticket/booking-date.vue';
import Shop_Booking_Shipping from '@/views/Shop/childView/booking/ticket/booking-shipping.vue';
import Shop_Booking_FillCustomerInfo from '@/views/Shop/childView/booking/ticket/booking-customer-form.vue';

import Shop_Order_Search from '@/views/Shop/childView/order/order-search.vue';
import Shop_Order_Info from '@/views/Shop/childView/order/order-info.vue';
import Shop_Order_Pay from '@/views/Shop/childView/order/order-pay.vue';
import Shop_Order_PayRedirect from '@/views/Shop/childView/order/order-pay-redirect.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'MainPage',
        component: Home_MainView,
        meta: {
          bookingDirect: false,
        }
      },
      {
        path: '/introduce-leucosapphire',
        name: 'Introduce-LeucoSapphire',
        component: Home_LeucoIntro_View,
        meta: {
          bookingDirect: false,
        }
      },
      {
        path: '#sec-booking',
        name: 'Booking',
      },
      {
        path: '/explore-liuqiu',
        name: 'Explore-Xiaoliuqiu',
        component: Home_ExploreLiuqiu_View,
        meta: {
          bookingDirect: false,
        }
      },
      {
        path: '/cooperate',
        name: 'Cooperate-Stores',
        component: Home_CooperateStores,
        meta: {
          bookingDirect: false,
        }
      },
      {
        path: '/q-a',
        name: 'Common-problem',
        component: Home_Leuco_QA_View,
        meta: {
          bookingDirect: false,
        }
      },
      {
        path: '/pdpa',
        name: 'Common-pdpa',
        component: Home_Leuco_PDPA_View,
        meta: {
          bookingDirect: false,
        }
      },
      {
        path: '/news/:year/:month/:id',
        name: 'News',
        component: Home_News,
        meta: {
          bookingDirect: false,
        }
      },
    ]
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    children: [
      {
        path: '/shop/booking/t/date',
        name: 'Booking_Date',
        component: Shop_Booking_Date,
        meta: {
          bookingDirect: true,
        }
      },
      {
        path: '/shop/booking/t/ship',
        name: 'Booking_Ship',
        component: Shop_Booking_Shipping,
        meta: {
          bookingDirect: true,
        }
      },
      {
        path: '/shop/booking/t/fill-customer-info',
        name: 'Booking_FillCustomerInfo',
        component: Shop_Booking_FillCustomerInfo,
        meta: {
          bookingDirect: true,
        }
      },
      {
        path: '/shop/order/search',
        name: 'Order_Search',
        component: Shop_Order_Search,
        meta: {
          bookingDirect: true,
        }
      },
      {
        path: '/shop/order',
        name: 'Order_Info',
        component: Shop_Order_Info,
        meta: {
          bookingDirect: true,
        }
      },
      {
        path: '/shop/pay',
        name: 'Order_Pay',
        component: Shop_Order_Pay,
        meta: {
          bookingDirect: true,
        }
      },
      {
        path: '/shop/pay-redirect',
        name: 'Order_PayRedirect',
        component: Shop_Order_PayRedirect,
        meta: {
          bookingDirect: true,
        }
      }
    ],
  },
  { 
    path: '/home', 
    redirect: '/' 
  },
  {
    path: '/*',
    redirect: '/'
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
        return {selector: to.hash}
        //Or for Vue 3:
        //return {el: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  },
})

export default router
