<template>
  <div class="info-mobile">
    <splide :options="options">
      <splide-slide
        v-for="(item, index) in infos"
        :key="index"
      >
        <img
          :src="
            require('@/assets/375/375_leuco_intro_img' +
              (parseInt(index) + 1) +
              '.png')
          "
          alt=""
          class="info-image-width"
        />
        <h5 class="text-title-color my-4">{{ item.title }}</h5>
        <div
         v-for="(subItem, subIndex) in item.datas" :key="subIndex"
        >
          <div class="info" v-if="subItem.type === 'default'">
            <div class="title my-2">
              {{ subItem.sub_title }}
            </div>
            <p class="content" v-if="subItem.content !== ''">{{ subItem.content }}
            </p>
            <hr v-if="item.datas[parseInt(subIndex) + 1] !== undefined" />
            <div v-else class="my-4 text-white">.</div>
          </div>
          <div class="info" v-else-if="subItem.type === 'default-title'">
            <div class="title my-2">
              {{ subItem.sub_title }}
            </div>
          </div>
          <div class="info" v-if="subItem.type === 'list'">
            <div class="title my-2">
              {{ subItem.sub_title }}
            </div>
            <ul class="content">
              <li
                v-for="(listItem, listIndex) in subItem.content"
                :key="listIndex"
              >
                {{ listItem }}
              </li>
            </ul>
            <hr v-if="item.datas[parseInt(subIndex) + 1] !== undefined" />
            <div v-else class="my-4 text-white">.</div>
          </div>
          <table
            class="info w-100"
            cellpadding="4"
            v-if="subItem.type === 'ticket'"
          >
            <tr
              class="content"
              v-for="(ticketItem, ticketIndex) in subItem.content"
              :key="ticketIndex"
            >
              <td
                v-for="(rowItem, rowIndex) in ticketItem"
                :key="rowIndex"
                :colspan="parseInt(rowIndex) === 0 ? 2 : 1"
                :class="{
                  'ticket-tr':
                    subItem.content[parseInt(ticketIndex) + 1] !== undefined,
                }"
              >
                <span
                  style="white-space:pre-wrap"
                  :class="{
                    'ticket-title': rowItem.type === 'title',
                  }"
                >
                  {{ rowItem.name }}
                </span>
              </td>
            </tr>
          </table>
          <div v-if="subItem.type === 'ticket'" class="p-1 text-start">
            <ul style="color:red; font-weight: bold; list-style-type: none; padding-left: 0;">
              <li class="custom-item">&ast; 團體票價請洽客服</li>
              <li class="custom-item">&ast; 國旅卡請至現場購票（線上訂票不配合國旅卡）</li>
            </ul>
          </div>
        </div>
      </splide-slide>
    </splide>
  </div>
</template>
<script>
export default {
  props: {
    infos: Object,
  },
  data() {
    return {
      options: {
        rewind: true,
        perPage: 2,
        perMove: 1,
        focus: "center",
        lazyLoad: "nearby",
        width: "120vw",
        fixedWidth: "68vw",
        gap: "40px",
        trimSpace: false,
        arrows: true,
        classes: {
          prev  : 'splide__arrow--prev info-pre',
          next  : 'splide__arrow--next info-next',
        },
        arrowPath: " ",
      },
    };
  },
};
</script>