<template>
    <div>
        <PayRedirect />
    </div>
</template>
<script>
import PayRedirect from '@/components/pages/shop/order/pay/pay-redirect.vue';
export default {
    components: {
        PayRedirect,
    }
}
</script>