<template>
    <div>
        <div class="title-row">
            <span class="title">{{ $t('title-pay-redirect-waiting') }}</span>
        </div>
        <div class="content my-4 py-4">
            <div v-if="status==='waiting'">
                <div class="fs-2">
                    {{ $t("body-text-waitng") }}
                </div>
                <div class="spinner-border text-main-color my-4" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="status==='fail'">
                <div class="my-3">
                    <img class="model-icon" src="@/assets/icons/icon_error.svg">
                </div>
                <div class="fs-2 text-danger">
                    {{ $t("body-text-fail") }}
                </div>
                <div class="py-4">
                </div>
            </div>
            <div v-if="status==='success'">
                <div class="my-3">
                    <img class="model-icon" src="@/assets/icons/icon_success.svg">
                </div>
                <div class="fs-2">
                    {{ $t("body-text-success") }}
                </div>
                <div class="py-4">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";

export default {
    data() {
        return {
            orderData: {},
            status: "waiting", // status: "waiting", "fail", "success",
            ts: {}
        }
    },
    methods: {
        async getOrderData() {
            let orderInfoResult = await this.ts.getOrderInfo(this.$route.query.o_n, this.$route.query.o_i) ;
            if ( orderInfoResult.status === 200 && orderInfoResult.data.paid ) {
                this.orderData = orderInfoResult.data ;
                return true ;
            }
            else {
                return false ;
            }
        },
        successRedirectToOrderPage() {
            let vm = this ;
            setTimeout( function() {
                vm.$router.push({
                    name: "Order_Info",
                    query: {
                        o_n: vm.$route.query.o_n,
                        o_i: vm.$route.query.o_i,
                        is_booking: true,
                    }
                });
            }, 3000);
        },
        failRedirectToPayPage() {
            let vm = this ;
            setTimeout( function() {
                vm.$router.push({
                    name: "Order_Pay",
                    query: {
                        o_n: vm.$route.query.o_n,
                        o_i: vm.$route.query.o_i,
                        is_booking: true,
                    }
                });
            }, 3000);
        }
    },
    async mounted() {
        this.ts = new TicketService() ;

        let result = await this.getOrderData() ;
        if ( result ) {
            this.status = "success" ;
            this.successRedirectToOrderPage() ;
        }
        else {
            let vm = this ;
            setTimeout( async function() {
                result = await vm.getOrderData()  ;
                if ( result ) {
                    vm.status = "success" ;
                    this.successRedirectToOrderPage() ;
                }
                else {
                    vm.status = "fail" ;
                }
            } );
        }
    },
}
</script>
<i18n>
{
    "en": {
        "title-pay-redirect-waiting": "Waiting",
        "title-pay-redirect-fail": "Fail",
        "title-pay-redirect-success": "Success",
        "body-text-waitng": "Transaction processing\nPlease wait",
        "body-text-fail": "Transaction failed\nPlease confirm all that the credit card information is correct",
        "body-text-success": "Transaction Successful"
    },
    "zh-TW": {
        "title-pay-redirect-waiting": "請稍候",
        "title-pay-redirect-fail": "發生錯誤",
        "title-pay-redirect-success": "交易成功",
        "body-text-waitng": "交易進行中",
        "body-text-fail": "交易發生錯誤",
        "body-text-success": "交易成功"
    }
}
</i18n>