<template>
  <div>
    <ul class="fixed_buttons">
      <ol
      class="bg-line"
      @mouseover="mouseOverLineBtn=(innerWidth>576?true:false)"
      v-on:click="clickLink(lineLink)">
        <img
        class="wh-40px"
        src="@/assets/1920/1920_icon_line_nbg.svg"
        >
      </ol>
    </ul>
    <transition name="fade">
      <div class="panel-hovershow shadow" v-if="mouseOverLineBtn">
        <div class="panel-header bg-line">
          <div class="d-flex align-item-center">
            <img
            class="mx-2"
            src="@/assets/1920/1920_icon_line_nbg.svg"
            >
            <span class="text-white fw-bold">LINE</span>
          </div>

          <button
          type="button"
          class="btn-close btn-close-white mx-2"
          aria-label="Close"
          v-on:click="mouseOverLineBtn=false"
          >
          </button>
        </div>
        <div class="px-2">
          <div class="my-1">
            <div class="panel-text-link">
              <a class="link-leuco-blue no-text-decoration" :href="lineLink" target="_blank">{{lineLink}}</a>
            </div>
            <img class="w-75" src="@/assets/1920/1920_line_qr.png">
          </div>
          <div class="text-pre-wrap">{{$t('line-qr-text')}}
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>
<script>
// import getEnv from "@/utils/env.js";
export default {
  data() {
    return {
      mouseOverLineBtn: false,
      lineLink: 'https://line.me/ti/p/@728eztol',
    }
  },
  methods: {
    clickLink(url) {
      window.open(url);
    },
  },
  mounted() {
    this.resizeEventHandler();

    // const CHATWOOT_BASE_URL = getEnv("VUE_APP_CHATWOOT_BASE_URL");
    // const CHATWOOT_WEBSITE_TOKEN = getEnv("VUE_APP_CHATWOOT_WEBSITE_TOKEN");
    //
    // (function(d, t) {
    //   var BASE_URL = CHATWOOT_BASE_URL;
    //   var g = d.createElement(t),
    //     s = d.getElementsByTagName(t)[0];
    //   g.src = BASE_URL + "/packs/js/sdk.js";
    //   s.parentNode.insertBefore(g, s);
    //   g.onload = function() {
    //     window.chatwootSDK.run({
    //       websiteToken: CHATWOOT_WEBSITE_TOKEN,
    //       baseUrl: BASE_URL,
    //     });
    //   };
    // })(document, "script");
    // window.chatwootSettings = {
    //   hideMessageBubble: true,
    // }

    // window.$chatwoot.setLocale(localStorage.getItem("footmark-lang"));

  },
};
</script>
<i18n>
{
  "en": {
    "line-qr-text": "Add LINE Friends\n via QR Code"
  },
  "zh-TW": {
    "line-qr-text": "有任何問題歡迎透過Line聯繫我們"
  }
}
</i18n>
<style scope>
@import './style/fixed-buttons.css';
</style>
