<template>
    <div class="col-sm-6 col-12 coop-store">
        <div class="img-store">
            <img 
            :src="
                innerWidth > 768 ? imgPath(image) :
                innerWidth > 576 ? imgM768Path(image) :
                imgMPath(image)
            "
            class="img-fluid"
            >
        </div>
        <div class="text text-start">
            <div class="title text-title-color">{{name}}</div>
            <div class="desc ellipsis">
                {{desc}}
            </div>
            <button 
             class="mainbtn route-btn text-nowrap bottom"
             v-on:click="openNewTabLink(link)"
            >
                {{ $t('more') }}
            </button>
        </div>
        <hr class="breakpoint-lower-w576px" v-if="!isEnd">
    </div>
</template>
<script>
export default {
    props: {
        name: String,
        image: String,
        link: String,
        desc: String,
        isEnd: Boolean,
    },
    methods: {
       openNewTabLink( url ) {
           window.open(url);
       } 
    },
    mounted() {
        this.resizeEventHandler();
    }
}
</script>
<style scope>
@import './style/store.css';
</style>