<template>
    <div>
        <InfoForm />
    </div>
</template>
<script>
import InfoForm from '@/components/pages/shop/booking/info-form/info-form.vue';
export default {
    components: {
        InfoForm,
    }
}
</script>