<template>
    <div class="row bg-main-banner main-banner-height p-banner" id="sec-booking">
        
        <!--trip date select panel-->
        <div class="col-xxl-6 col-xl-8 col-md-10 col-sm-12 mx-auto px-0 homepage-booking-section-max-w">
            <SelectDate />
        </div>


    </div>
</template>
<script>
import SelectDate from '@/components/pages/shop/booking/select-date/select-date.vue';
export default {
    components: {
        SelectDate,
    }
}
</script>
<style scope>
@import './style/booking-section.css';
</style>

