import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/styles/bootstrap-icons/bootstrap-icons.css';
import AOS from 'aos';
import VueSplide from '@splidejs/vue-splide';

import 'aos/dist/aos.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import WindowSizeMixin from './mixin/WindowSizeMixin.js'
import DateMixin from './mixin/DateMixin.js'
import ImagePathMixin from './mixin/ImagePathMixin.js'
import ArrayMixin from './mixin/ArrayMixin.js'
import I18nMixin from './mixin/I18nMixin.js'
import InputValidateMixin from './mixin/InputValidateMixin.js';


import SmoothPicker from 'vue-smooth-picker'
import 'vue-smooth-picker/dist/css/style.css'

// css
import '@/styles/base.css';
import '@/styles/main.css';
import '@/styles/shop.css';
import '@/styles/custom-smooth-picker.css';


import i18n from './utils/i18n'
import getEnv from './utils/env.js'

Vue.use( VueSplide );
Vue.use( SmoothPicker )

Vue.config.productionTip = false

Vue.mixin(WindowSizeMixin)
Vue.mixin(DateMixin)
Vue.mixin(ImagePathMixin)
Vue.mixin(ArrayMixin)
Vue.mixin(I18nMixin)
Vue.mixin(InputValidateMixin)

Sentry.init({
  Vue,
  dsn: getEnv('VUE_APP_SENTRY_DSN'),
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [ getEnv('VUE_APP_APP_URL'), /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

AOS.init();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
