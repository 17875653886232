<template>
    <div>
        <PDPA/>
    </div>
</template>
<script>
import PDPA from '@/components/pages/home/leuco-pdpa-view/pdpa/pdpa.vue'
export default {
    components: {
        PDPA
    }
}
</script>