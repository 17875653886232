export default {
    methods: {
        dateIncrease( day ) {
            Date.prototype.addDays = function(days) {
                this.setDate(this.getDate() + days);
                return this;
            }

            let dateObject = new Date();
            dateObject = dateObject.addDays(day);
            let year = dateObject.getFullYear();
            let month = dateObject.getMonth()+1;
            month = month >= 10 ? month : '0' + month ;
            let date = dateObject.getDate();
            date = date >= 10 ? date : '0' + date ;

            return year + '-' + month + '-' + date ;
        },
        getToday() {
            let date = new Date();
            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // 1月為 0 ;
            let dd = date.getDate();
            if (dd < 10) {
              dd = "0" + dd;
            }
            if (mm < 10) {
              mm = "0" + mm;
            }
            
            return {
              year: yyyy,
              md: mm + "-" + dd,
              day: this.getWeekday( yyyy+'-'+mm+'-'+dd ),
            };
        },
        getWeekday: function( _time, locale ) {
            // date => weekday ;
            let day = new Date(_time) ;
            var weekday = new Array(7);
            
            weekday[0] = locale==='zh-TW'||locale===undefined?"日":"Sun";
            weekday[1] = locale==='zh-TW'||locale===undefined?"一":"Mon";
            weekday[2] = locale==='zh-TW'||locale===undefined?"二":"Tue";
            weekday[3] = locale==='zh-TW'||locale===undefined?"三":"Wed";
            weekday[4] = locale==='zh-TW'||locale===undefined?"四":"Thu";
            weekday[5] = locale==='zh-TW'||locale===undefined?"五":"Fri";
            weekday[6] = locale==='zh-TW'||locale===undefined?"六":"Sat";
            return weekday[day.getDay()] ;
        },
        getNowTime: function() {
            // 取得現在 hr:min ;
            let today = new Date() ;
            let hr = today.getHours() ;
            let min = today.getMinutes() ;
            if (hr < 10) {
                hr = '0' + hr ;
            }
            if (min < 10) {
                min = '0' + min ;
            }
            
            return ( hr + ':' + min ) ; 
        },
        getNowTimeHMS: function() {
            // 取得現在 hr:min ;
            let today = new Date() ;
            let hr = today.getHours() ;
            let min = today.getMinutes() ;
            let second = today.getSeconds();
            if (hr < 10) {
                hr = '0' + hr ;
            }
            if (min < 10) {
                min = '0' + min ;
            }
            if (second < 10) {
                second = '0' + second ;
            }
            
            return ( hr + ':' + min + ':' + second ) ; 
  
        },
        generateDateString( y, m, d, locale ) {
            if ( m < 10 ) {
              m = "0" + m;
            }
            if ( d < 10 ) {
              d = "0" + d;
            }
            return y + '-' + m + '-' + d + ' (' +  this.getWeekday(y+'-'+m+'-'+d, locale) + ')' ;
          },
    },
    
}