<template>
    <div class="px-0 px-sm-3 px-lg-0 w-layout-1020-limit mx-auto" id="news">
        <h4 class="text-title-color my-4">{{ $t("news") }}</h4>
        <div 
         class="px-0 d-sm-flex d-none d-sm-block position-relative justify-content-center"
         v-if="facebookPosts.length>0"
        >
            <NewsPage
             :items="fbPostToFXItems(facebookPosts)"
            />
            <ShippingPanel

            />
        </div>
          <div 
         class="px-0 d-sm-flex d-block position-relative justify-content-center d-sm-none"
         v-if="facebookPosts.length>0"
        >
           <ShippingPanel

            />
            <NewsPage
             :items="fbPostToFXItems(facebookPosts)"
            />
         
        </div>
        <div class="d-flex justify-content-center align-items-center h-50" v-else>
            <div class="spinner-border link-leuco-blue" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import NewsPage from './components/news-page.vue';
import ShippingPanel from '@/components/layout-components/shipping-panel-block/shipping-panel-block.vue';
import { apiGetFBPost } from '@/apis/facebook.js';

export default {
    props: {
        main_scenery: Array,
    },
    components: {
        NewsPage,
        ShippingPanel,
    },
    data() {
        return {
            facebookPosts:[],
        }
    },
    methods: {
        async getFacebookFanpagePost(length, need_group) {
            let result = await apiGetFBPost({
                length: length,
                need_group: need_group,
            });
            this.facebookPosts = this.extendToArrayOneDim(result.data);
        },
        extendToArrayOneDim( data ) {
            let array = []
            for ( let i = 0 ; i < data.length && array.length < 12 ; i++ ) {
                for ( let inside_index = 0 ; inside_index < data[i].length && array.length < 12 ; inside_index++ ) {

                    if ( data[i][inside_index].full_picture !== undefined 
                      && data[i][inside_index].message !== undefined ) {
                        array.push( { 
                            post: data[i][inside_index],
                            index: inside_index,
                        });
                    }
                }
            }
            return array;
        },
        fbPostToFXItems( posts ) {
            let array = [];
            for ( let i = 0 ; i < posts.length ; i++ ) {
                array.push({
                    imgsrc: posts[i].post.full_picture,
                    content: posts[i].post.message,
                    date: this.dateString(posts[i].post.created_time),
                    type: 'article',
                    link: this.postLink(posts[i].post.created_time, posts[i].index)
                });
            }
            return array;
        },
        dateString( date ) {
            let dateObject = new Date(date);
            return dateObject.getFullYear() + ' - ' 
                + (dateObject.getMonth()+1) + ' - '
                + dateObject.getDate();
        },
        postLink( time, index ) {
            let dateObject = new Date(time);
            return '/news/' 
            + dateObject.getFullYear() + '/'
            + (dateObject.getMonth()+1) + '/'
            + index ;
        }
    },
    async mounted() {
        await this.getFacebookFanpagePost(30,1);
    }
}
</script>
<style scope>
@import './style/news-section.css';
</style>