<template>
    <div class="row d-flex justify-content-center my-4 mx-2">
        <div class="col-xl-2 col-lg-3 col-3 w-intro-limit" v-for="(item, index) in infos" :key="index">
            <img :src="require('@/assets/1920/1920_leuco_intro_img' + (parseInt(index) + 1) + '.png')" class="w-100" alt="">
            <h5 class="text-title-color my-4">{{ item.title }}</h5>
            <div v-for="(subItem, subIndex) in item.datas" :key="subIndex">
                <div class="info" v-if="subItem.type === 'default'">
                    <div class="title my-2">
                        {{ subItem.sub_title }}
                    </div>
                    <p class="content" v-if="subItem.content !== ''">{{ subItem.content }}
                    </p>
                    <hr v-if="item.datas[parseInt(subIndex) + 1] !== undefined" />
                </div>
                <div class="info" v-else-if="subItem.type === 'default-title'">
                    <div class="title my-2">
                        {{ subItem.sub_title }}
                    </div>
                </div>
                <div class="info" v-if="subItem.type === 'list'">
                    <div class="title my-2">
                        {{ subItem.sub_title }}
                    </div>
                    <ul class="content">
                        <li v-for="(listItem, listIndex) in subItem.content" :key="listIndex">
                            {{ listItem }}
                        </li>
                    </ul>
                    <hr v-if="item.datas[parseInt(subIndex) + 1] !== undefined" />
                </div>
                <table class="info w-100" cellpadding="4" v-if="subItem.type === 'ticket'">
                    <tr class="content" v-for="(ticketItem, ticketIndex) in subItem.content" :key="ticketIndex">
                        <td v-for="(rowItem, rowIndex) in ticketItem" :key="rowIndex"
                            :colspan="parseInt(rowIndex) === 0 ? 2 : 1"
                            :class="{ 'ticket-tr': subItem.content[parseInt(ticketIndex) + 1] !== undefined }"
                            v-show=" ticketIndex <= 5"
                            >
                            <span style="white-space:pre-wrap" :class="{
                                'ticket-title': rowItem.type === 'title'
                            }">
                                {{ rowItem.name }}
                            </span>
                        </td>
                    </tr>
                </table>
                <div class="p-1 discount_info" v-if="subItem.type === 'ticket'">
                    民宿代訂及合作通路另有優惠。
                    <a style="text-decoration: none;" href="https://www.kkday.com/zh-tw/product/123925" target="_blank">KKday</a>、
                    <a style="text-decoration: none;" href="https://www.klook.com/zh-TW/activity/76597-xiaoliuqiu-round-trip-ticket/ " target="_blank">Klook</a>
                </div>
                <table class="info w-100" cellpadding="4" v-if="subItem.type === 'ticket'">
                    <tr class="content" v-for="(ticketItem, ticketIndex) in subItem.content" :key="ticketIndex">
                        <td v-for="(rowItem, rowIndex) in ticketItem" :key="rowIndex"
                            :colspan="parseInt(rowIndex) === 0 ? 2 : 1"
                            class="ticket-tr"
                            v-show=" ticketIndex >= 6"
                            >
                            <span style="white-space:pre-wrap" :class="{
                                'ticket-title': rowItem.type === 'title'
                            }">
                                {{ rowItem.name }}
                            </span>
                        </td>
                    </tr>
                </table>
                <div v-if="subItem.type === 'ticket'" class="p-1 text-start">
                    <ul style="color:red; font-weight: bold; list-style-type: none; padding-left: 0;">
                    <li class="custom-item">&ast; 團體票價請洽客服</li>
                    <li class="custom-item">&ast; 國旅卡請至現場購票（線上訂票不配合國旅卡）</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        infos: Object,
    }
}
</script>

