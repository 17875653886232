<template>
    <div 
     class="modal fade" 
     :id="targetID" 
     data-bs-backdrop="static" 
     data-bs-keyboard="false" 
     tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content rounded-10px shop-panel">
                <div class="modal-header p-0 border-0">
                    <div class="title-row w-100 d-sm-flex d-none">
                        <span class="title">
                            {{ $t("title-take-ticket") }}
                        </span>
                    </div>
                </div>
                <div class="py-4">
                    <div class="modal-body pb-0 fs-3">
                        {{ $t("modal-take-ticket-body-text-1") }}<ins class="fw-bold text-main-color">{{ $t("modal-take-ticket-body-text-2") }}</ins><br>
                        {{ $t("modal-take-ticket-body-text-3") }}
                    </div>
                    <div class="modal-footer justify-content-center bottom border-0">
                        <button 
                            class="btn-bottom main w-m-f"
                            data-bs-dismiss="modal"
                            v-on:click="backHomePage()"
                            >
                                <span class="text-nowrap">{{ $t('btn-ok') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        targetID: String,
        orderNumber: String,
        ticketCount: Number,
    },
    methods: {
        backHomePage() {
            this.$router.push({
                name: "MainPage",
            });
        }
    }
}
</script>
<i18n>
{
    "en": {
        "title-take-ticket": "Get ticket now",
        "modal-take-ticket-body-text-1": "Please use your ",
        "modal-take-ticket-body-text-2": "mobile device",
        "modal-take-ticket-body-text-3": "to collect your ticket For ticket pickup link, please check the SMS message sent when booking is completed"
        
    },
    "zh-TW": {
        "title-take-ticket": "立即取票",
        "modal-take-ticket-body-text-1": "請以",
        "modal-take-ticket-body-text-2": "行動裝置取票",
        "modal-take-ticket-body-text-3": "取票連結請查看訂票完成時發送之手機簡訊"
    }
}
</i18n>