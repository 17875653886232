<template>
<button 
 class="number-control-button btn btn-outline-leuco"
 v-on:click="emitMethod()"
>
    <i class="bi bi-plus-lg" v-if="isPlus"></i>
    <i class="bi bi-dash-lg" v-else></i>
</button>
</template>
<script>
export default {
    props: {
        emitName: String,
        isPlus: Boolean,
    },
    components: {
    },
    methods: {
        emitMethod() {
            this.$emit(this.emitName, {
                isPlus: this.isPlus,
                emitName: this.emitName,
            }) ;
        }
    }
}
</script>
<style scope>
@import './style/number-control-button.css';
</style>