<template>
    <div 
     class="modal fade" 
     :id="targetID" 
     data-bs-backdrop="static" 
     data-bs-keyboard="false" 
     tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content rounded-10px shop-panel">
                <div class="modal-header p-0 border-0">
                    <div class="title-row w-100 d-sm-flex d-none">
                        <span class="title">
                            {{ $t('title-pay-later') }}
                        </span>
                    </div>
                    <!-- <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body pb-0">
                    {{ $t("modal-pay-later-body-text-1") }}
                    <span class="fw-bold text-main-color">{{ orderNumber }}</span><br>

                    {{ $t("modal-pay-later-body-text-2") }} <br>

                    {{ $t("modal-pay-later-body-text-3-1") }} 
                    <span class="fw-bold text-main-color">「{{ $t("route-booking-search") }}」</span>
                    {{ $t("modal-pay-later-body-text-3-2") }}<br>

                    {{ $t("modal-pay-later-body-text-4-1") }} 
                    <span class="fw-bold text-main-color">{{ ticketCount }}</span>
                    {{ $t("modal-pay-later-body-text-4-2") }}<br>

                </div>
                <div class="modal-footer justify-content-center bottom border-0">
                    <button 
                        class="btn-bottom main w-m-f"
                        data-bs-dismiss="modal"
                        v-on:click="backHomePage()"
                        >
                            <span class="text-nowrap">{{ $t('btn-to-homepage') }}</span>
                    </button>
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Understood</button> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        targetID: String,
        orderNumber: String,
        ticketCount: Number,
    },
    methods: {
        backHomePage() {
            this.$router.push({
                name: "MainPage",
            });
        }
    }
}
</script>
<i18n>
{
    "en": {
        "title-pay-later": "Pay later",
        "modal-pay-later-body-text-1": "Your order number is ",
        "modal-pay-later-body-text-2": "Please keep your records. This order has not yet been paid, and the ticket can only be collected after the online payment is completed.",
        "modal-pay-later-body-text-3-1": "You can go to",
        "modal-pay-later-body-text-3-2": "to complete the payment.",
        "modal-pay-later-body-text-4-1": "Total",
        "modal-pay-later-body-text-4-2": "tickets"
    },
    "zh-TW": {
        "title-pay-later": "稍後付款",
        "modal-pay-later-body-text-1": "您的訂單編號",
        "modal-pay-later-body-text-2": "請自行收藏保存紀錄",
        "modal-pay-later-body-text-3-1": "可至",
        "modal-pay-later-body-text-3-2": "查詢",
        "modal-pay-later-body-text-4-1": "總計",
        "modal-pay-later-body-text-4-2": "張船票，領取時請確認數量"
    }
}
</i18n>