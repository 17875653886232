<template>
    <div>
        <div class="title-row d-flex justify-content-between sub-row">
            <div class="title sub w-number-st ">{{ $t('table-title-row-number') }}</div>
            <div class="title sub w-type">{{ $t('table-title-row-type') }}</div>
            <div class="title sub d-sm-block d-none w-count">{{ $t('table-title-row-count') }}</div>
            <div class="title sub w-status" v-if="paid">{{ $t('table-title-row-status') }}</div>
            <div class="title sub w-price">{{ $t('table-title-row-price') }}</div>
        </div>
        <div class="content pt-sm-4 pt-1">
            <ol class="list px-0">
                <li
                 v-for="(item, indexAR) 
                 in ( fareIndex('adult', 1) >= 0) ? fares[fareIndex('adult', 1)].count : 0" 
                 :key="'AR'+indexAR"
                 class="d-flex justify-content-between py-2"
                >
                    <div class="w-number-st">{{ countIndex.ar + indexAR + 1 }}.</div>
                    <div class="w-type">{{ ticketType(fares[fareIndex('adult', 1)].type) }} (<span class="mx-sm-1">{{ ticketTrip(fares[fareIndex('adult', 1)].is_round_trip) }}</span>)<span class="d-sm-none d-block text-end">x2{{ $t("unit-ticket") }}</span></div>
                    <div class="d-sm-flex d-none w-count justify-content-center">2</div>
                    <div class="w-status" v-if="paid">{{ ticketStatus(fares[fareIndex('adult', 1)].simple_ticket_infos[indexAR]) }}</div>
                    <div class="w-price"><span class="mx-sm-2">NT</span>${{ numberWithCommas(fares[fareIndex('adult', 1)].price) }}</div>
                </li>
                <li 
                 v-for="(item, indexCR) 
                 in ( fareIndex('children', 1) >= 0) ? fares[fareIndex('children', 1)].count : 0" 
                 :key="'CR'+indexCR"
                 class="d-flex justify-content-between py-2"
                >
                    <div class="w-number-st">{{ countIndex.cr + indexCR + 1 }}.</div>
                    <div class="w-type">{{ ticketType(fares[fareIndex('children', 1)].type) }} (<span class="mx-sm-1">{{ ticketTrip(fares[fareIndex('children', 1)].is_round_trip) }}</span>)<span class="d-sm-none d-block text-end">x2{{ $t("unit-ticket") }}</span></div>
                    <div class="d-sm-flex d-none w-count justify-content-center">2</div>
                    <div class="w-status" v-if="paid">{{ ticketStatus(fares[fareIndex('children', 1)].simple_ticket_infos[indexCR]) }}</div>
                    <div class="w-price"><span class="mx-sm-2">NT</span>${{ numberWithCommas(fares[fareIndex('children', 1)].price) }}</div>
                </li>
                <li
                 v-for="(item, indexANR)
                 in ( fareIndex('adult', 0) >= 0 ) ? fares[fareIndex('adult', 0)].count : 0" 
                 :key="'ANR'+indexANR"
                 class="d-flex justify-content-between py-2"
                >
                    <div class="w-number-st">{{ countIndex.anr + indexANR + 1 }}.</div>
                    <div class="w-type">{{ ticketType(fares[fareIndex('adult', 0)].type) }} (<span class="mx-sm-1">{{ ticketTrip(fares[fareIndex('adult', 0)].is_round_trip) }}</span>)</div>
                    <div class="d-sm-flex d-none w-count justify-content-center">1</div>
                    <div class="w-status" v-if="paid">{{ ticketStatus(fares[fareIndex('adult', 0)].simple_ticket_infos[indexANR]) }}</div>
                    <div class="w-price"><span class="mx-sm-2">NT</span>${{ numberWithCommas(fares[fareIndex('adult', 0)].price) }}</div>
                </li>
                <li
                 v-for="(item, indexCNR)
                 in ( fareIndex('children', 0) >= 0 ) ? fares[fareIndex('children', 0)].count : 0" 
                 :key="'CNR'+indexCNR"
                 class="d-flex justify-content-between py-2"
                >
                    <div class="w-number-st">{{ countIndex.cnr + indexCNR + 1 }}.</div>
                    <div class="w-type">{{ ticketType(fares[fareIndex('children', 0)].type) }} (<span class="mx-sm-1">{{ ticketTrip(fares[fareIndex('children', 0)].is_round_trip) }}</span>)</div>
                    <div class="d-sm-flex d-none w-count justify-content-center">1</div>
                    <div class="w-status" v-if="paid">{{ ticketStatus(fares[fareIndex('children', 0)].simple_ticket_infos[indexCNR]) }}</div>
                    <div class="w-price"><span class="mx-sm-2">NT</span>${{ numberWithCommas(fares[fareIndex('children', 0)].price) }}</div>
                </li>
            </ol>
            <hr>
            <div class="d-none d-sm-block">
                  <div class="d-block d-sm-flex justify-content-between align-items-end my-3">
                <div class="text-end">
                    <span class="text-dark fw-bold">{{ $t('table-bottom-result-1') }}</span> <span class="text-danger fw-bold">{{ resultCount('passenger') }} </span> 
                    <span class="text-dark fw-bold">{{ $t('table-bottom-result-2') }}</span> <span class="text-danger fw-bold">{{ resultCount('ticket') }} </span> 
                    <span class="text-dark fw-bold">{{ $t('table-bottom-result-3') }}</span> 
                </div>
                <div class="text-end">
                    <span class="text-dark fw-bold fs-5">{{ $t('table-bottom-result-4') }}</span> <span class="text-danger fw-bold">NT${{ numberWithCommas( resultCount('price') ) }}</span> 
                </div>
            </div>
            <div class="d-block d-sm-flex justify-content-between align-items-end my-3">
                <div class="text-end">
                    <span class="text-dark fw-bold">{{ $t('coupon-discount') }}</span> 
                </div>
                <div class="text-end">
                     <span class="text-danger">-NT${{ numberWithCommas( resultCount('discount') ) }}</span> 
                </div>
            </div>
            <hr class="mb-0 d-none d-sm-block"> 
              <div class="d-block d-sm-flex justify-content-between align-items-end my-3  fz-amount ">
              <div class="text-end">
                <span class="text-dark fw-bold">{{ $t('total-cost') }}</span> 
              </div>
              <div class="text-end">
                <span class="text-danger fw-bold">NT${{ numberWithCommas( resultCount('total') ) }}</span> 
              </div>
            </div>
          </div>
          <!-- 手機版本介面 -->
          <div class="d-block d-sm-none">
              <div class="d-flex justify-content-between align-items-center my-1">
                  <div>
                    <span class="text-grey fw-bold fs-5">{{ $t('table-bottom-result-1') }}</span><span class="text-danger fw-bold fs-5">{{ resultCount('passenger') }} </span> 
                    <span class="text-grey fw-bold fs-5">{{ $t('table-bottom-result-2') }}</span><span class="text-danger fw-bold fs-5">{{ resultCount('ticket') }} </span> 
                    <span class="text-grey fw-bold fs-5">{{ $t('table-bottom-result-3') }}，</span> 
                    <span class="text-grey fw-bold fs-5">{{ $t('table-bottom-result-4') }}</span> 
                  </div>
                    <span class="text-danger fw-bold fs-5">NT${{ numberWithCommas( resultCount('price') ) }}</span> 
              </div>
              <div class="d-flex justify-content-between align-items-center my-1">
                  <span class="text-grey fw-bold text-start fs-5">{{ $t('coupon-discount') }}</span> 
                  <span class="text-danger fs-5">-NT${{ numberWithCommas( resultCount('discount') ) }}</span> 
              </div>
              <hr>
                <div class="d-flex justify-content-between align-items-center my-2" >
                  <span class="text-dark fw-bold fs-5">{{ $t('total-cost') }}</span> 
                  <span class="text-danger fw-bold fs-5">NT${{ numberWithCommas( resultCount('total') ) }}</span> 
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        fares: Array,
        paid: Boolean,
    },
    data() {
        return {
            countIndex: {
                ar: 0,
                cr: 0,
                anr: 0,
                cnr: 0,
            }
        }
    },
    methods: {
        fareIndex( type, isRoundTrip ) {
            if ( this.fares != undefined ) {
                for ( let i = 0 ; i < this.fares.length ; i++ ) {
                    if ( this.fares[i].type === type && this.fares[i].is_round_trip === isRoundTrip )
                        return i ;
                }
            }
            return -1 ;
        },
        ticketType( type ) {
            if ( type === 'adult' ) {
                return this.$t('ticket-type-adult')
            }
            else if ( type === 'children' ) {
                return this.$t('ticket-type-children')
            }
            return "" ;
        },
        ticketTrip( trip ) {
            if ( trip === 1 ) {
                return this.$t('ticket-is-round-trip')
            }
            else if ( trip === 0 ) {
                return this.$t('ticket-is-not-round-trip')
            }
            return "" ;
        },
        ticketStatus( simple_info ) {

            let returnString = "" ;

            if ( simple_info.out !== undefined 
              && simple_info.out.status === 'created'
              && ( simple_info.in !== undefined ? simple_info.in.status === 'created' : true ) ) {
                returnString = this.$t("ticket-status-created") ;
            }
            else if ( simple_info.out !== undefined 
              && ( simple_info.out.status === 'picked_up' || simple_info.out.status === 'used' )
              && ( simple_info.in !== undefined ? ( simple_info.in.status === 'picked_up' || simple_info.in.status === 'used' ) : true ) ) {
                returnString = this.$t("ticket-status-picked_up") ; 
            }
            else if ( simple_info.out !== undefined 
              && simple_info.out.status === 'refunded'
              && ( simple_info.in !== undefined ? simple_info.in.status === 'refunded' : true ) ) {
                returnString = this.$t("ticket-status-refunded") ; 
            }
            else if ( simple_info.out !== undefined 
              && simple_info.in !== undefined ) {
                returnString += this.$t("outbound") ;
                if ( simple_info.out.status === 'created' ) {
                    returnString += this.$t("ticket-status-short-created")  ;
                }
                else if ( simple_info.out.status === 'picked_up' || simple_info.out.status === 'used' ) {
                    returnString += this.$t("ticket-status-short-picked_up") ;
                }
                else if ( simple_info.out.status === 'refunded' ) {
                    returnString += this.$t("ticket-status-short-refunded") ;
                }
                returnString += "\n" + this.$t("inbound") ;
                if ( simple_info.in.status === 'created' ) {
                    returnString += this.$t("ticket-status-short-created")  ;
                }
                else if ( simple_info.in.status === 'picked_up' || simple_info.in.status === 'used' ) {
                    returnString += this.$t("ticket-status-short-picked_up") ;
                }
                else if ( simple_info.in.status === 'refunded' ) {
                    returnString += this.$t("ticket-status-short-refunded") ;
                }
            }
            return returnString ;
        },
        numberWithCommas(num) {
            if (this.isNotUndefined(num))
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            else return "";
        },
        isNotUndefined(val) {
        // console.log( val, typeof val === 'undefined' )
            if (typeof val === "undefined") {
                return false;
            } else {
                return true;
            }
        },
        resultCount( key ) {
            let count = 0 ;
            if ( this.fares != undefined ) {
                for ( let i = 0 ; i < this.fares.length ; i++ ) {
                    if ( key === 'passenger' )
                        count += this.fares[i].count ;
                    else if ( key === 'ticket' ) 
                        count += this.fares[i].is_round_trip ? this.fares[i].count * 2 : this.fares[i].count ;
                    else if ( key === 'price' ) 
                        count += this.fares[i].price * this.fares[i].count
                    else if (key === "discount")
                        count += this.fares[i].coupon_discount * this.fares[i].count ;
                    else if ( key === "total")
                         count += (this.fares[i].price - this.fares[i].coupon_discount)* this.fares[i].count;

                }
            }
            if ( key === 'ticket' ) {
                this.$emit( 'ticket-count', count );
            }
            return count ;
        },
        setIndex() {
            this.countIndex.ar = 0 ;
            this.countIndex.cr = this.fares[this.fareIndex('adult', 1)].count ;
            this.countIndex.anr = this.countIndex.cr + this.fares[this.fareIndex('children', 1)].count ;
            this.countIndex.cnr = this.countIndex.anr + this.fares[this.fareIndex('adult', 0)].count ;
        },

    },
    mounted() {
        this.setIndex() ;
    }
    
}
</script>
<i18n>
{
    "en": {
        "table-title-row-number": "Number",
        "table-title-row-type": "Type",
        "table-title-row-count": "Quantity",
        "table-title-row-status": "Status",
        "table-title-row-price": "Price",
        "ticket-type-adult": "Regular",
        "ticket-type-children": "Concession",
        "ticket-is-round-trip": "Roundtrip",
        "ticket-is-not-round-trip": "",
        "ticket-status-created": "Paid",
        "ticket-status-picked_up": "Picked up",
        "ticket-status-refunded": "Refunded",
        "unit-ticket": " ",
        "inbound": "Departure",
        "outbound": "Return",
        "coupon-discount":"Discount amount",
        "ticket-status-short-created": "Paid",
        "ticket-status-short-picked_up": "Pickedup",
        "ticket-status-short-refunded": "Refunded",
        "table-bottom-result-1": "Total",
        "table-bottom-result-2": "Passengers, ",
        "table-bottom-result-3": "Tickets",
        "table-bottom-result-4": "Total price",
        "total-cost": "Amount to be paid for this order"
    },
    "zh-TW": {
        "table-title-row-number": "編號",
        "table-title-row-type": "票別",
        "table-title-row-count": "取票張數",
        "table-title-row-status": "狀態",
        "table-title-row-price": "票價",
        "ticket-type-adult": "全票",
        "ticket-type-children": "半票",
        "ticket-is-round-trip": "來回",
        "ticket-is-not-round-trip": "單程",
        "ticket-status-created": "未取票",
        "ticket-status-picked_up": "已取票",
        "ticket-status-refunded": "已退票",
        "unit-ticket": "張",
        "inbound": "回程",
        "outbound": "去程",
        "coupon-discount":"折扣碼抵用金額",
        "ticket-status-short-created": "未取",
        "ticket-status-short-picked_up": "已取",
        "ticket-status-short-refunded": "已退",
        "table-bottom-result-1": "共計",
        "table-bottom-result-2": "人，",
        "table-bottom-result-3": "張票",
        "table-bottom-result-4": "總票價",
        "total-cost":"本訂單需付金額"     
    }
}
</i18n>
<style scope>
@import './style/tickets-table.css';
</style>