<template>
    <div>
        <div class="title-row">
            <span class="title">{{ $t('title-pay-by-creditcard') }}</span>
        </div>
        <div class="content pt-sm-4 pt-3">
            <div class="text-main-color text-start mb-sm-3 mb-0">
                <span>{{ $t("hint-pay") }}</span>
                <span class="fw-bold">{{($i18n.locale==='zh-TW'?' 「':' "') + $t("btn-pay-confirm") + ($i18n.locale==='zh-TW'?'」':'"')}}</span>
            </div>
            <form class="needs-validation credit-info">
                <div class="g-3 d-sm-flex justify-content-between my-sm-4 my-2">
                    <div class="col-sm-6 col-xl-5 d-sm-flex col-12 d-block align-items-center my-sm-0 my-4">
                        <div class="d-flex justify-content-start my-1 text-black-50">
                            <label for="name" class="text-nowrap">
                                {{ $t("input-label-name") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="w-100">
                            <input 
                            type="text"
                            class="mx-md-2 mx-0 w-100 px-md-3 px-2 py-1 form-control"
                            :placeholder="$t('input-placeholder-name')"
                            v-model="input.name"
                            v-on:blur="validateForm('name')"
                            :class="{ 
                                'is-valid': validateArray[0].isValid,
                                'is-invalid': !validateArray[0].isValid && validateArray[0].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                        </div>
                    </div>
                    <div class="col-sm-6 col-xl-6 d-sm-flex col-12 d-block align-items-center justify-content-end my-sm-0 my-4">
                        <div class="d-flex justify-content-start my-1 text-black-50">
                            <label for="customer-birthday" class="text-nowrap mx-md-2 mx-0">
                                {{ $t("input-label-email") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="w-100">
                            <input 
                            type="text"
                            class="w-100 px-md-3 py-1 form-control"
                            :placeholder="$t('input-placeholder-email')"
                            v-model="input.email"
                            v-on:blur="validateForm('email')"
                            :class="{ 
                                'is-valid': validateArray[2].isValid,
                                'is-invalid': !validateArray[2].isValid && validateArray[2].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                        </div>
                    </div>
                </div>
                <div class="g-3 d-sm-flex justify-content-between my-sm-4 my-2">
                    <div class="col-sm-6 col-xl-5 d-sm-flex col-12 d-block align-items-center my-sm-0 my-4">
                        <div class="d-flex justify-content-start my-1 text-black-50">
                            <label for="name" class="text-nowrap">
                                {{ $t("input-label-phone") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="w-100">
                            <input 
                            type="text"
                            class="mx-md-2 mx-0 w-100 px-md-3 px-2 py-1 form-control"
                            :placeholder="$t('input-placeholder-phone')"
                            v-model="input.phone"
                            v-on:blur="validateForm('phone')"
                            :class="{ 
                                'is-valid': validateArray[1].isValid,
                                'is-invalid': !validateArray[1].isValid && validateArray[1].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                        </div>
                    </div>
                </div>

                <hr>

                <div class="g-3 d-sm-flex justify-content-start my-sm-4 my-2">
                    <div class="col-sm-7 col-xl-6 d-sm-flex col-12 d-block align-items-center my-sm-0 my-4">
                        <div class="d-flex justify-content-start my-1 text-black-50">
                            <label for="name" class="text-nowrap w-label-min-width">
                                {{ $t("input-label-card-number") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="w-100">
                            <div 
                             class="form-control"
                             style="height:40px;"
                             ref="number"
                            >
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-xl-4 d-sm-flex col-12 d-block align-items-center justify-content-end my-sm-0 my-4 mx-sm-4 mx-0">
                        <div class="d-flex justify-content-start my-1 text-black-50">
                            <label for="customer-birthday" class="text-nowrap mx-md-2 mx-0">
                                {{ $t("input-label-card-exp") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="w-100">
                            <div 
                             class="form-control"
                             style="height:40px;"
                             ref="expirationDate"
                            >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="g-3 d-sm-flex justify-content-between my-sm-4 my-2">
                    <div class="col-sm-6 col-xl-5 d-sm-flex col-12 d-block align-items-center my-sm-0 my-4">
                        <div class="d-flex justify-content-start my-1 text-black-50">
                            <label for="name" class="text-nowrap d-sm-block d-flex w-label-min-width">
                                {{ $t("input-label-card-ccv") }}<span class="text-main-color">*</span>
                                <div>
                                    <label class="form-check-label fs-0p8rem mx-1 my-auto">
                                        {{ $t("input-label-card-ccv-sub") }}
                                    </label>
                                </div>
                                
                            </label>
                            
                        </div>
                        <div class="w-100">
                            <div 
                             class="form-control w-100"
                             style="height:40px;"
                             ref="ccv"
                            >
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            <div class="py-sm-1"></div>
            <hr class="mb-0">
        </div>
        <div class="bottom">
            <div class="group justify-content-between">
                <button
                 class="btn-bottom w-m-f"
                 :class="{'disabled':sendingRequest}"
                 data-bs-toggle="modal" 
                 :data-bs-target="'#'+modalIDs[0]"
                >
                    <span class="text-nowrap">{{ $t('btn-cancel-pay') }}</span>
                </button>
                <button 
                 class="btn-bottom main w-m-f"
                 :class="{ 
                    'disabled': !isFormValid,
                    'loading': sendingRequest,
                 }"
                 v-on:click="payConfirm()"
                 :data-bs-toggle="isFormValid?'modal':''" 
                 :data-bs-target="'#'+modalIDs[1]"
                >
                    <span 
                     v-if="!sendingRequest"
                     class="text-nowrap"
                    >{{ $t('btn-pay-confirm') }}
                    </span>
                    <span
                     v-if="sendingRequest"
                    >
                     <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                     {{ $t("btn-next-loading") }}
                    </span>

                </button>
            </div>
            
        </div>
        <ModalCancelPay 
         :targetID="modalIDs[0]"
        />
        <ModalPayingStatus 
         :targetID="modalIDs[1]"
         :status="payingStatus"
         :errorMessage="errorMessage"
         @emitReset="resetStatus"
        />
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";
import getEnv from "@/utils/env.js";
import ModalCancelPay from "@/components/layout-components/shop-panel/modal-cancel-pay/modal-cancel-pay.vue";
import ModalPayingStatus from "@/components/layout-components/shop-panel/modal-paying-status/modal-paying-status.vue";

export default {
    components: {
        ModalCancelPay,
        ModalPayingStatus,
    },
    data() {
        return {
            ts: {},
            sendingRequest: false,
            orderData: {},
            input: {
                name: "",
                phone: "",
                email: "",
            },
            validateArray: [
                { isValid: false, isBlur: false, target: "name" },
                { isValid: false, isBlur: false, target: "phone" },
                { isValid: false, isBlur: false, target: "email" },
                { isValid: false, isBlur: true, target: "credit-card" },
            ],
            modalIDs: [
                "id-cancel-pay",
                "id-paying-status"
            ],
            isGetPrimComp: false,
            isGetPrimSuccess: false,
            isPayRequsetComp: false,
            isPayRequestSuccess: false,
            errorMessage: "",
        }
    },
    computed: {
        isFormValid() {
            for ( let i = 0 ; i < this.validateArray.length ; i++ ) {
                if ( !this.validateArray[i].isValid ) 
                    return false ;
            }
            return true ;
        },
        payingStatus() {
            if ( !this.isGetPrimComp ) {
                return "geting-prime" ;
            }
            else if ( this.isGetPrimComp && !this.isGetPrimSuccess ) {
                return "get-prime-fail" ;
            }
            else if ( this.isGetPrimComp && this.isGetPrimSuccess && !this.isPayRequsetComp ) {
                return "sending-pay-request" ;
            }
            else if ( this.isGetPrimComp && this.isGetPrimSuccess && this.isPayRequsetComp && !this.isPayRequestSuccess ) {
                return "pay-request-fail"
            }
            else {
                return "success-redirect" ;
            }
        }
    },
    methods: {
        validateForm( target ) {
            if ( target === 'name' || target === 'form' ) {
                this.validateArray[0].isBlur = true ;
                if ( this.input.name !== "" ) {
                    this.validateArray[0].isValid = true ;
                }
                else {
                    this.validateArray[0].isValid = false ;
                }
            }
            if ( target === 'phone' || target === 'form' ) {
                this.validateArray[1].isBlur = true ;
                if ( this.validatePhoneNumber(this.input.phone) ) {
                    this.validateArray[1].isValid = true ;
                }
                else {
                    this.validateArray[1].isValid = false ;
                }
            }
            if ( target === 'email' || target === 'form' ) {
                this.validateArray[2].isBlur = true ;
                if ( this.validateEmail(this.input.email) ) {
                    this.validateArray[2].isValid = true ;
                }
                else {
                    this.validateArray[2].isValid = false ;
                }
            }
        },
        async init() {

            if ( this.$route.query.o_n !== undefined && this.$route.query.o_i !== undefined ) {

                this.ts = new TicketService() ;
                await this.getOrderData() ;

                let dateObj = new Date();
                this.maxDate = dateObj.getFullYear() + '-' + (dateObj.getMonth()+1) + '-' + dateObj.getDate() ;
                this.input.birthday = this.maxDate ;

                this.setupTapPay() ;

            }
        },
        async getOrderData() {
            let orderInfoResult = await this.ts.getOrderInfo(this.$route.query.o_n, this.$route.query.o_i) ;
            if ( orderInfoResult.status === 200 && !orderInfoResult.data.paid ) {
                this.orderData = orderInfoResult.data ;
            }
            else {
                if ( orderInfoResult.status !== 200 )
                    console.log( "cannot get order info in pay page" ) ;
                else if ( orderInfoResult.data.paid )
                    console.log( "order paid" ) ;

                this.$router.push({
                    name: "Order_Info",
                    query: {
                        o_n: this.$route.query.o_n,
                        o_i: this.$route.query.o_i
                    },
                });
            }
        },
        setupTapPay() {
            let APP_ID = getEnv("VUE_APP_TAP_PAY_APP_ID");
            let APP_KEY = getEnv("VUE_APP_TAP_PAY_APP_KEY");
            TPDirect.setupSDK(APP_ID, APP_KEY, getEnv("VUE_APP_TAP_PAY_TARGET_ENV"));

            const fields = {
                number: {
                element: this.$refs.number,
                placeholder: this.$t("input-placeholder-creditcard-number"),
                },
                expirationDate: {
                element: this.$refs.expirationDate,
                placeholder: this.$t("input-placeholder-creditcard-exp"),
                },
                ccv: {
                element: this.$refs.ccv,
                placeholder: this.$t("input-placeholder-creditcard-ccv"),
                },
            };

            TPDirect.card.setup({
                fields: fields,
                styles: {
                // Style all elements
                input: {
                    color: "gray",
                },
                // Styling ccv field
                "input.ccv": {},
                // Styling expiration-date field
                "input.expiration-date": {},
                // Styling card-number field
                "input.card-number": {},
                // style focus state
                ":focus": {
                    // 'color': 'black'
                },
                // style valid state
                ".valid": {
                    color: "green",
                },
                // style invalid state
                ".invalid": {
                    color: "red",
                },
                // Media queries
                // Note that these apply to the iframe, not the root window.
                "@media screen and (max-width: 400px)": {
                    input: {
                    color: "orange",
                    },
                    //   'input::placeholder': {
                    //     color: '#ffffff'
                    //   }
                },
                },
            });

            TPDirect.card.onUpdate((update) => {
                if (update.canGetPrime) {
                //全部欄位皆為正確 可以呼叫 getPrime
                this.disabledBtnPay = false;
                } else {
                this.disabledBtnPay = false;
                }

                this.updateStatus(update.status.number);
                this.updateStatus(update.status.expiry);
                this.updateStatus(update.status.ccv);
                this.validateArray[3].isValid = TPDirect.card.getTappayFieldsStatus().canGetPrime;
            });
        },
        updateStatus(field) {
            switch (field) {
                case 0:
                //欄位已填好，並且沒有問題
                console.log("field is ok");
                break;
                case 1:
                //欄位還沒有填寫
                console.log("field is empty");
                break;
                case 2:
                //欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
                console.log("field has error");
                break;
                case 3:
                //使用者正在輸入中
                console.log("usertyping");
                break;
                default:
                console.log("error!");
            }
        },
        async payConfirm() {
            if ( this.isFormValid && !this.sendingRequest ) {
                this.sendingRequest = true ;
                TPDirect.card.getPrime( (result) => this.getPrimeCallback(result) ) ;
            }
            else if ( !this.sendingRequest ) {
                this.validateForm('form');
            }
        },
        async getPrimeCallback( getPrimeResult ) {
            // console.log( getPrimeResult ) ;
            this.isGetPrimComp = true ;

            if ( getPrimeResult.status !== 0 ) {
                this.isGetPrimSuccess = false ;  
                this.sendingRequest = false ;
                return ;
            }
            this.isGetPrimSuccess = true ;
            
            let result = await this.ts.sendPayRequest(
                this.$route.query.o_n, 
                this.$route.query.o_i, 
                getPrimeResult.card.prime, 
                this.input.name, 
                this.input.phone, 
                this.input.email, 
                getEnv("VUE_APP_TAP_PAY_REDIRECT_TARGET_TYPE")
            ) ;

            if ( result.status === 200 ) {
                this.isPayRequsetComp = true ;
                this.isPayRequestSuccess = true ;
                window.location.replace(result.data.redirect_url) ;
            }
            else {
                this.isPayRequsetComp = true ;
                this.isPayRequestSuccess = false ;
                this.errorMessage = result.message ;
            }
            this.sendingRequest = false ;
        },
        resetStatus() {
            this.isGetPrimComp = false ;
            this.isGetPrimSuccess = false ;
            this.isPayRequsetComp = false ;
            this.isPayRequestSuccess = false ;
            this.sendingRequest = false ;
            this.errorMessage = "" ;
        }

    },
    async mounted() {
        this.init() ;
    }
}
</script>
<i18n>
{
    "en": {
        "title-pay-by-creditcard": "Credit card payment",
        "hint-pay": "※Enter the credit card information, please confirm whether it is correct and click",
        "input-label-name": "Name",
        "input-label-email": "Email",
        "input-label-phone": "Phone",
        "input-label-card-number": "Card Seurity Code",
        "input-label-card-exp": "Expiration Date",
        "input-label-card-ccv": "Check code",
        "input-label-card-ccv-sub": "(Last three yards on the back)",
        "input-placeholder-name": "Name",
        "input-placeholder-email": "Email",
        "input-placeholder-phone": "Phone",
        "input-placeholder-creditcard-number": "Code",
        "input-placeholder-creditcard-exp": "MM/YY",
        "input-placeholder-creditcard-ccv": "ccv",
        "btn-cancel-pay": "Cancel",
        "btn-pay-confirm": "Pay",
        "btn-next-loading": "Loading"
    },
    "zh-TW": {
        "title-pay-by-creditcard": "信用卡付款",
        "hint-pay": "※ 輸入信用卡資訊，輸入完請確認是否無誤，請按",
        "input-label-name": "持卡人姓名",
        "input-label-email": "持卡人Email",
        "input-label-phone": "持卡人手機",
        "input-label-card-number": "信用卡卡號",
        "input-label-card-exp": "有效期限",
        "input-label-card-ccv": "檢查碼",
        "input-label-card-ccv-sub": "(背面末三碼)",
        "input-placeholder-name": "請輸入持卡人姓名",
        "input-placeholder-email": "請輸入持卡人Email",
        "input-placeholder-phone": "請輸入手機",
        "input-placeholder-creditcard-number": "請輸入卡號",
        "input-placeholder-creditcard-exp": "MM/YY",
        "input-placeholder-creditcard-ccv": "請輸入檢查碼",
        "btn-cancel-pay": "取消付款",
        "btn-pay-confirm": "確認付款",
        "btn-next-loading": "請稍候"
    }
}
</i18n>
<style scope>
@import './style/pay-creditcard.css';
</style>