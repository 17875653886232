import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from "@/store/store.js";

import zhTW from '@/locales/zh-TW.json';
import en from '@/locales/en.json';

Vue.use(VueI18n)

// 複數路徑免import作法 //
// function loadLocaleMessages () {
//   const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

let locale = 'zh-TW';

// 檢查 localStorage 是否已有保存使用者選用的語系資訊
if (localStorage.getItem('footmark-lang')) {
  locale = localStorage.getItem('footmark-lang');
  store.commit('lang/setLang', locale);
} else {
  store.commit('lang/setLang', locale);
}

export default new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE || 'zh-TW',
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh-TW',
  // messages: loadLocaleMessages()
  locale: locale,
  messages: {
    'zh-TW': zhTW,
    'en': en
  }
})
