<template>
    <div 
     v-if="shiptimeArray.length > 0"
     :class="{
         'py-sm-3': paddingSide === 'py',
         'py-1': paddingSide === 'py',
         'pt-sm-3': paddingSide === 'pt',
         'pt-1': paddingSide === 'pt'
     }"
    >
        <div 
         v-for="(items, index) in arrayCapacity(shiptimeArray,capacity)" :key="index"
         class="row-shiptime">
            <div
             v-for="(item, index_btn) in selectArray(shiptimeArray,capacity,index)" :key="index_btn"
             class="col-sm-3 col-12 d-flex" 
             :class="{ 
                'justify-content-start': parseInt(index_btn)%capacity===0,
                'justify-content-center': parseInt(index_btn)%capacity!==0 && parseInt(index_btn)%capacity!==(capacity-1),
                'justify-content-end': parseInt(index_btn)%capacity===(capacity-1),
             }"
            >
                <div 
                 class="btn-shiptime"
                 :class="{
                     'disable': item.disable,
                     'selected': item.isSelected
                 }"
                 v-on:click="clickEmit(item)"
                >
                    {{ item.data.time }} {{ item.isSpecial ? $t('is-special') : "" }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        shiptimeArray: Array,
        capacity: Number,
        paddingSide: String,
        target: String,
    },
    methods: {
        arrayCapacity( array, capacity ) {
            return Math.floor( array.length / capacity ) + ( array.length % capacity > 0 ? 1 : 0 ) ;
        },
        selectArray( _array, size, _index ) {
            let array = [];
            let index = parseInt(_index);
            for ( let i = (index*size) ; i < (index*size+size) && _array[i] !== undefined ; i++ ) {
                array.push( _array[i] );
            }
            return array;
        },
        clickEmit( shiptimeItem ) {
            if ( !shiptimeItem.disable ) {
                this.$emit( "emit-select-time", { 
                    target: this.target,
                    data: shiptimeItem,
                });
            }
        },
    }
}
</script>
<i18n>
{
    "en": {
        "is-special": "(Extra)"
    },
    "zh-TW": {
        "is-special": "( 加開 )"
    }
}
</i18n>