var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-0"},[_c('h4',{staticClass:"text-title-color my-4"},[_vm._v(_vm._s(_vm.$t("explore-liuqiu")))]),_c('div',{staticClass:"breakpoint-over-w576px px-0"},[_c('FadeXComponent',{attrs:{"main_img":[
            { 
                imgsrc: 'island_intro_img1',
            },
            { 
                imgsrc: 'island_intro_img2',
            },
            { 
                imgsrc: 'island_intro_img3',
            },
            { 
                imgsrc: 'island_intro_img4',
            } ],"items":[
            { 
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.0.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.0.desc')
                },
                type: 'describe',
            },
            { 
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.1.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.1.desc')
                },
                type: 'describe',
            },
            { 
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.2.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.2.desc')
                },
                type: 'describe',
            },
            { 
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.3.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.3.desc')
                },
                type: 'describe',
            } ]}})],1),_c('div',{staticClass:"breakpoint-lower-w576px px-0"},[_c('CarouselSlideIndicator',{attrs:{"items":[
            { 
                imgsrc_main: _vm.imgMPath('news_main_img1'),
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.0.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.0.desc')
                },
                type: 'describe',
            },
            { 
                imgsrc_main: _vm.imgMPath('news_main_img2'),
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.1.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.1.desc')
                },
                type: 'describe',
            },
            { 
                imgsrc_main: _vm.imgMPath('news_main_img3'),
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.2.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.2.desc')
                },
                type: 'describe',
            },
            { 
                imgsrc_main: _vm.imgMPath('news_main_img4'),
                content: {
                    title: _vm.$t('section-explore-liuqiu-content.3.title'),
                    desc: _vm.$t('section-explore-liuqiu-content.3.desc')
                },
                type: 'describe',
            } ]}})],1),_c('div',{staticClass:"cooperation-store-link"},[_c('button',{staticClass:"mainbtn",on:{"click":function($event){return _vm.$router.push({
             name: 'Cooperate-Stores',
         })}}},[_vm._v(" "+_vm._s(_vm.$t('route-cooperate'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }