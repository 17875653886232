<template>
    <div class="row bg-wave">
        <LeucoInfoSection/>
    </div>
</template>
<script>
import LeucoInfoSection from './components/leuco-info-section/leuco-info-section.vue'
export default {
    components: {
        LeucoInfoSection,
    }
}
</script>
<style scope>
@import './style/leuco-introduce.css';
</style>