<template>
    <div class="bg-wave py-4">
        <div class="w-layout-1020-limit row justify-content-center mx-auto">
            <h5 class="text-title-color my-4">{{$t('news')}}</h5>

            <NewsList 
            v-if="lists.length>0"
            :lists="lists"
            />
            <NewsContent 
            v-if="lists.length>0"
            :lists="lists"
            />
            <div 
            class="col-12 w-100"
            style="min-height:20vh;"
            v-if="lists.length===0"
            >
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NewsList from './components/news-list/news-list.vue';
import NewsContent from './components/news-content/news-content.vue'
import { apiGetFBPost } from '@/apis/facebook.js';

export default {
    components: {
        NewsList,
        NewsContent,
    },
    data() {
        return {
            lists: [],
            /* 
              year_list: {
                year: String,
                list: [
                    month_list
                ],
              },
              month_list: {
                month: String,
                content: Object{
                    message, full_picture, created_time,
                }
              }
            */
        }
    },
    methods: {
        async getFacebookFanpagePost(length, need_group) {
            let result = await apiGetFBPost({
                length: length,
                need_group: need_group,
            });
            // this.lists = result.data;
            this.lists = this.setPostsList(result.data);
            // console.log(this.lists);
        },
        setPostsList( data ){
            // console.log('@');
            let lists = [];
            let year_list = {
                year: new Date().getFullYear(),
                list: [],
            }
            for (const [key, value] of Object.entries(data)) {
                let itemDate = new Date(value[0].created_time); // 在api端已經做好年月區分，只需取第一個 ;
                console.log(`load news:(${key}: ${value[0]} - ${itemDate})`);
                // console.log(itemDate);
                if ( year_list.year === itemDate.getFullYear() ) {
                    year_list.list.push({
                        month: itemDate.getMonth()+1,
                        content: this.takeNotNullPostList(value), // 後來api已補上檢查null值 ;
                        showing: false
                    });
                }
                else {
                    lists.push(year_list);
                    let temp_year = year_list.year;
                    year_list = {
                        year: temp_year - 1,
                        list: [],
                    }
                }
                
            }
            lists.push(year_list);
            // console.log("result",lists);
            return lists;
        },
        takeNotNullPostList( contentsArray ) {
            let array = [];
            for ( let i = 0 ; i < contentsArray.length ; i++ ) {
                if ( this.isNotNullAndNotUndefined(contentsArray[i].created_time)
                  && this.isNotNullAndNotUndefined(contentsArray[i].message)
                  && this.isNotNullAndNotUndefined(contentsArray[i].full_picture) ) {
                    array.push(contentsArray[i]);
                }
            }
            return array ;
        },
        isNotNullAndNotUndefined( value ) {
            if ( value !== null && value !== undefined ) 
                return true ;
            else 
                return false ;
        }
    },
    async mounted() {
        await this.getFacebookFanpagePost(20,1);
    }
}
</script>