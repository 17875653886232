<template>
  <div class="d-flex justify-content-center my-4">
    <div class="arrow_lr left" :class="{'disabled':!hasLeft}" v-on:click="$emit('emitLeft')"></div>
    <div class="arrow_lr right" :class="{'disabled':!hasRight}" v-on:click="$emit('emitRight')"></div>
  </div>
</template>
<script>
export default {
  props: {
    hasLeft: Boolean,
    hasRight: Boolean,
  }
};
</script>
<style scope>
@import "./style/arrow-left-right-buttons.css";
</style>
