<template>
    <div class="d-flex align-items-center title">
        <div class="d-none display-375px-flex mx-sm-4 mx-1 align-items-center">
            <div class="main-text fw-bold">
                {{ $t("station-donggang") }}
            </div>
            <img 
             v-if="isRoundTrip"
             src="@/assets/375/375_stations_arrow.svg"
             class="mx-sm-3 mx-1" />
            <div class="text-dark fw-bold">
                {{ $t("station-liuqiu") }}
            </div>
        </div>
        <div class="round-trip px-2">
            {{ $t("round-trip") }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isRoundTrip: Boolean,
    }
}
</script>
<i18n>
{
    "en": {
        "title-select-ship": "Select trips",
        "round-trip": "Roundtrip",
        "station-donggang": "Donggang",
        "station-liuqiu": "Liuqiu"
    },
    "zh-TW": {
        "title-select-ship": "選擇船班時間",
        "round-trip": "來回票",
        "station-donggang": "東港",
        "station-liuqiu": "小琉球"
    }
}
</i18n>