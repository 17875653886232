export default {
    methods: {
        imgPath( fileName ) {
            return require('@/assets/1920/1920_' + fileName + '.png' );
        },
        imgM768Path( fileName ) {
            return require('@/assets/768/768_' + fileName + '.png' );
        },
        imgMPath( fileName ) {
            return require('@/assets/375/375_' + fileName + '.png' );
        },
    }
}