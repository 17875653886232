<template>
    <div 
     class="modal fade" 
     :id="targetID" 
     data-bs-backdrop="static" 
     data-bs-keyboard="false" 
     tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content rounded-10px shop-panel">
                <div class="modal-header p-0 border-0">
                    <div class="title-row w-100 d-sm-flex d-none">
                        <span class="title">
                            {{ $t('title-refund-order') }}
                        </span>
                    </div>
                </div>
                <div v-if="!isRefunding&&!isSuccess&&!isFail">
                    <div class="modal-body py-sm-0 pb-0">
                        <div class="fw-bold text-main-color my-3 fs-1p4rem">
                            {{ $t("modal-refund-order-body-text-1") }}
                        </div>
                        <div>
                            <ins class="fw-bold text-main-color">{{ $t("modal-refund-order-body-text-2") }}</ins>
                            <br>
                            <span>{{ $t("modal-refund-order-body-text-3") }}</span>
                            <br>
                            <span>{{ $t("modal-refund-order-body-text-4-1") }}</span><ins class="fw-bold text-main-color">{{ $t("modal-refund-order-body-text-4-2") }}</ins><span>{{ $t("modal-refund-order-body-text-4-3") }}</span>
                            <br><span>{{ $t("modal-refund-order-body-text-5") }}</span>
                            <br><span>{{ $t("modal-refund-order-body-text-6") }}</span>
                        </div>
                        <div>
                            {{ $t("modal-refund-order-body-text-3") }} 
                        </div>

                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                            class="btn-bottom w-m-hf main-no-extend mx-1"
                            data-bs-dismiss="modal"
                            >
                                <span class="text-nowrap">{{ $t('btn-no') }}</span>
                        </button>
                        <button 
                            class="btn-bottom w-m-hf mx-1"
                            v-on:click="refundOrder()"
                            >
                                <span class="text-nowrap">{{ $t('btn-yes') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="isSuccess">
                    <div class="modal-body py-sm-0 pb-0">
                        <div class="fw-bold text-main-color my-3 fs-1p4rem">
                            {{ $t("modal-refund-order-body-text-success") }}
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                            class="btn-bottom w-m-f main"
                            data-bs-dismiss="modal"
                            v-on:click="backHomePage()"
                            >
                                <span class="text-nowrap">{{ $t('btn-to-homepage') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="isFail">
                    <div class="modal-body pt-sm-3 pb-0">
                        <div>
                            <img class="model-icon" src="@/assets/icons/icon_error.svg">
                        </div>
                        <div class="fw-bold text-danger my-3 fs-1p4rem">
                            {{ $t("modal-refund-order-body-text-fail-1") }}
                        </div>
                        <div>
                            {{ $t("modal-refund-order-body-text-fail-2") }}
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                            class="btn-bottom w-m-f main"
                            data-bs-dismiss="modal"
                            v-on:click="refreshPage()"
                            >
                                <span class="text-nowrap">{{ $t('btn-refresh') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="isRefunding">
                    <div class="modal-body py-3">
                        <div class="fw-bold text-main-color my-3 fs-1p4rem">
                            {{ $t("modal-refund-order-body-text-loading") }}
                        </div>
                        <div class="spinner-border text-main-color" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="py-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";

export default {
    props: {
        targetID: String,
        orderNumber: String,
        orderInfo: String,
    },
    data() {
        return {
            ts: {},
            isRefunding: false,
            isSuccess: false,
            isFail: false,
        }
    },
    methods: {
        async refundOrder() {
            this.isRefunding = true ;
            if ( this.orderNumber !== "" && this.orderInfo !== "" ) {
                
                let result = await this.ts.sendRefundTicketsRequest(this.orderNumber, this.orderInfo) ;

                if ( result.status === 200 && result.data[0].success ) {
                    this.isSuccess = true ;
                    this.isRefunding = false ;
                }
                else {
                    this.isFail = true ;
                    this.isRefunding = false ; 
                }

            }
        },
        backHomePage() {
            this.$router.push({
                name: "MainPage",
            });
        },
        refreshPage() {
            window.location.href = this.$route.fullPath ;
        }
    },
    mounted() {
        this.ts = new TicketService() ;
    }
}
</script>
<i18n>
{
    "en": {
        "title-refund-order": "Cancel the operation",
        "modal-refund-order-body-text-1": "Are you sure you want to refund the ticket?",
        "modal-refund-order-body-text-2": "When the departure ticket is refunded, the return ticket will be refunded at the same time. ",
        "modal-refund-order-body-text-3": "The refund period is within three days after the payment is completed.",
        "modal-refund-order-body-text-4-1": "And a ",
        "modal-refund-order-body-text-4-2": "10% handling fee",
        "modal-refund-order-body-text-4-3": " will be charged,",
        "modal-refund-order-body-text-5": "The refund process varies according to the operating time of each card-issuing bank.",
        "modal-refund-order-body-text-6": "It takes about a few working days to complete.",
        "modal-refund-order-body-text-loading": "Waiting",
        "modal-refund-order-body-text-success": "Cancel successful",
        "modal-refund-order-body-text-fail": "Fail",
        "modal-refund-order-body-text-fail-2": "Please try it later."
    },
    "zh-TW": {
        "title-refund-order": "取消訂單",
        "modal-refund-order-body-text-1": "您確定要退票嗎？",
        "modal-refund-order-body-text-2": "去程票退票時將會同時退掉回程票   ",
        "modal-refund-order-body-text-3": "退費期間為付款完成後三天內，",
        "modal-refund-order-body-text-4-1": "並酌收",
        "modal-refund-order-body-text-4-2": "手續費10%",
        "modal-refund-order-body-text-4-3": "，",
        "modal-refund-order-body-text-5": "退費流程依據各發卡銀行作業時間不定，",
        "modal-refund-order-body-text-6": "約需幾個工作天完成。",
        "modal-refund-order-body-text-loading": "讀取中",
        "modal-refund-order-body-text-success": "退票成功",
        "modal-refund-order-body-text-fail": "退票失敗",
        "modal-refund-order-body-text-fail-2": "請稍後再試"
    }
}
</i18n>