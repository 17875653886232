<template>
    <div class="row">
        <div class="col-xl-6 col-12 text-start my-sm-0 my-2" v-if="scheduleOutbound!==undefined">
            <div class="d-lg-flex d-block my-sm-0 my-1">
                <div class="text-nowrap my-sm-0 my-1 fw-bold text-black-50">{{ $t("outbound-date") }}</div>
                <div class="mx-lg-2 mx-0">{{ scheduleOutbound.date }} ({{ getWeekday(scheduleOutbound.date, $i18n.locale) }}) {{ scheduleOutbound.time }}</div>
            </div>
            <div class="text-start fs-1 fw-bold d-flex align-items-center">
                <span class="text-main-color">{{$t(stationOutbound)}}</span>
                <div class="pointer to-right"></div> {{$t(stationInbound)}}
            </div>
        </div>
        <div class="col-xl-6 col-12 text-start my-sm-0 my-2" v-if="scheduleInbound!==undefined">
            <div class="d-lg-flex d-block my-sm-0 my-1">
                <div class="text-nowrap my-sm-0 my-1 fw-bold text-black-50">{{ $t("inbound-date") }}</div>
                <div class="mx-lg-2 mx-0">{{ scheduleInbound.date }} ({{ getWeekday(scheduleInbound.date, $i18n.locale) }}) {{ scheduleInbound.time }}</div>
            </div>
            <div class="text-start fs-1 fw-bold d-flex align-items-center">
                <span class="text-main-color">{{$t(stationInbound)}}</span>
                <div class="pointer to-right"></div> {{$t(stationOutbound)}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        scheduleOutbound: Object,
        stationOutbound: String,
        scheduleInbound: Object,
        stationInbound: String,
    }
}
</script>
<i18n>
{
    "en": {
        "outbound-date": "Departure",
        "inbound-date": "Return"
    },
    "zh-TW": {
        "outbound-date": "去程日期",
        "inbound-date": "回程日期"
    }
}
</i18n>
<style scope>
@import './style/schedule-route.css';
</style>