<template>
    <div class="news-list accordion col-md-3 col-11 my-2" id="accordion">
        <div 
         v-for="(item, index) in lists" :key="index"
         class="accordion-item border-0">
            <h2 class="accordion-header" id="headingOne">
            <button 
             class="accordion-button collapsed" 
             type="button" data-bs-toggle="collapse" 
             :data-bs-target="'#collapse'+index" 
             aria-expanded="true" 
             aria-controls="collapseOne"
            >
                <span class="fs-1p4rem bold">{{ item.year }}</span>
            </button>
            </h2>
            <div 
             :id="'collapse'+index" class="accordion-collapse collapse" 
             aria-labelledby="headingOne" 
             data-bs-parent="#accordion">
            <div class="accordion-body">
                <div class="list-group date-list">
                    <div 
                     v-for="(subItem, subIndex) in item.list" :key="subIndex"
                     class="list-group-item"
                     :class="{'start':subIndex===0, 'end':subIndex===item.list.length-1}">
                        <div 
                         class="d-flex justify-content-between py-2 month cursor-pointer"
                         :class="{'active':subItem.showing}"
                         v-on:click="showingTargetMonth(item.year, subItem.month)"
                        >
                            <span>{{ subItem.month }}月</span>
                            <span>({{ subItem.content.length }})</span>
                        </div>
                        <transition name="fade">
                        <ul class="list-group" v-if="subItem.showing">
                            <li 
                             v-for="(liItem, liIndex) in (subItem.content)" :key="liIndex"
                             class="list-group-item day"
                             :class="{
                                'end':liIndex===subItem.content.length-1
                             }"
                            >
                                <router-link 
                                 :to="{
                                    path: '/news/' + item.year + '/' + subItem.month + '/' + liIndex + '#post',
                                 }"
                                 class="text-title-color no-text-decoration"
                                 :class="{
                                    'active':
                                        parseInt(subItem.month)===parseInt($route.params.month)
                                     && parseInt(liIndex)===parseInt($route.params.id),
                                 }"
                                >
                                    {{ timeToDateString(liItem.created_time) }}
                                </router-link>
                            </li>
                        </ul>
                        </transition>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        lists: Array,
    },
    watch: {
        '$route': function() {
            this.showingTargetMonth(this.$route.params.year, this.$route.params.month);
        }
    },
    methods: {
        timeToDateString( time ) {
            let date = new Date(time);
            // console.log(date);
            return (date.getMonth()+1) + ' / ' + date.getDate() ;
        },
        showingTargetMonth( targetYear, targetMonth ) {
            this.hideAllMonth() ;
            for ( let i = 0 ; i < this.lists.length ; i++ ) {
                // console.log(this.lists[i].year, targetYear);
                if ( this.lists[i].year === parseInt(targetYear) ) {
                    for ( let monthIndex = 0 ; monthIndex < this.lists[i].list.length ; monthIndex++ ) {
                        if ( this.lists[i].list[monthIndex].month === parseInt(targetMonth) ) {
                            this.lists[i].list[monthIndex].showing = true ;
                        }
                    }
                }
                
            }
        },
        hideAllMonth() {
            for ( let i = 0 ; i < this.lists.length ; i++ ) {
                for ( let monthIndex = 0 ; monthIndex < this.lists[i].list.length ; monthIndex++ ) {
                    this.lists[i].list[monthIndex].showing = false ;
                }
            }
        },
    },
    mounted() {
        this.showingTargetMonth(this.$route.params.year, this.$route.params.month);
    }
}
</script>
<style scope>
@import './style/news-list.css';
</style>