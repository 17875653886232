<template>
    <div>
        <div class="title-row">
            <span class="title">{{ $t('title-customer-info') }}</span>
            <RouteTitle
             :isRoundTrip="true"
            />
        </div>
        <div class="content pt-sm-4 pt-3">
            <div class="text-main-color text-start mb-sm-3 mb-0">
                <span>{{ $t("hint-fill-customer-info") }}</span>
                <span class="fw-bold">{{($i18n.locale==='zh-TW'?' 「':' "') + $t("btn-next") + ($i18n.locale==='zh-TW'?'」':'"')}}</span>
            </div>
            <form class="needs-validation d-flex justify-content-between flex-column flex-sm-row">
                <div class="col-xl-5 col-sm-6 col-12">
                    <!-- 訂票人姓名 -->
                    <div class="d-sm-flex col-12 d-block align-items-center  my-4 justify-content-lg-between justify-content-center">
                        <div class="d-flex justify-content-start justify-content-sm-end my-1 text-black-50 col-sm-4 col-12 text-start">
                            <label for="customer-name" class="text-nowrap">
                                {{ $t("input-label-name") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="col-sm-7 col-12">
                            <input 
                            type="text"
                            class=" w-100 px-lg-3 px-2 py-1 form-control"
                            :placeholder="$t('input-placeholder-name')"
                            v-model="input.name"
                            v-on:blur="validateForm('name')"
                            :class="{ 
                                'is-valid': validateArray[0].isValid,
                                'is-invalid': !validateArray[0].isValid && validateArray[0].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                        </div>
                    </div>
                    <!-- 出生年月日 -->
                    <div class="d-sm-flex col-12 d-block align-items-center justify-content-lg-between justify-content-center  my-4">
                        <div class="d-flex justify-content-start justify-content-sm-end my-1 text-black-50 col-sm-4 col-12 text-start">
                            <label for="customer-birthday" class="text-nowrap ">
                                {{ $t("input-label-birthday") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="col-sm-7 col-12">
                            <input 
                             class="w-100 px-md-3 py-1 form-control display-desktop " 
                             type="date"
                             :max="maxDate"
                             v-model="input.birthday"
                             :disabled="sendingRequest"
                            >
                            <div 
                             v-on:click="isShowingDateSelect=true"
                             class="form-control display-mobile"
                            >
                                <!-- {{ formData.dates.return }} ({{ getWeekday(formData.dates.return, $i18n.locale) }}) -->
                                <div class="">
                                    {{ input.birthday }}
                                </div>
                            </div>
                        </div>
                    </div>
                     <!-- 行動電話 -->
                    <div class="d-sm-flex col-12 d-block align-items-center justify-content-lg-between justify-content-center  mt-4 my-sm-4">
                        <div class="d-flex justify-content-start justify-content-sm-end my-1 text-black-50 col-sm-4 col-12">
                            <label for="customer-name" class="text-nowrap ">
                                {{ $t("input-label-phone") }}<span class="text-main-color">*</span>
                            </label>
                        </div>
                        <div class="col-sm-7 col-12">
                            <input 
                            type="text"
                            class="w-100 px-md-3 py-1 form-control "
                            :placeholder="$t('input-placeholder-phone')"
                            v-model="input.phone"
                            v-on:blur="validateForm('phone')"
                            :class="{ 
                                'is-valid': validateArray[3].isValid,
                                'is-invalid': !validateArray[3].isValid && validateArray[3].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                        </div>
                    </div>
                </div>  
                <div class=" my-sm-4 ccol-xl-5 col-sm-6 col-12">
                <!-- 身份證字號 input-->
                    <div class="d-sm-flex col-12 d-block align-items-start my-sm-0 my-4 justify-content-between">
                        <div class="d-flex justify-content-between justify-content-end  my-1 text-black-50 col-sm-4 col-12 text-start">
                            <label for="customer-id" class="text-nowrap d-sm-block d-flex align-items-center">
                                {{ $t("input-label-id") }}<span class="text-main-color">*</span><br>
                                <span class="fs-0p8rem">({{ $t("input-label-ispassport") }})</span>
                            </label>
                            <div class="form-check mx-2 display-mobile">
                                <input 
                                 class="form-check-input" 
                                 type="checkbox" 
                                 v-model="input.ispassport"
                                 v-on:change="validateForm('id')"
                                 :disabled="sendingRequest"
                                >
                                <label class="form-check-label fs-0p8rem mx-1 my-auto">
                                    {{ $t("input-checkbox-label-ispassport") }}
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-7 col-12">
                            <input 
                            type="text"
                            class="w-100 px-md-3 px-2 py-1 form-control"
                            :placeholder="$t('input-placeholder-id')"
                            v-model="input.id"
                            v-on:blur="validateForm('id')"
                            :class="{ 
                                'is-valid': validateArray[2].isValid,
                                'is-invalid': !validateArray[2].isValid && validateArray[2].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                            <div class="form-check position-absolute display-desktop m-2 m-sm-0">
                                <input 
                                 class="form-check-input" 
                                 type="checkbox" 
                                 v-model="input.ispassport"
                                 v-on:change="validateForm('id')"
                                >
                                <label class="form-check-label">
                                    {{ $t("input-checkbox-label-ispassport") }}
                                </label>
                            </div>
                        </div>
                    </div>
                <!-- 折扣碼 -->
                      <div class="mt-sm-4 d-sm-flex col-12 d-block  justify-content-between justify-content-center">
                        <div class="d-flex justify-content-start  my-1 text-black-50 col-sm-4 col-12">
                          <label for="customer-name" class="text-nowrap ">
                              {{ $t("input-label-coupon") }}<span class="text-main-color">*</span>
                          </label>
                      </div>
                      <div class="col-sm-7 col-12 d-flex">
                          <input 
                          type="text"
                          class="w-75 px-md-3 py-1 form-control "
                          :placeholder="$t('input-placeholder-coupon')"
                          v-model="input.coupon"
                          >
                          <button
                            class="btn-coupon-check w-m-f"
                            :class="{'disabled':sendingRequest}"
                            @click.prevent="checkCouponCode()"
                          >
                            <span class="text-nowrap">{{ $t('btn-use') }}</span>
                            </button>
                        </div>
                      </div>
                   <div class="text-start mt-1 fs-6" :style="couponTextColor"><span class="">{{ couponStatus }}</span></div>
                </div>
            </form>
            <div class="py-sm-1"></div>
            <hr class="mb-0">
        </div>
        <div class="bottom">
            <div class="group justify-content-between">
                <button
                 class="btn-bottom w-m-f"
                 :class="{'disabled':sendingRequest}"
                 v-on:click="btn_pre()"
                >
                <span class="text-nowrap">{{ $t('btn-pre') }}</span>
                </button>
                <button 
                 class="btn-bottom main w-m-f"
                 :class="{ 
                    'disabled': !isFormValid,
                    'loading': sendingRequest,
                 }"
                 v-on:click="btn_next()"
                 
                >
                    <span 
                     v-if="!sendingRequest"
                     class="text-nowrap"
                    >{{ $t('btn-next') }}
                    </span>
                    <span
                     v-if="sendingRequest"
                    >
                     <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                     {{ $t('btn-next-loading') }}
                    </span>

                </button>
            </div>
            
        </div>


        <div v-if="isShowingDateSelect">
            <CustomDateSmoothPicker 
                :headerName="$t('label-choose-date')"
                :maxDate="maxDate"
                :minDate="null"
                :target="'birthday'"
                @birthday="scrollResult"
                :currentYear="getCurrentYear()"
                :currentMonth="getCurrentMonth()"
                :currentDate="getCurrentDate()"
            />
        </div>
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";
import RouteTitle from "@/components/layout-components/shop-panel/route-title/route-title.vue";
import CustomDateSmoothPicker from "./components/custom-date-smooth-picker.vue";

export default {
    components: {
        RouteTitle,
        CustomDateSmoothPicker,
    },
    data() {
        return {
            ts: {},
            maxDate: "",
            isShowingDateSelect: false,
            sendingRequest: false,
            couponStatus:"",
            couponTextColor:"",
            input: {
                name: "",
                birthday: "",
                id: "",
                phone: "",
                coupon:"",
                ispassport: false,
            },
            validateArray: [
                { isValid: false, isBlur: false, target: "name" },
                { isValid: true, isBlur: true, target: "birthday" },
                { isValid: false, isBlur: false, target: "id" },
                { isValid: false, isBlur: false, target: "phone" }
            ]
        }
    },
    computed: {
        isFormValid() {
            for ( let i = 0 ; i < this.validateArray.length ; i++ ) {
                if ( !this.validateArray[i].isValid ) 
                    return false ;
            }
            return true ;
        }
    },
    methods: {
        getCurrentYear() {
            return parseInt(new Date(this.input.birthday).getFullYear());
        },
        getCurrentMonth() {
            return parseInt(new Date(this.input.birthday).getMonth()+1);
        },
        getCurrentDate() {
            return parseInt(new Date(this.input.birthday).getDate());
        },
        scrollResult( result ) {
            // TODO: result 若允許為空 則須加額外判斷避免直接空值覆蓋既有數值 ;
            this.input.birthday = result;
            this.isShowingDateSelect = false;
        },
        validateForm( target ) {
            if ( target === 'name' || target === 'form' ) {
                this.validateArray[0].isBlur = true ;
                if ( this.input.name !== "" ) {
                    this.validateArray[0].isValid = true ;
                }
                else {
                    this.validateArray[0].isValid = false ;
                }
            }
            if ( target === 'id' || target === 'form' ) {
                this.validateArray[2].isBlur = true ;
                if ( !this.input.ispassport && this.validateIDNumber(this.input.id) ) {
                    this.validateArray[2].isValid = true ;
                }
                else if ( this.input.ispassport && this.validatePassport(this.input.id) ) {
                    this.validateArray[2].isValid = true ;
                }
                else {
                    this.validateArray[2].isValid = false ;
                }
            }
            if ( target === 'phone' || target === 'form' ) {
                this.validateArray[3].isBlur = true ;
                if ( this.validatePhoneNumber(this.input.phone) ) {
                    this.validateArray[3].isValid = true ;
                }
                else {
                    this.validateArray[3].isValid = false ;
                }
            }
        },
        btn_pre() {
            if ( !this.sendingRequest ) {
                this.$router.push({
                    name: "Booking_Ship",
                    query: {
                        o_d: this.$route.query.o_d,
                        i_d: this.$route.query.i_d,
                        t_a: this.$route.query.t_a,
                        t_c: this.$route.query.t_c,
                    }
                });
            }
        },
        async btn_next() {
            if ( this.isFormValid && !this.sendingRequest ) {
                this.sendingRequest = true ;
                let orderResult = await this.ts.sendBookingRequest(
                    this.input.name, this.input.birthday, this.input.id, this.input.phone, this.input.ispassport,
                    this.input.coupon,this.$route.query.o_d, this.$route.query.o_t, this.$route.query.i_d, this.$route.query.i_t,
                    this.$route.query.t_a, this.$route.query.t_c, "DL", null
                ) ;
                // console.log(orderResult);
                if ( orderResult.status === 200 ) {
                    this.$router.push({
                        name: "Order_Info",
                        query: {
                            o_n: orderResult.data.order_number,
                            o_i: this.input.phone,
                            is_booking: true,
                        }
                    });
                }
                else {
                    console.log('error', orderResult);
                    this.sendingRequest = false ;
                }
            }
            else if ( !this.sendingRequest ) {
                this.validateForm('form');
            }
        },
        init() {
            this.ts = new TicketService() ;
            let date = this.getToday();
            this.maxDate = date.year + '-' + date.md
              
            this.input.birthday = this.maxDate ;
        },
       async checkCouponCode() {
            console.log("aaasaa",)
          let res =  await this.ts.checkCouponCode(this.$route.query.t_a, this.$route.query.t_c, this.input.coupon)
          if(res.status === 200){
              this.couponStatus = "折扣碼使用成功"
              this.couponTextColor = "color:#12A1D3"
          }else{
              this.couponStatus = res.message + `，請重新輸入或洽詢客服人員。`
              this.couponTextColor = "color:#FF0000"
          }
           console.log("this.couponStatus",this.couponStatus)
        }

    },
    async mounted() {
        this.init() ;
    }
}
</script>
<i18n>
{
    "en": {
        "title-customer-info": "Information",
        "round-trip": "Roundtrip",
        "station-donggang": "Donggang",
        "station-liuqiu": "Liuqiu",
        "btn-pre": "Back",
        "btn-use":"Apply",
        "btn-next": "Confirm",
        "btn-next-loading": "Loading",
        "hint-fill-customer-info": "Please check all the information is correct, and click",
        "input-placeholder-name": "Name",
        "input-placeholder-birthday": "Birthday",
        "input-placeholder-id": "ID number or Passport number",
        "input-placeholder-phone": "Phone",
        "input-placeholder-coupon":"Coupon code",
        "input-label-name": "Name",
        "input-label-birthday": "Birthday",
        "input-label-id": "ID number",
        "input-label-phone": "Phone",
        "input-label-coupon":"Coupon code",
        "input-label-ispassport": "Tick the passport number",
        "input-checkbox-label-ispassport": "Passport number",
        "label-choose-date": "Birthday Date"
    },
    "zh-TW": {
        "title-customer-info": "訂票人資訊",
        "round-trip": "來回票",
        "station-donggang": "東港",
        "station-liuqiu": "小琉球",
        "btn-pre": "上一步",
        "btn-use":"使用",
        "btn-next": "確認訂票",
        "btn-next-loading": "請稍候",
        "hint-fill-customer-info": "※ 輸入訂票人資訊，輸入完請確認是否無誤，請按",
        "input-placeholder-name": "請輸入訂票人姓名",
        "input-placeholder-birthday": "19xx-xx-xx",
        "input-placeholder-id": "身分證字號或護照號碼",
        "input-placeholder-phone": "請輸入行動電話",
        "input-placeholder-coupon":"請輸入折扣碼",
        "input-label-name": "訂票人姓名",
        "input-label-birthday": "出生年月日",
        "input-label-id": "身分證字號",
        "input-label-phone": "行動電話",
        "input-label-coupon":"輸入折扣碼",
        "input-label-ispassport": "護照號碼請勾選",
        "input-checkbox-label-ispassport": "護照號碼",
        "label-choose-date": "選擇生日"
    }
}
</i18n>