import { render, staticRenderFns } from "./cooperate-stores.vue?vue&type=template&id=6b88a1bd&"
import script from "./cooperate-stores.vue?vue&type=script&lang=js&"
export * from "./cooperate-stores.vue?vue&type=script&lang=js&"
import style0 from "./cooperate-stores.vue?vue&type=style&index=0&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/cooperation-list.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpages%2Fhome%2Fcooperate-stores-view%2Fcooperate-stores%2Fcooperate-stores.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports