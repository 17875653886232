<template>
  <div class="text-black">
    <div class="cover">
      <div class="gray-background" v-on:click="confirmScroll"></div>
      <div class="smooth-picker-layout">
        <div
          class="bg-white w-100 smooth-picker-header d-flex align-items-center justify-content-center"
        >
          <div class="col-3">
          </div>
          <div class="col-6">
            {{ headerName }}
          </div> 
          <div class="col-3 text-end">
            <span 
             class="smooth-picker-confirm mx-2" 
             :class="{'disabled':componentStatus.can_confirm}"
             v-on:click="confirmScroll(componentStatus.can_confirm)" 
             v-if="componentStatus.can_confirm"
            >
             {{ $t('btn-confirm') }}
            </span>
          </div>
        </div>
        <div class="smooth-picker-body date">
          <smooth-picker ref="smoothPicker" :data="data" :change="dataChange" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headerName: String,
    target: String,
    maxDate: String,
    minDate: String,
    currentYear: Number,
    currentMonth: Number,
    currentDate: Number,
  },
  watch: {
    'data': {
      deep:true,
      immediate:true,
      handler: function() {
        // console.log(val);
        if ( this.can_confirm() ) {
          this.componentStatus.can_confirm = true;
        }
        else {
          this.componentStatus.can_confirm = false;
        }
      },
    }
  },
  data() {
    const nowYear = new Date().getFullYear();
    const years = [];
    for (let i = 1900; i <= nowYear; i++) {
      years.push(i);
    }
    return {
      componentStatus: {
        can_confirm: false,
      },
      data: [
        {
          currentIndex: years.length-1,
          flex: 3,
          list: years,
          textAlign: "center",
          className: "row-group",
        },
        {
          currentIndex: new Date().getMonth(),
          flex: 3,
          list: [...Array(12)].map((m, i) => i + 1),
          textAlign: "center",
          className: "row-group",
        },
        {
          currentIndex: new Date().getDate()-1,
          flex: 3,
          list: [...Array(30)].map((d, i) => i + 1),
          // onClick: this.clickOnDay,
          textAlign: "center",
          className: "item-group",
        },
      ],
    };
  },
  methods: {
    init() {
      this.setCurrentIndex();
    },
    setCurrentIndex(){
      let yearIndex = this.data[0].list.indexOf(this.currentYear);
      let monthIndex = this.data[1].list.indexOf(this.currentMonth);
      let dateIndex = this.data[2].list.indexOf(this.currentDate);
      // console.log(yearIndex, this.data[0].list.length);
      if ( yearIndex === -1 ) {
        yearIndex = this.data[0].list.length-1;
      }
      if ( monthIndex === -1 ) {
        monthIndex = new Date().getMonth();
      }
      if ( dateIndex === -1 ) {
        dateIndex = new Date().getDate()-1;
      }


      this.data[0].currentIndex = yearIndex;
      this.$refs.smoothPicker.setGroupData(0, {
          ...this.data[0],
          ...{ yearIndex },
      });
      this.data[1].currentIndex = monthIndex;
      this.$refs.smoothPicker.setGroupData(1, {
          ...this.data[1],
          ...{ monthIndex },
      });
      this.data[2].currentIndex = dateIndex;
      this.$refs.smoothPicker.setGroupData(2, {
          ...this.data[2],
          ...{ dateIndex },
      });

    },
    can_confirm() {
      try {
        let date = this.getScrollSelectDate();
        return this.validateDateResult(date) ;
        
      } catch ( e ) {
        // console.log(e);
        return true ;
      }
    },
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    },
    dataChange(gIndex, iIndex) {
      // console.info("list", gIndex, iIndex);
      const ciList = this.$refs.smoothPicker.getCurrentIndexList();
      if (gIndex === 0 || gIndex === 1) {
        // year or month changed
        let currentIndex = 0;
        let monthCount = 30;
        let month = iIndex + 1; // month
        if (gIndex === 0) {
          // year
          month = this.data[1].list[ciList[1]];
        }
        switch (month) {
          case 2: {
            let selectedYear = this.data[0].list[ciList[0]]; // month
            if (gIndex === 0) {
              // year
              selectedYear = this.data[0].list[iIndex];
            }
            let isLeapYear = false;
            if (this.isLeapYear(selectedYear)) {
              isLeapYear = true;
            }
            monthCount = 28;
            currentIndex = 0;
            if (isLeapYear) {
              monthCount = 29;
              currentIndex = 0;
            }
            break;
          }
          case 4:
          case 6:
          case 9:
          case 11: {
            monthCount = 30;
            currentIndex = 0;
            break;
          }
          default: {
            monthCount = 31;
            currentIndex = 0;
          }
        }
        const list = [...Array(monthCount)].map((d, i) => i + 1);
        this.$refs.smoothPicker.setGroupData(2, {
          ...this.data[2],
          ...{ currentIndex, list },
        });
      }
      else if ( gIndex === 2 ) {
        this.data[2].currentIndex = iIndex;
      }
    },

    confirmScroll( canConfirm ) {
      if ( canConfirm ) {
        let dateResult = this.getScrollSelectDate();
        if ( this.validateDateResult( dateResult ) ) {
          this.$emit( this.target, dateResult );
        }
        else {
          console.log( '日期不符合限制' );
        }
      }
    },
    getScrollSelectDate() {
      const ciList = this.$refs.smoothPicker.getCurrentIndexList();
      let year = this.data[0].list[ciList[0]];
      let month = this.data[1].list[ciList[1]];
      let day = this.data[2].list[ciList[2]];
      if ( month < 10 ) {
        month = "0" + month;
      }
      if ( day < 10 ) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    },
    validateDateResult( result ) {

      if ( this.maxDate !== null && result > this.maxDate ) {
        return false ;
      }
      if ( this.minDate !== null && result < this.minDate ) {
        return false ;
      }

      return true ;

    }

  },
  mounted() {
    this.init();
  }
};
</script>
<i18n>
{
    "en": {
      "btn-confirm": "Confirm"
    },
    "zh-TW": {
      "btn-confirm": "確認"
    }
}
</i18n>
