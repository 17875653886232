export default {
    methods: {
        getArrayFromJsonText( jsonObjectName ) {
            let objectList = this.$t(jsonObjectName);
            let resultArray = [];
            for ( let i = 0 ; objectList[i] !== undefined ; i++ ) {
                resultArray.push(objectList[i]);
            }
            return resultArray;
        },
        takeListRange( index, capacity, targetArray ) {
            let array = [];
            if ( isNaN(index) ) 
                index = 1;
            for ( let i = 0 ; 
                  i < capacity && targetArray[ (index-1)*capacity + i ]!==undefined ;
                  i++ ) {
                array.push( targetArray[ (index-1)*capacity + i ] );
            }
            return array;
        },
    }

}