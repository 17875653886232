<template>
    <div class="px-0 my-4">
        <h4 class="text-title-color my-4">{{ $t("leuco-sapphire") }}</h4>
        <div class="breakpoint-over-w576px px-0">
            <InfoPCWidth
             :infos="infos"
            />
        </div>
        <div class="breakpoint-lower-w576px px-0">
            <InfoMobileWidth
             :infos="infos"
            />
        </div>
    </div>
</template>
<script>
import InfoPCWidth from './components/info-pc-width.vue';
import InfoMobileWidth from './components/info-moblie-width.vue'
export default {
    components: {
        InfoPCWidth,
        InfoMobileWidth,
    },
    data() {
        return {
            infos: this.$t('leuco-info')
        }
    },
    mounted() {
        
    }
}
</script>
<i18n src="./locale/locale.json"></i18n>