<template>
    <div>
        <Search />
    </div>
</template>
<script>
import Search from '@/components/pages/shop/order/search/search.vue';
export default {
    components: {
        Search,
    }
}
</script>