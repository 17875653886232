<template>
    <div 
     class="modal fade" 
     :id="targetID" 
     data-bs-backdrop="static" 
     data-bs-keyboard="false" 
     tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content rounded-10px shop-panel">
                <div class="modal-header p-0 border-0">
                    <div class="title-row w-100 d-sm-flex d-none">
                        <span class="title" v-if="status==='geting-prime'">
                            {{ $t('title-paying-status-waiting') }}
                        </span>
                        <span class="title" v-if="status==='get-prime-fail'">
                            {{ $t('title-paying-status-fail') }}
                        </span>
                        <span class="title" v-if="status==='sending-pay-request'">
                            {{ $t('title-paying-status-waiting') }}
                        </span>
                        <span class="title" v-if="status==='pay-request-fail'">
                            {{ $t('title-paying-status-fail') }}
                        </span>
                        <span class="title" v-if="status==='success-redirect'">
                            {{ $t('title-paying-status-waiting') }}
                        </span>
                    </div>
                </div>
                <div v-if="status==='geting-prime'">
                    <div class="modal-body py-sm-0 pb-0">
                        <WaitingText
                        />
                        <div class="spinner-border text-main-color mb-4" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div v-if="status==='get-prime-fail'">
                    <div class="modal-body py-sm-0 pb-0">
                        <div class="my-3">
                            <img class="model-icon" src="@/assets/icons/icon_error.svg">
                        </div>
                        <div class="fw-bold text-danger my-3 fs-1p4rem">
                            {{ $t("modal-paying-status-get-prime-fail-body-text-1") }} <br>
                            {{ $t("modal-paying-status-fail-notice-text-1") }}<br class="d-sm-block d-none">{{ $t("modal-paying-status-fail-notice-text-2") }} 
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                         class="btn-bottom w-m-f main"
                         data-bs-dismiss="modal"
                         v-on:click="emitReset()"
                        >
                            <span class="text-nowrap">{{ $t('btn-back-pay') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="status==='sending-pay-request'">
                    <div class="modal-body py-sm-0 pb-0">
                        <WaitingText
                        />
                        <div class="spinner-border text-main-color mb-4" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div v-if="status==='pay-request-fail'">
                    <div class="modal-body py-sm-0 pb-0">
                        <div class="my-3">
                            <img class="model-icon" src="@/assets/icons/icon_error.svg">
                        </div>
                        <div class="fw-bold text-danger my-3 fs-1p4rem" v-if="errorMessage===''">
                            {{ $t("modal-paying-status-pay-request-fail-body-text-1") }}<br>
                            {{ $t("modal-paying-status-fail-notice-text-1") }}<br class="d-sm-block d-none">{{ $t("modal-paying-status-fail-notice-text-2") }} 
                        </div>
                        <div v-else>
                            {{ errorMessage }}
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                         class="btn-bottom w-m-f main"
                         data-bs-dismiss="modal"
                         v-on:click="emitReset()"
                        >
                            <span class="text-nowrap">{{ $t('btn-back-pay') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="status==='success-redirect'">
                    <div class="modal-body py-sm-0 pb-0">
                        <WaitingText
                        />
                        <div class="spinner-border text-main-color mb-4" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import WaitingText from "./components/waiting-text.vue";
/*
    "waiting"
    "get-prime-fail"
    "sending-pay-request"
    "pay-request-fail"
    "success-redirect"
*/
export default {
    props: {
        targetID: String,
        status: String,
        errorMessage: String,
    },
    components: { 
        WaitingText
    },
    data() {
        return {
            
        }
    },
    methods: {
        emitReset() {
            this.$emit("emitReset");
        }
    },
    mounted() {

    }
}
</script>
<i18n>
{
    "en": {
        "title-paying-status-waiting": "Transaction processing Please wait",
        "title-paying-status-fail": "Fail",
        "modal-paying-status-get-prime-fail-body-text-1": "Error in Connect to Tappay",
        "modal-paying-status-pay-request-fail-body-text-1": "Transaction error",
        "modal-paying-status-fail-notice-text-1": "Please check connection and",
        "modal-paying-status-fail-notice-text-2": "creditcard information is currect",
        "btn-back-pay": "Back"
    },
    "zh-TW": {
        "title-paying-status-waiting": "請稍候",
        "title-paying-status-fail": "交易失敗",
        "modal-paying-status-get-prime-fail-body-text-1": "與Tappay取得連線時發生錯誤",
        "modal-paying-status-pay-request-fail-body-text-1": "交易發生錯誤",
        "modal-paying-status-fail-notice-text-1": "請確認連線狀態正常及",
        "modal-paying-status-fail-notice-text-2": "信用卡資訊是否無誤",
        "btn-back-pay": "返回付款"
    }
}
</i18n>