<template>
    <div 
     class="modal fade" 
     :id="targetID" 
     data-bs-backdrop="static" 
     data-bs-keyboard="false" 
     tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content rounded-10px shop-panel">
                <div class="modal-header p-0 border-0">
                    <div class="title-row w-100 d-sm-flex d-none">
                        <span class="title">
                            {{ $t('title-cancel-pay') }}
                        </span>
                    </div>
                    <!-- <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body pb-0">
                    <div class="fw-bold text-main-color my-3 fs-1p4rem">
                        {{ $t("modal-cancel-pay-body-text-1") }}
                    </div>
                    {{ $t("modal-cancel-pay-body-text-2") }}<br class="d-none d-sm-block">{{ $t("modal-cancel-pay-body-text-3") }}
                </div>
                <div class="modal-footer justify-content-center bottom border-0 d-flex">
                    <button 
                     class="btn-bottom w-m-hf mx-1"
                     data-bs-dismiss="modal"
                    >
                        <span class="text-nowrap">{{ $t('btn-no') }}</span>
                    </button>
                    <button 
                     class="btn-bottom main-no-extend w-m-hf mx-1"
                     data-bs-dismiss="modal"
                     v-on:click="backHomePage()"
                    >
                        <span class="text-nowrap">{{ $t('btn-yes') }}</span>
                    </button>
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Understood</button> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        targetID: String,
    },
    methods: {
        backHomePage() {
            this.$router.push({
                name: "MainPage",
            });
        }
    }
}
</script>
<i18n>
{
    "en": {
        "title-cancel-pay": "Cancel the payment",
        "modal-cancel-pay-body-text-1": "Are you sure you want to cancel the payment?",
        "modal-cancel-pay-body-text-2": "Cancelling the payment does not mean canceling the order.",
        "modal-cancel-pay-body-text-3": "If you want to pay again, please go to the booking inquiry to make the payment again."
    },
    "zh-TW": {
        "title-cancel-pay": "取消付款",
        "modal-cancel-pay-body-text-1": "您確定要取消付款嗎？",
        "modal-cancel-pay-body-text-2": "取消付款不等於取消訂單，若是要再次付款請至訂票查詢",
        "modal-cancel-pay-body-text-3": "重新進行付款作業。"
    }
}
</i18n>