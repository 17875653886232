import { apiGetSailings, apiPostBookingTickets, apiCheckCouponCode } from "@/apis/booking.js";
import { apiGetSearchOrder } from "@/apis/download.js";
import { apiPostCancelOrder, apiPostRefundTickets } from "@/apis/refund.js";
import { apiPostPay } from "@/apis/pay.js";
import dateUtils from "./utils/dateUtils.js";

export default class TicketService { 

    constructor() {
        this.DATE_UTILS = new dateUtils();
    }

    async getRouteShippingList(date, routeCode) { // ticket limit param -> (date, routeCode, ticketNum)
        let data = {
            date: date,
            route_code: routeCode,
        };

        let searchResult = await apiGetSailings(data);
        console.log(searchResult);
        if ( searchResult.status === 200 ) {
            let resultArray = [];
            for ( let i = 0 ; i < searchResult.data.length ; i++ ) {
                let item = {
                    data: searchResult.data[i],
                    disable: false,
                    isSpecial: false,
                    isFull: false,
                    isSelected: false,
                };
                item.disable = !this.checkTime( date, item.data.time, this.DATE_UTILS.getToday(), this.DATE_UTILS.getNowTime() ) ;
                // if ( item.data.booking + ticketNum >= item.data.quota ) {
                //     item.disable = true;
                //     item.isFull = true;
                // }
                if ( item.data.type === 'special' 
                    || item.data.type === 'speical' ) { // 'speical' 是錯誤拼，等api端有做修正再移除此判斷 ;
                    item.isSpecial = true;
                }
                resultArray.push(item);
            }

            return resultArray;
        }
        else {
            console.log('fail',searchResult);
            return [];
        }
    }
    checkTime( date, time, limitDate, limitTime ) {
        // 檢查輸入的時間是否過期(超過目標時間) ;
        if ( date > limitDate ) {
            return true ;
        }
        else if ( date === limitDate && time > limitTime ) {
            return true ;
        }
        return false;
    }
    async sendBookingRequest( name, birthday, id, phone, isPassport, coupon, dd, dt, rd, rt, aT, cT, rc, bUid ) {
        let requestData = {
            bee_uuid: bUid,
            route_code: rc,
            departure_date: dd + " " + dt,
            return_date: rd + " " + rt,
            name: name,
            birthday: birthday,
            id_number: id,
            phone: phone,
            is_passport: isPassport,
            coupon_code: coupon,
            adult: aT,
            children: cT
        } ;
        return await apiPostBookingTickets( requestData ) ;
        
    } 
    async getOrderInfo( oN, oI ) {
        return await apiGetSearchOrder({
            order_number: oN,
            info: oI,
        });
    }
    async sendCancelRequest( oN, oI ) {
        return await apiPostCancelOrder({
            orders: [
                {
                    order_number: oN,
                    phone: oI,
                },
            ],
        })
    }
    async sendPayRequest( oN, oI, prime, name, phone, email, type ) {
        return await apiPostPay({
            order_number: oN,
            info: oI,
            prime: prime,
            name: name,
            phone: phone,
            email: email,
            type: type
        })
    }
    async sendRefundTicketsRequest( oN, oI ) {
        let orderData = await this.getOrderInfo( oN, oI ) ;
        if ( orderData.status !== 200 ) {
            return false ;
        }
        
        let ticketArray = this.getRefundTicketArray( orderData.data.fares ) ;
        
        return await apiPostRefundTickets({
            tickets: ticketArray,
        }) ; 
        // let refundResult = await apiPostRefundTickets({
        //     tickets: ticketArray,
        // }) ; 
        // if ( refundResult.status === 200 ) {
        //     console.log( "refund result", refundResult ) ;
        //     return true ;
        // }
        // else {
        //     console.log( "refund result", refundResult ) ;
        //     return false ;
        // }   
        
    }
    getRefundTicketArray( fares ) {

        let ticketArray = [] ;

        for ( let fareIndex = 0 
            ; fareIndex < fares.length 
            ; fareIndex++ ) {

            for ( let simpleIndex = 0 
                ; simpleIndex < fares[fareIndex].simple_ticket_infos.length 
                ; simpleIndex++ ) {
                
                if ( fares[fareIndex].simple_ticket_infos[simpleIndex].out !== undefined &&
                     !fares[fareIndex].simple_ticket_infos[simpleIndex].out.expired &&
                     fares[fareIndex].simple_ticket_infos[simpleIndex].out.status !== 'used' &&
                     fares[fareIndex].simple_ticket_infos[simpleIndex].out.status !== 'refunded'
                   ) {
                    ticketArray.push({
                        ticket_number: fares[fareIndex].simple_ticket_infos[simpleIndex].out.number,
                        auth: fares[fareIndex].simple_ticket_infos[simpleIndex].out.auth,
                    });
                }
                if ( fares[fareIndex].simple_ticket_infos[simpleIndex].in !== undefined &&
                     !fares[fareIndex].simple_ticket_infos[simpleIndex].in.expired &&
                     fares[fareIndex].simple_ticket_infos[simpleIndex].in.status !== 'used' &&
                     fares[fareIndex].simple_ticket_infos[simpleIndex].in.status !== 'refunded'
                   ) {
                    ticketArray.push({
                        ticket_number: fares[fareIndex].simple_ticket_infos[simpleIndex].in.number,
                        auth: fares[fareIndex].simple_ticket_infos[simpleIndex].in.auth,
                    });
                }
                
            }

        }

        return ticketArray ;

    }

    canPickedTickets( fares ) {
        for ( let fareIndexs = 0 ; fareIndexs < fares.length ; fareIndexs++ ) {
            for ( let simpleInfoIndex = 0 
                ; simpleInfoIndex < fares[fareIndexs].simple_ticket_infos.length 
                ; simpleInfoIndex++ ) {
                if ( fares[fareIndexs].simple_ticket_infos[simpleInfoIndex].out !== undefined 
                  && fares[fareIndexs].simple_ticket_infos[simpleInfoIndex].out.status === 'created' ) {
                    return true ;
                }
            }
        }
        return false ;
    }
    canRefundOrder( fares ) {
        let array = this.getRefundTicketArray( fares ) ;
        return array.length > 0 ? true : false ;
    }

    async checkCouponCode( adult, children, coupon_code ) {
        let requestData = {
            adult:adult,
            children:children,
            coupon_code:coupon_code
        }
        return await apiCheckCouponCode(requestData)
    }
}