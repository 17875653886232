<template>
    <div>
        <div class="title-row">
            <span class="title">{{ $t('title-order-search') }}</span>
        </div>
        <div class="content pt-sm-4 pt-1">

            <form class="needs-validation">
                <div class="g-3 d-sm-flex justify-content-between my-sm-4 my-2">
                    <div class="col-sm-10 col-md-9 d-sm-flex col-12 d-block align-items-center my-sm-0 my-4">
                        <div class="d-flex justify-content-start my-1 text-black-50 col-sm-7 col-12">
                            <label for="customer-name" class="text-nowrap">
                                {{ $t("label-order-number") }}
                            </label>
                        </div>
                        <div class="col-sm-6 col-12">
                            <input 
                            type="text"
                            class="mx-md-2 mx-0 w-100 px-md-3 px-2 py-1 form-control"
                            :placeholder="$t('input-placeholder-order-number')"
                            v-model="input.orderNumber"
                            v-on:blur="validateForm('number')"
                            :class="{ 
                                'is-valid': validateArray[0].isValid,
                                'is-invalid': !validateArray[0].isValid && validateArray[0].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                        </div>
                    </div>
                </div>  
                <div class="g-3 d-sm-flex justify-content-between my-sm-4 my-2">
                    <div class="col-sm-10 col-md-9 d-sm-flex col-12 d-block align-items-center my-sm-0 my-4">
                        <div class="d-flex justify-content-start my-1 text-black-50 col-sm-7 col-12">
                            <label for="customer-name" class="text-nowrap d-sm-block d-flex align-items-center">
                                {{ $t("label-order-info") }}
                            </label>
                        </div>
                        <div class="col-sm-6 col-12">
                            <input 
                            type="text"
                            class="mx-md-2 mx-0 w-100 px-md-3 px-2 py-1 form-control"
                            :placeholder="$t('input-placeholder-order-info')"
                            v-model="input.orderInfo"
                            v-on:blur="validateForm('info')"
                            :class="{ 
                                'is-valid': validateArray[1].isValid,
                                'is-invalid': !validateArray[1].isValid && validateArray[1].isBlur,
                            }"
                            :disabled="sendingRequest"
                            >
                        </div>
                    </div>
                </div>
                <div>
                    <div class="form-check my-2 text-start">
                        <input 
                         class="form-check-input" 
                         type="checkbox" 
                         v-model="input.agree"
                        >
                        <label class="form-check-label text-start fs-1rem">
                            {{ $t("label-agreement") }}
                        </label>
                    </div>
                </div>
            </form>

            <div class="text-main-color">
                {{ returnMessage }}
            </div>
            <hr>
        </div>
        <div class="bottom">
            <div class="group justify-content-start py-0">
                <button
                 class="btn-bottom w-m-f"
                 :class="{'disabled':sendingRequest}"
                 v-on:click="btn_pre()"
                >
                    <span class="text-nowrap">{{ $t('btn-pre') }}</span>
                </button>
            </div>
            <div class="group justify-content-end py-0">
                <button 
                 class="btn-bottom main w-m-f"
                 :class="{ 
                    'disabled': !isFormValid,
                    'loading': sendingRequest,
                 }"
                 v-on:click="btn_next()"
                >
                    <span 
                     class="text-nowrap"
                     v-if="!sendingRequest"
                    >{{ $t('btn-next') }}</span>
                    <span
                     v-if="sendingRequest"
                    >
                     <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                     {{ $t('btn-next-loading') }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";

export default {
    data() {
        return {
            ts: {},
            input: {
                orderNumber: "",
                orderInfo: "",
                agree: false,
            },
            validateArray: [
                {isValid: false, isBlur: false, target: "number" },
                {isValid: false, isBlur: false, target: "info" },
            ],
            sendingRequest: false,
            returnMessage: "",
        }
    },
    computed: {
        isFormValid() {
            for ( let i = 0 ; i < this.validateArray.length ; i++ ) {
                if ( !this.validateArray[i].isValid ) 
                    return false ;
            }
            if ( !this.input.agree ) 
                return false ;

            return true ;
        }
    },
    methods: {
        btn_pre() {
            if ( !this.sendingRequest ) {
                this.$router.push({
                    name: "MainPage"
                });
            }
        },
        async btn_next() {
            if ( this.isFormValid && !this.sendingRequest ) {
                this.sendingRequest = true ;
                let result = await this.ts.getOrderInfo(this.input.orderNumber, this.input.orderInfo) ;
                if ( result.status === 200 ) {
                    this.$router.push({
                        name: "Order_Info",
                        query: {
                            o_n: this.input.orderNumber,
                            o_i: this.input.orderInfo,
                        }
                    }) ;
                }
                else {
                    this.returnMessage = result.message ;
                }
                this.sendingRequest = false ;
            }
        },
        validateForm( target ) {
            if ( target === 'number' || target === 'form' ) {
                this.validateArray[0].isBlur = true ;
                if ( this.input.orderNumber.length === 11 ) {
                    this.validateArray[0].isValid = true ;
                }
                else {
                    this.validateArray[0].isValid = false ;
                }
            }
            if ( target === 'info' || target === 'form' ) {
                this.validateArray[1].isBlur = true ;
                if ( this.validatePhoneNumber(this.input.orderInfo) ) {
                    this.validateArray[1].isValid = true ;
                }
                else {
                    this.validateArray[1].isValid = false ;
                }
            }
        },
    },
    async mounted() {
        this.ts = new TicketService();
    }
}
</script>
<i18n>
{
    "en": {
        "title-order-search": "Booking inquiry",
        "label-order-number": "Order number",
        "label-order-info": "Mobile phone number",
        "input-placeholder-order-number": "Order number",
        "input-placeholder-order-info": "Mobile phone number",
        "label-agreement": "I have clearly understood the terms of service, the finalized contract and the customer’s personal privacy policy items of the blue and white shipping online booking transaction, and I agree to abide by all regulations and provide the required personal information.",
        "btn-pre": "Back",
        "btn-next": "Inquiry",
        "btn-next-loading": "Waiting"
    },
    "zh-TW": {
        "title-order-search": "訂票查詢",
        "label-order-number": "請輸入訂單編號",
        "label-order-info": "請輸入取票人行動電話",
        "input-placeholder-order-number": "輸入訂單編號",
        "input-placeholder-order-info": "請輸入取票人行動電話",
        "label-agreement": "我已明確了解藍白航運線上訂票交易之服務條款、定型化契約及顧客個人隱私政策事項內容，並且同意遵守所有規定及提供所需之個人資料。",
        "btn-pre": "回到首頁",
        "btn-next": "送出查詢",
        "btn-next-loading": "請稍候"
    }
}
</i18n>