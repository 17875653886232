<template>
    <div>
        <CooperateStores/>
    </div>
</template>
<script>
import CooperateStores from '@/components/pages/home/cooperate-stores-view/cooperate-stores/cooperate-stores.vue'
export default {
    components: {
        CooperateStores
    }
}
</script>