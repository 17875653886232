<template>
    <div>
        <Info />
    </div>
</template>
<script>
import Info from '@/components/pages/shop/order/info/info.vue';
export default {
    components: {
        Info,
    }
}
</script>