<template>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav mr-auto d-flex align-items-center nav-text-size">
      <li class="nav-item active" v-for="(item, index) in navItems" :key="index">
        <router-link
         v-if="item.type==='route'"
         class="nav-link link-white fw-bold"
         :to="item.to"
         :disabled="item.disabled"
         :event="!item.disabled ? 'click' : ''"
        >
          {{ $t(item.name) }}
        </router-link>
        <a 
         v-else-if="item.type==='href'"
         class="nav-link link-white fw-bold" 
         :href="item.to"
         :disabled="item.disabled"
         :event="!item.disabled ? 'click' : ''"
        >
          {{ $t(item.name) }}
        </a>
        <span 
         v-else-if="item.type==='emitAction'"
         class="nav-link link-white fw-bold cursor-pointer" 
         :disabled="item.disabled"
         :event="!item.disabled ? 'click' : ''"
         v-on:click="$emit(item.to)"
        >
          {{ $t(item.name) }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
    props: {
        navItems: Array,
    }
}
</script>
