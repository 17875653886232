import { render, staticRenderFns } from "./leuco-info-section.vue?vue&type=template&id=36fb8bf2&"
import script from "./leuco-info-section.vue?vue&type=script&lang=js&"
export * from "./leuco-info-section.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/locale.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpages%2Fhome%2Fleuco-intro-view%2Fleuco-introduce%2Fcomponents%2Fleuco-info-section%2Fleuco-info-section.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports