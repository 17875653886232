import { render, staticRenderFns } from "./qa.vue?vue&type=template&id=1819e642&"
import script from "./qa.vue?vue&type=script&lang=js&"
export * from "./qa.vue?vue&type=script&lang=js&"
import style0 from "./qa.vue?vue&type=style&index=0&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/locale.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpages%2Fhome%2Fleuco-qa-view%2Fqa%2Fqa.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports