<template>
    <div>
        <div class="date-select-section shop-panel">
            <div class="title-row">
                <span class="title">{{ $t("online-booking") }}</span>
                <RouteTitle 
                 :isRoundTrip="true"
                />
            </div>

            <div class="body bg-white form-group">
                
                <MobileBookingSectionBodyStations />
                <div class="mx-auto pt-1 pb-3 px-1">
                        <div class="my-2 row-in-select justify-content-between">
                            <div class="d-block d-sm-flex col-sm-6 align-items-sm-center justify-content-between pe-sm-2 pe-lg-4">
                                <div class="text-nowrap text-start">
                                    {{ $t('outbound') }}
                                </div>
                                <input 
                                class="form-control input-ship-date display-desktop arrow-picker-icon mx-md-3" 
                                type="date"
                                v-model="formData.dates.departure"
                                :min="departureMin"
                                :max="departureMax"
                                >
                                <div 
                                v-on:click="setTargetAndShowScroll('departure-date')"
                                class="input-ship-date display-mobile justify-content-between"
                                >
                                    {{ formData.dates.departure }} ({{ getWeekday(formData.dates.departure, $i18n.locale) }})
                                    <div>
                                        <img 
                                        
                                        src="@/assets/375/375_booking_arrow.svg" alt=""
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="d-block d-sm-flex col-sm-6 align-items-sm-center justify-content-between ps-sm-2 ps-lg-4">
                                <div class="text-nowrap text-start">
                                    {{ $t('inbound') }}
                                </div>
                                <input 
                                class="form-control input-ship-date display-desktop arrow-picker-icon ms-md-3" 
                                type="date"
                                v-model="formData.dates.return"
                                :min="returnMin"
                                :max="returnMax"
                                >
                                <div 
                                v-on:click="setTargetAndShowScroll('return-date')"
                                class="input-ship-date display-mobile justify-content-between"
                                >
                                    {{ formData.dates.return }} ({{ getWeekday(formData.dates.return, $i18n.locale) }})
                                    <div>
                                        <img 
                                        src="@/assets/375/375_booking_arrow.svg" alt=""
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row-in-ticket">
                            <div class="col-12 ticket-types fs-1rem d-sm-flex justify-content-between" v-on:click="setTargetAndShowScroll('tickets')">
                                <div class="display-mobile align-items-start my-2 w-25">
                                    {{ $t('passengers') }}
                                </div>
                                <div 
                                 :class="{'en': $i18n.locale === 'en'}"
                                 class="ticket-type align-items-center justify-content-between pe-sm-2 pe-lg-4">
                                    <div class="my-2">
                                        {{ $t('ticket-type-adult') }}
                                    </div>
                                    <div class="input-layout">

                                        <NumberControl 
                                        class="d-none d-sm-block"
                                        :emitName="'adult-num'"
                                        :isPlus="false"
                                        @adult-num="controlNums"
                                        />
                                        <input 
                                        class="form-control input-ticket px-2 display-desktop mx-2"
                                        type="number" 
                                        value=0
                                        v-model="formData.tickets.adult"
                                        min="0"
                                        @change="checkTicketInput('adult')"
                                        >
                                        <NumberControl 
                                        class="d-none d-sm-block"
                                        :emitName="'adult-num'"
                                        :isPlus="true"
                                        @adult-num="controlNums"
                                        />
                                        <!-- <ArrowController
                                        class="display-desktop"
                                        @emitAdd="formData.tickets.adult++"
                                        @emitSub="formData.tickets.adult>0?(formData.tickets.adult--):''"
                                        /> -->

                                        <div class="display-mobile my-2 align-items-center">
                                            {{ formData.tickets.adult }}
                                        </div>
                                        
                                    </div>
                                </div>
                                <div 
                                 :class="{'en': $i18n.locale === 'en'}"
                                 class="ticket-type align-items-center justify-content-between ps-sm-2 ps-lg-4">
                                    <div class="my-2">
                                        {{ $t('ticket-type-child') }}
                                    </div>
                                    <div class="input-layout me-0 justify-content-between">
                                        <NumberControl 
                                        class="d-none d-sm-block"
                                        :emitName="'child-num'"
                                        :isPlus="false"
                                        @child-num="controlNums"
                                        />
                                        <input 
                                        class="form-control input-ticket px-2 display-desktop mx-2"
                                        type="number" 
                                        value=0
                                        v-model="formData.tickets.child"
                                        min="0"
                                        @change="checkTicketInput('child')"
                                        >
                                        <NumberControl 
                                        class="d-none d-sm-block"
                                        :emitName="'child-num'"
                                        :isPlus="true"
                                        @child-num="controlNums"
                                        />
                                        <!-- <ArrowController 
                                        class="display-desktop"
                                        @emitAdd="formData.tickets.child++"
                                        @emitSub="formData.tickets.child>0?(formData.tickets.child--):''"
                                        /> -->
                                        <div class="display-mobile my-2">
                                            {{ formData.tickets.child }}
                                        </div>
                                    </div>
                                </div>
                                <div class="d-sm-none d-flex align-items-center">
                                    <img 
                                    src="@/assets/375/375_booking_arrow.svg" alt=""
                                    >
                                </div>
                                
                            </div>
                        </div>
                        <div>
                            <div class="col-12 py-2 text-center" style="">
                                <div style="color:red; font-weight: bold;">
                                    {{ $t('onlineBookingNoTravelCard') }}<br class="d-block d-sm-none">
                                    {{ $t('useTravelCardAtVenue') }}
                                </div>
                            </div>
                            <div class="col-12">
                                <button 
                                :class="{ 'disabled': !dateIsLegal() }"
                                class="mainbtn booking-btn" 
                                v-on:click="btn_booking()"
                                >
                                    <!-- <img src="~@/assets/1920/1920_icon2.svg" alt=""> -->
                                    {{ $t('btn-online-booking') }}
                                </button>
                            </div>
                        </div>

                </div>
                
            </div>
            
        </div>
        <div 
         class="text-black px-0" 
         v-if="smoothPickerStatus.showing"
        >
            <div class="cover">
                <div 
                    class="gray-background"
                    v-on:click="confirmScroll" 
                >
                </div>
                <div 
                class="smooth-picker-layout"
                v-if="smoothPickerStatus.selecting==='date'"
                >
                    <div class="bg-white smooth-picker-header d-flex align-items-center justify-content-center">
                        <div class="col-3">
                        </div>
                        <div class="col-6">
                            {{ $t('label-select-date') }}
                        </div> 
                        <div class="col-3 text-end">
                            <span class="smooth-picker-confirm mx-2" v-on:click="confirmScroll" >{{ $t('btn-confirm') }}</span>
                        </div>
                    </div>
                    <div class="smooth-picker-body date">
                        <smooth-picker 
                            ref="smoothPickerDate" :data="scrollingDate" :change="dateDataChange" 
                        />
                    </div>
                    
                </div>
                <div
                class="smooth-picker-layout"
                v-if="smoothPickerStatus.selecting==='tickets'"
                >
                    <div class="bg-white smooth-picker-header d-flex align-items-center justify-content-center">
                        <div class="col-3">
                        </div>
                        <div class="col-6">
                            {{ $t('label-select-number') }}
                        </div>
                        <div class="col-3 text-end">
                            <span class="smooth-picker-confirm mx-2" v-on:click="confirmScroll" >{{ $t('btn-confirm') }}</span>
                        </div>
                    </div>
                    <div class="bg-white w-100 smooth-picker-header d-flex align-items-center justify-content-center">
                        <div class="w-50 text-center">
                            {{ $t("ticket-type-adult") }}
                        </div>
                        <div class="w-50 text-center">
                            {{ $t("ticket-type-child") }}
                        </div>
                    </div>
                    <div class="smooth-picker-body num">
                        <smooth-picker 
                            ref="smoothPickerTicket" :data="scrollingTickets" :change="ticketsDataChange" 
                        />
                    </div>
                </div>
                

            </div>
        </div>
    </div>
</template>
<script>
import getEnv from "@/utils/env.js";
import MobileBookingSectionBodyStations from "./components/mobile-booking-section-body-stations.vue";
import { SmoothPicker } from 'vue-smooth-picker';
import RouteTitle from "@/components/layout-components/shop-panel/route-title/route-title.vue";
import NumberControl from "@/components/layout-components/number-control-button/number-control-button.vue";

export default {
    components: {
        MobileBookingSectionBodyStations,
        SmoothPicker,
        RouteTitle,
        NumberControl,
    },
    data() {  
        return {
            formData: {
                tickets: {
                    adult: 0,
                    child: 0,
                },
                dates: {
                    departure: "",
                    return: "",
                },
            },
            totalTicketNum:40,
            smoothPickerStatus: {
                showing: false,
                selecting: "",
                target: "",
            },
            scrollingDate: [
                {
                    currentIndex: 0,
                    flex: 3,
                    list: [],
                    textAlign: 'center',
                    onClick: this.dateDataChange
                },
            ],
            scrollingTickets: [
                {
                    currentIndex: 0,
                    flex: 3,
                    list: [
                    0,1,2,3,4,5,6,7,8,9,10
                    ],
                    textAlign: 'center',
                    className: 'row-group'
                },
                {
                    currentIndex: 0,
                    flex: 3,
                    list: [
                    0,1,2,3,4,5,6,7,8,9,10
                    ],
                    textAlign: 'center',
                    className: 'row-group'
                },
            ],
        }
    },
    watch: {
        'formData.dates.departure': function() {
            if ( this.formData.dates.departure > this.formData.dates.return ) {
                this.formData.dates.return = this.formData.dates.departure;
            }
        },
        'formData.dates.return': function() {
            if ( this.formData.dates.departure > this.formData.dates.return ) {
                this.formData.dates.return = this.formData.dates.departure;
            }
        },
    },
    computed: {
        departureMin() {
            return this.dateIncrease(0) ;
        },
        departureMax() {
            return this.dateIncrease(62) ;
        },
        returnMin() {
            if ( this.formData.dates.departure !== "" ) {
                return this.formData.dates.departure ;
            }
            else 
                return this.departureMin ;
        },
        returnMax() {
            return this.departureMax ;
        },
    },
    methods: {
        init() {
            this.resizeEventHandler(); // fetch first size;
            this.formData.dates.departure = this.departureMin;
            this.formData.dates.return = this.returnMin;

            this.dateCreate(62, this.scrollingDate[0].list, new Date());
        },
        btn_booking() {
            if ( this.dateIsLegal() ) {
                if ( getEnv("VUE_APP_BOOKING_NEED_REDIRECT")==='true' ) {
                    window.open( 
                        getEnv("VUE_APP_ONLINE_TICKET_WEB_URL") 
                        + '/booking?book=true' 
                        + '&dd=' + this.formData.dates.departure 
                        + '&rd=' + this.formData.dates.return
                        + '&at=' + this.formData.tickets.adult
                        + '&ct=' + this.formData.tickets.child
                    );
                }
                else {
                    this.$router.push({
                        name: 'Booking_Ship',
                        query: {
                            // 出發時間
                            o_d: this.formData.dates.departure,
                            // 回家時間
                            i_d: this.formData.dates.return,
                            t_a: this.formData.tickets.adult,
                            t_c: this.formData.tickets.child
                        },
                    });
                }
                
            }
        },
        dateIsLegal() {
            if ( this.formData.dates.departure >= this.departureMin 
                && this.formData.dates.departure <= this.departureMax 
                && this.formData.dates.return >= this.returnMin 
                && this.formData.dates.return <= this.returnMax
                && this.formData.dates.departure <= this.formData.dates.return
                && ( this.formData.tickets.adult + this.formData.tickets.child ) > 0
               ) {
                return true;
            }
            else {
                return false ;
            }
        },
        dateCreate(num, array, start) {
            let date = new Date(start);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            let d = date.getDate();
            array.push(this.generateDateString(y,m,d, this.$i18n.locale));
            for ( let i = 0; i < num ; i++ ) {
                d++ ;
                if ( m === 2 ) {
                    let numOfFebruary = new Date(y, m, 0).getDate() ;
                    if ( d>numOfFebruary ) {
                        d = 1;
                        m++ ;
                    }
                }
                else if ( m == 1 || m == 3 || m == 5 || m == 7 || m == 8 || m == 10 || m == 12 ) {
                    if ( d>31 ) {
                        d = 1;
                        m++ ;
                    }
                }
                else if ( m == 4 || m == 6 || m == 9 || m == 11 ) {
                    if ( d>30 ) {
                        d = 1;
                        m++ ;
                    }
                }
                if ( m > 12 ) {
                    m = 1;
                    y++;
                }
                array.push(this.generateDateString(y,m,d, this.$i18n.locale));
            }
        },
        dateDataChange (gIndex, iIndex) {
            // console.info('list', gIndex, iIndex);
            this.scrollingDate[0].currentIndex = iIndex;
        },
        ticketsDataChange (gIndex, iIndex) {
            // console.info('list', gIndex, iIndex);
            this.scrollingTickets[gIndex].currentIndex = iIndex;
            if ( gIndex === 0 ) {
                this.formData.tickets.adult = this.scrollingTickets[gIndex].list[iIndex];
            }
            else if ( gIndex === 1 ) {
                this.formData.tickets.child = this.scrollingTickets[gIndex].list[iIndex];
            }
        },
        setTargetAndShowScroll( targetName ) {

            if ( this.innerWidth > 576 )
                return 0;

            if ( targetName === 'departure-date' ) {
                this.smoothPickerStatus.selecting = 'date';
                this.smoothPickerStatus.target = 'departure';
                this.scrollingDate[0].currentIndex = this.getCurrentScrollIndex(
                    this.formData.dates.departure + ' (' + this.getWeekday(this.formData.dates.departure) + ')',
                    this.scrollingDate[0].list
                );
            }
            else if ( targetName === 'return-date' ) {
                this.smoothPickerStatus.selecting = 'date';
                this.smoothPickerStatus.target = 'return';
                this.scrollingDate[0].currentIndex = this.getCurrentScrollIndex(
                    this.formData.dates.return + ' (' + this.getWeekday(this.formData.dates.return) + ')',
                    this.scrollingDate[0].list
                );
            }
            else if ( targetName === 'tickets' ) {
                this.smoothPickerStatus.selecting = 'tickets';
                this.smoothPickerStatus.target = 'tickets';
                this.scrollingTickets[0].currentIndex = this.getCurrentScrollIndex(
                    this.formData.tickets.adult,
                    this.scrollingTickets[0].list
                );
                this.scrollingTickets[1].currentIndex = this.getCurrentScrollIndex(
                    this.formData.tickets.child,
                    this.scrollingTickets[1].list
                );
            }
            this.smoothPickerStatus.showing = true;
        },
        getCurrentScrollIndex( value, list ) {
            console.log(value);
            for ( let i = 0 ; i < list.length ; i++ ) {
                console.log(list[i]);
                if ( value === list[i] ) {
                    return i;
                }
            }
            return 0;
        },
        confirmScroll() {
            if ( this.smoothPickerStatus.target === 'departure' ) {
                let index = this.scrollingDate[0].currentIndex ;
                this.formData.dates.departure = this.scrollingDate[0].list[index].substring(0,10);
            }
            else if ( this.smoothPickerStatus.target === 'return' ) {
                let index = this.scrollingDate[0].currentIndex ;
                this.formData.dates.return = this.scrollingDate[0].list[index].substring(0,10);
            }
            else if ( this.smoothPickerStatus.target === 'tickets' ) {
                let indexF = this.scrollingTickets[0].currentIndex ;
                this.formData.tickets.adult = this.scrollingTickets[0].list[indexF];
                let indexH = this.scrollingTickets[1].currentIndex ;
                this.formData.tickets.child = this.scrollingTickets[1].list[indexH];
            }
            this.smoothPickerStatus.showing=false;
            this.smoothPickerStatus.selecting = "";
            this.smoothPickerStatus.target = "";
        },
        controlNums(emitData) {
            if ( emitData.isPlus && emitData.emitName === 'adult-num' ) {
                if(this.formData.tickets.adult + this.formData.tickets.child >= this.totalTicketNum ){
                    return
                }
                this.formData.tickets.adult++ ;
            }
            else if ( !emitData.isPlus && emitData.emitName === 'adult-num' && this.formData.tickets.adult > 0) {
                this.formData.tickets.adult-- ;
            }
            if ( emitData.isPlus && emitData.emitName === 'child-num' ) {
                if(this.formData.tickets.adult + this.formData.tickets.child >= this.totalTicketNum ){
                    return
                }
                this.formData.tickets.child++ ;
            }
            else if ( !emitData.isPlus && emitData.emitName === 'child-num' && this.formData.tickets.child > 0) {
                this.formData.tickets.child-- ;
            }
        },
        checkTicketInput(inputName){
            if(Number(this.formData.tickets.adult)<= this.totalTicketNum &&  Number(this.formData.tickets.child) <= this.totalTicketNum && Number(this.formData.tickets.adult) + Number(this.formData.tickets.child)<= this.totalTicketNum){
                this.formData.tickets.child = Number(this.formData.tickets.child)
            this.formData.tickets.adult = Number(this.formData.tickets.adult)
                return
            }
            // if(Number(this.formData.tickets.adult)>=40 ||  Number(this.formData.tickets.child) >=40 ){
            //     if(inputName === 'adult'){
            //         this.formData.tickets.adult = 40
            //         return
            //     }else{
            //         this.formData.tickets.child  = 40
            //         return
            //     }  
               
            // }
            if(Number(this.formData.tickets.adult) + Number(this.formData.tickets.child) >= this.totalTicketNum){
              if(inputName === 'adult'){
                this.formData.tickets.adult = this.totalTicketNum - this.formData.tickets.child 
              }else{
                this.formData.tickets.child = this.totalTicketNum - this.formData.tickets.adult 
              }
              this.formData.tickets.child = Number(this.formData.tickets.child)
            this.formData.tickets.adult = Number(this.formData.tickets.adult)
            }
            
        }

    },
    mounted() {
        this.init();
    }

}
</script>
<i18n>
{
    "en": {
        "round-trip": "round trip",
        "outbound": "Departure",
        "inbound": "Return",
        "station-donggang": "Donggang",
        "station-liuqiu": "Liuqiu",
        "ticket-type-adult": "Regular",
        "ticket-type-child": "Concession",
        "ticket-type-regular": "Regular ticket",
        "ticket-type-Concession": "Concession ticket",
        "btn-online-booking": "Search",
        "online-booking": "Booking",
        "passengers": "Passengers",
        "from-station": "From",
        "destination-station": "Destination",
        "label-select-date": "Date",
        "label-select-number": "Tickets",
        "onlineBookingNoTravelCard": "Online booking is not available for the Travel Card",
        "useTravelCardAtVenue": "To use the Travel Card, please purchase directly at the venue"
    },
    "zh-TW": {    
        "round-trip": "來回票",
        "outbound": "去程",
        "inbound": "回程",
        "station-donggang": "東港",
        "station-liuqiu": "小琉球",
        "ticket-type-adult": "全票",
        "ticket-type-child": "半票",
        "ticket-type-regular": "全票",
        "ticket-type-Concession": "半票",
        "btn-online-booking": "訂票",
        "online-booking": "線上售票",
        "passengers": "乘客人數",
        "from-station": "去程",
        "destination-station": "回程",
        "label-select-date": "選擇日期",
        "label-select-number": "選擇票數",
        "onlineBookingNoTravelCard": "線上訂票不適用國旅卡",
        "useTravelCardAtVenue": "如需使用國旅卡請至現場直接購票"
    }
}
</i18n>
<style scope>
@import './style/select-date.css';
</style>