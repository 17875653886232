<template>
    <div>
        <Introduce />
    </div>
</template>
<script>
import Introduce from '@/components/pages/home/main-view/introduce/introduce.vue'
export default {
    components: {
        Introduce
    }
}
</script>