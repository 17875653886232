<template>
    <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center align-items-center my-4 leuco-pagination">
            <li class="page-item mx-pagination">
                <router-link 
                 class="page-link leuco-arrow-left" 
                 :to="prePage" 
                 :event="prePage!=='' ? 'click' : ''"
                >
                    pre
                </router-link>
            </li>
            <li 
             class="page-item mx-pagination" 
             v-for="(item, index) in page" :key="index">
                <router-link 
                 class="page-link"
                 :class="{ 'active': isActive(parseInt(index)+1) }"
                 :to="'?p='+(parseInt(index)+1)"
                >
                {{parseInt(index)+1}}
                </router-link>
            </li>
            
            <li class="page-item mx-pagination">
                <router-link 
                 class="page-link leuco-arrow-right" 
                 :to="nextPage" 
                 :event="nextPage!=='' ? 'click' : ''"
                >
                    next
                </router-link>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    props: {
        totalCount: Number,
        capacity: Number,
    },
    computed: {
        page() {
            return Math.floor(this.totalCount/this.capacity) + (( this.totalCount%this.capacity > 0 )?1:0);
        },
        prePage() {
            let rqp = this.$route.query.p===undefined?1:parseInt(this.$route.query.p);
            return rqp>1?'?p='+(rqp-1):'';
        },
        nextPage() {
            let rqp = this.$route.query.p===undefined?1:parseInt(this.$route.query.p);
            return rqp<this.page?'?p='+(rqp+1):'';
        },
    },
    methods: {
        isActive( index ) {
            let rqp = this.$route.query.p===undefined?1:parseInt(this.$route.query.p);
            index = parseInt(index);
            if ( rqp === index ) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    mounted() {
    }
}
</script>
<style scope>
@import './style/pagination.css';
</style>