<template>
    <div>
        <div class="title-row fs-0p8rem">
            <span class="title">
                {{ $t('title-order-info') }}
                <span 
                 v-if="loadComp && !$route.query.is_booking"
                 class="mx-sm-1">
                 {{ data.paid ? $t('title-paid') : $t('title-not-paid') }}
                </span> 
            </span>
            <span class="title sub"><span class="mx-sm-2 mx-1">{{ $t('title-order-number') }}</span><span class="text-danger">{{ $route.query.o_n }}</span></span>
        </div>
        <div v-if="loadComp">
            <div class="content py-sm-4 py-3">
                <div 
                 class="text-main-color text-start mb-sm-3 mb-0 fs-6" 
                 v-if="!isOrderRefunded"
                >
                    <span>{{ $t("hint-pay-now") }}</span><span class="fw-bold">「{{ data.paid ? $t("btn-picked-up-now") : $t("btn-pay-now") }}」</span>
                </div>
                <ScheduleRoute 
                 :scheduleOutbound="data.schedule.DL"
                 :stationOutbound="'donggang'"
                 :scheduleInbound="data.schedule.LD"
                 :stationInbound="'liuqiu'"
                />
            </div>
            <TicketsTable 
             :fares="data.fares"
             :paid="data.paid"
             @ticket-count="getEmitTicketCount"
            />
            <div class="bottom">
                <div class="group justify-content-between">
                    <div class="sub-group">
                        <button
                        class="btn-bottom w-m-hf"
                        v-if="!$route.query.is_booking"
                        v-on:click="btn_pre()"
                        >
                            <span class="text-nowrap">{{ $t('btn-pre') }}</span>
                        </button>
                        <span 
                         class="d-sm-flex d-none align-items-center fw-bold"
                         v-if="$route.query.is_booking&&canPickedTickets"
                        >
                            {{ $t("hint-take-ticket") }}
                        </span>
                        
                        <button
                        class="btn-bottom w-m-hf d-sm-none d-flex"
                        v-if="!$route.query.is_booking&&canCancelOrder"
                        data-bs-toggle="modal" 
                        :data-bs-target="'#'+modalIDs[1]"
                        >
                            <span class="text-nowrap">{{ $t('btn-cancel-order') }}</span>
                        </button>
                        <button
                        class="btn-bottom w-m-hf d-sm-none d-flex"
                        v-if="!$route.query.is_booking&&data.paid&&canRefundOrder"
                        data-bs-toggle="modal" 
                        :data-bs-target="'#'+modalIDs[3]"
                        >
                            <span class="text-nowrap">{{ $t('btn-refund-order') }}</span>
                        </button>
                        <button
                        class="btn-bottom w-m-f mx-sm-3 mx-0 d-sm-none d-flex justify-content-center"
                        data-bs-toggle="modal" 
                        :data-bs-target="'#'+modalIDs[0]"
                        v-if="$route.query.is_booking&&!data.paid"
                        >
                            <span class="text-nowrap">{{ $t('btn-pay-later') }}</span>
                        </button>
                    </div>
                    <div class="sub-group">
                        <button
                        class="btn-bottom w-m-hf d-sm-flex d-none mx-3"
                        v-if="!$route.query.is_booking&&canCancelOrder"
                        data-bs-toggle="modal" 
                        :data-bs-target="'#'+modalIDs[1]"
                        >
                            <span class="text-nowrap">{{ $t('btn-cancel-order') }}</span>
                        </button>
                        <button
                        class="btn-bottom w-m-f mx-sm-3 mx-0 d-sm-flex d-none justify-content-center"
                        data-bs-toggle="modal" 
                        :data-bs-target="'#'+modalIDs[0]"
                        v-if="$route.query.is_booking&&!data.paid"
                        >
                            <span class="text-nowrap">{{ $t('btn-pay-later') }}</span>
                        </button>
                        <button
                        class="btn-bottom w-m-hf d-sm-flex d-none mx-3"
                        v-if="!$route.query.is_booking&&data.paid&&canRefundOrder"
                        data-bs-toggle="modal" 
                        :data-bs-target="'#'+modalIDs[3]"
                        >
                            <span class="text-nowrap">{{ $t('btn-refund-order') }}</span>
                        </button>
                        <button 
                        class="btn-bottom main w-m-f"
                        v-on:click="btn_payNow()"
                        v-if="!data.paid"
                        >
                            <span class="text-nowrap">{{ $t('btn-pay-now') }}</span>
                        </button>
                        <button 
                        class="btn-bottom main w-m-f"
                        v-if="data.paid&&canPickedTickets"
                        data-bs-toggle="modal" 
                        :data-bs-target="'#'+modalIDs[2]"
                        >
                            <span class="text-nowrap">{{ $t('btn-picked-up-now') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="content h-100 d-flex justify-content-center align-items-center py-0 has-min-height">
            <div class="spinner-border text-primary my-3" style="width: 5rem; height: 5rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <ModalPayLater
         :targetID="modalIDs[0]"
         :orderNumber="$route.query.o_n"
         :ticketCount="ticketCount"
        />
        <ModalCancelOrder
         :targetID="modalIDs[1]"
         :orderNumber="$route.query.o_n"
         :orderInfo="$route.query.o_i"
        />
        <ModalTakeTicket
         :targetID="modalIDs[2]"
        />
        <ModalRefundOrder
         :targetID="modalIDs[3]"
         :orderNumber="$route.query.o_n"
         :orderInfo="$route.query.o_i"
        />
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";
import ScheduleRoute from "@/components/layout-components/shop-panel/schedule-route/schedule-route.vue";
import TicketsTable from "@/components/layout-components/shop-panel/tickets-table/tickets-table.vue";
import ModalPayLater from "@/components/layout-components/shop-panel/modal-pay-later/modal-pay-later.vue";
import ModalCancelOrder from "@/components/layout-components/shop-panel/modal-cancel-order/modal-cancel-order.vue";
import ModalTakeTicket from "@/components/layout-components/shop-panel/modal-take-ticket/modal-take-ticket.vue";
import ModalRefundOrder from "@/components/layout-components/shop-panel/modal-refund-order/modal-refund-order.vue";

export default {
    components: {
        ScheduleRoute,
        TicketsTable,
        ModalPayLater,
        ModalCancelOrder,
        ModalTakeTicket,
        ModalRefundOrder,
    },
    data() {
        return {
            ts: {},
            loadComp: false,
            data: {},
            modalIDs: [
                "id-pay-later",
                "id-cancel-order",
                "id-take-ticket",
                "id-refund-order",
            ],
            ticketCount: 0,
        }
    },
    computed: {
        canPickedTickets() {
            if ( this.data.paid )
                return this.ts.canPickedTickets(this.data.fares) ;
            else 
                return false ;
        },
        canCancelOrder() {
            return !this.data.paid ? true : false ;
        },
        canRefundOrder() {
            return this.ts.canRefundOrder(this.data.fares) ;
        }
    },
    methods: {
        getEmitTicketCount( emitData ) {
            this.ticketCount = emitData ;
        },
        btn_pre() {
            this.$router.push({
                name: "Order_Search",
            });
        },
        btn_payNow() {
            this.$router.push({
                name: "Order_Pay",
                query: {
                    o_n: this.$route.query.o_n,
                    o_i: this.$route.query.o_i,
                    is_booking: this.$route.query.is_booking,
                }
            })
        },

    },
    async mounted() {
        this.ts = new TicketService();
        if ( this.$route.query.o_n !== undefined && this.$route.query.o_i !== undefined ) {
            let orderInfoResult = await this.ts.getOrderInfo(this.$route.query.o_n, this.$route.query.o_i) ;
            if ( orderInfoResult.status === 200 ) {
                this.loadComp = true ;
                this.data = orderInfoResult.data ;
                console.log(`dataaaaa`,this.data)
            }
            else {
                this.$router.push({
                    name: "Order_Search",
                });
            }
        }
        else {
            this.$router.push({
                name: "Order_Search",
            });
        }

    }
}
</script>
<i18n>
{
    "en": {
        "title-order-info": "Order Detail",
        "title-order-number": "Order numbers",
        "title-paid": "(Paid)",
        "title-not-paid": "(Unpaid)",
        "title-refunded": "(Refunded)",
        "hint-pay-now": "※ Please confirm that all is correct, and click the",
        "hint-take-ticket": "※ Please follow the content of the SMS to complete the ticket collection operation",
        "btn-pre": "Previous",
        "btn-cancel-order": "Cancel",
        "btn-pay-later" : "Pay later",
        "btn-pay-now": "Pay now",
        "btn-picked-up-now": "Get tickets now",
        "btn-refund-order": "Refund"
    },
    "zh-TW": {
        "title-order-info": "訂單明細",
        "title-order-number": "訂單編號",
        "title-paid": "(已付款)",
        "title-not-paid": "(未付款)",
        "title-refunded": "(訂單已退)",
        "hint-pay-now": "※ 請確認所有資訊是否無誤，確認後請按",
        "hint-take-ticket": "※ 請依循簡訊內容完成取票作業",
        "btn-pre": "返回",
        "btn-cancel-order": "取消訂單",
        "btn-pay-later" : "稍後付款",
        "btn-pay-now": "立刻付款",
        "btn-picked-up-now": "立即取票",
        "btn-refund-order": "取消訂單"
    }
}
</i18n>