<template>
  <div class="px-0 fadex-component row">
    <div
      id="carouselIndicatorsNews-desktop"
      class="carousel slide leuco-carousel-desktop carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner indicators-with-text-desc">
        <div
          v-for="(n,index) in pages()"
          :key="index"
          class="carousel-item"
          :class="{ active: index === 0 }"
        >
          <div
            v-for="(item, key) in takeArray(items, parseInt(index), 4)"
            :key="key"
            class="fadex-class col-xxl-10 col-10 mx-auto"
            :class="{ 'fadex-class-hasLink': item.link !== undefined }"
            :data-aos="key % 2 === 1 ? 'fade-left' : 'fade-right'"
            data-aos-easing="linear"
            v-on:click="clickLink(item.link)"
          >
            <div
              class="d-flex align-items-center justify-content-between text-start"
              :class="{'translateY-hover':item.type === 'article'}"
              v-if="key % 2 === 0"
            >
              <div class="fadex-img">
                <img :src="imgPath(main_img[parseInt(key)].imgsrc)" class="img-fluid" />
              </div>
              <Article
                v-if="item.type === 'article'"
                :content="item.content"
                :date="item.date"
                :imgsrc="item.imgsrc"
                :alignSide="'right'"
              />
              <div class="fadex-text" v-else-if="item.type === 'describe'">
                <div class="title">{{ item.content.title }}</div>
                <div class="desc my-2">{{ item.content.desc }}</div>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between text-end"
              :class="{'translateY-hover':item.type === 'article'}"
              v-if="key % 2 === 1"
            >
              <Article
                v-if="item.type === 'article'"
                :content="item.content"
                :date="item.date"
                :imgsrc="item.imgsrc"
                :alignSide="'left'"
              />
              <div class="fadex-text" v-else-if="item.type === 'describe'">
                <div class="title">{{ item.content.title }}</div>
                <div class="desc my-2">{{ item.content.desc }}</div>
              </div>
              <div class="fadex-img">
                <img :src="imgPath(main_img[parseInt(key)].imgsrc)" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-indicators customer-style">
        <button
          v-for="(n,index) in pages()"
          :key="index"
          type="button"
          data-bs-target="#carouselIndicatorsNews-desktop"
          :data-bs-slide-to="index"
          :class="{ active: index === 0 }"
          aria-current="true"
          :aria-label="'Slide ' + index"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
// import Parallax from '@/components/layout-components/parallax-component/parallax-component.vue';
import Article from "@/components/layout-components/article-component/article.vue";
export default {
  props: {
    items: Array,
    main_img: Array,
  },
  components: {
    // Parallax,
    Article,
  },
  methods: {
    clickLink(url) {
      if (url !== undefined) this.$router.push(url);
    },
    takeArray(array, page, capacity) {
      let result = [];
    //   console.log(page,capacity);
      for (let i = page * capacity; i < (page+1) * capacity; i++) {
        result.push(array[i]);
      }
    //   console.log(result);
      return result;
    },
    pages() {
        return (this.items.length / 4) + ((this.items.length % 4>0)?1:0);
    }
  },

};
</script>
<style scope>
@import "./style/fadex-component.css";
</style>
