<template>
    <div>
        <QA/>
    </div>
</template>
<script>
import QA from '@/components/pages/home/leuco-qa-view/qa/qa.vue'
export default {
    components: {
        QA
    }
}
</script>