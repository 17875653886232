<template>
    <div>
        <ExploreLiuqiu/>
    </div>
</template>
<script>
import ExploreLiuqiu from '@/components/pages/home/explore-liuqiu-view/explore-liuqiu/explore-liuqiu.vue'
export default {
    components: {
        ExploreLiuqiu
    }
}
</script>