<template>
    <div class="row bg-wave py-4">

        <div class="w-layout-1020-limit mx-auto">

            <h5 class="text-title-color my-4">{{$t('Q&A-title')}}</h5>

            <div class="accordion mx-auto my-4" id="accordion">

                <div
                v-for="(item, index) in takeListRange( parseInt($route.query.p), pageCapacity, questionList )" :key="index"
                class="accordion-item my-2"
                >
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button qa-button collapsed"
                        type="button" data-bs-toggle="collapse"
                        :data-bs-target="'#collapse-'+generatorTargetID(parseInt(index))"
                        v-on:click="clickExtend(parseInt(index))"
                        aria-expanded="false"
                        :aria-controls="'collapse-'+generatorTargetID(parseInt(index))">
                            Q{{ (parseInt($route.query.p===undefined?1:$route.query.p)-1) * pageCapacity + parseInt(index) + 1 }}:{{ item.title }}
                        </button>
                    </h2>
                    <div
                    :id="'collapse-'+generatorTargetID(parseInt(index))"
                    class="accordion-collapse collapse"
                    :class="{'show':item.expanded}"
                    :aria-labelledby="'heading'+generatorTargetID(parseInt(index))"
                    data-bs-parent="#accordion"
                    >
                        <div class="accordion-body text-start">
                            <div class="text-pre-wrap text-start">{{item.content}}</div>
                            <div v-if="item.url!==undefined" >
                                <div v-for="(u ,index) in item.url" :key="index">
                                    {{u.title}} : <a :href="u.link" target="_blank">{{ $t("click-here") }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Pagination
                :totalCount="questionList.length"
                :capacity="pageCapacity"
                />

            </div>
        </div>


    </div>
</template>
<script>
import Pagination from '@/components/layout-components/pagination/pagination.vue';
export default {
    components: {
        Pagination
    },
    data() {
        return {
            questionList: [],
            pageCapacity: 8,
            pageQuestionList: [],
        }
    },
    watch: {
        '$route': function() {
            this.clickExtend( this.pageCapacity * (this.$route.query.p-1) );
        }
    },
    methods: {
        init() {
            this.setQuestionList();
        },
        setQuestionList() {
            let ql = this.$t(`Q&As`);
            for ( let i = 0 ; ql[i] !== undefined ; i++ ) {
                let temp = {
                    title: ql[i].title,
                    content: ql[i].content,
                    url: ql[i].url,
                    emit: ql[i].emit,
                    expanded: i>0?false:true,
                };
                this.questionList.push(temp);
            }
        },
        generatorTargetID( index ) {
            let rqp = this.$route.query.p===undefined?1:parseInt(this.$route.query.p);
            return (rqp-1)*this.pageCapacity + index;
        },
        clickExtend( index ) {
            for ( let i = 0 ; i < this.questionList.length ; i++ ) {
                if ( index === i )
                    this.questionList[i].expanded = true;
                else
                    this.questionList[i].expanded = false;
            }
        }
    },
    mounted() {
        this.init();
    }
}
</script>
<i18n src="./locale/locale.json"></i18n>
<style scope>
@import './style/qa.css';
</style>
