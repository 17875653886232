<template>
  <div class="pt-cover-navbar">
    <MainBanner
    />
    <router-view />
    <BookingSection/>
    <FixedButton/>
  </div>
</template>

<script>
import MainBanner from '@/components/layout-components/main-banner/main-banner.vue';
import BookingSection from '@/components/pages/home/booking-section/booking-section.vue';
import FixedButton from '@/components/layout-components/fixed-buttons/fixed-buttons.vue';
export default {
  name: 'Home',
  components: {
    MainBanner,
    BookingSection,
    FixedButton,
  },
  methods: {
    init() {
    }
  },
  mounted() {
    this.init();
  },
}
</script>

