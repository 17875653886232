<template>
    <div 
     class="modal fade" 
     :id="targetID" 
     data-bs-backdrop="static" 
     data-bs-keyboard="false" 
     tabindex="-1" 
     aria-labelledby="staticBackdropLabel" 
     aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content rounded-10px shop-panel">
                <div class="modal-header p-0 border-0">
                    <div class="title-row w-100 d-sm-flex d-none">
                        <span class="title">
                            {{ $t('title-cancel-order') }}
                        </span>
                    </div>
                    <!-- <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div v-if="!isCanceling&&!isSuccess&&!isFail">
                    <div class="modal-body py-sm-0 pb-0">
                        <div class="fw-bold text-main-color my-3 fs-1p4rem">
                            {{ $t("modal-cancel-order-body-text-1") }}
                        </div>
                        <div>
                            {{ $t("modal-cancel-order-body-text-2-1") }} 
                            <ins class="fw-bold text-main-color">{{ $t("modal-cancel-order-body-text-2-2") }}</ins>
                        </div>
                        <div>
                            {{ $t("modal-cancel-order-body-text-3") }} 
                        </div>

                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                            class="btn-bottom w-m-hf main-no-extend mx-1"
                            data-bs-dismiss="modal"
                            >
                                <span class="text-nowrap">{{ $t('btn-no') }}</span>
                        </button>
                        <button 
                            class="btn-bottom w-m-hf mx-1"
                            v-on:click="cancelOrder()"
                            >
                                <span class="text-nowrap">{{ $t('btn-yes') }}</span>
                        </button>
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Understood</button> -->
                    </div>
                </div>
                <div v-if="isSuccess">
                    <div class="modal-body py-sm-0 pb-0">
                        <div class="fw-bold text-main-color my-3 fs-1p4rem">
                            {{ $t("modal-cancel-order-body-text-success") }}
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                            class="btn-bottom w-m-f main"
                            data-bs-dismiss="modal"
                            v-on:click="backHomePage()"
                            >
                                <span class="text-nowrap">{{ $t('btn-to-homepage') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="isFail">
                    <div class="modal-body pt-sm-3 pb-0">
                        <div>
                            <img class="model-icon" src="@/assets/icons/icon_error.svg">
                        </div>
                        <div class="fw-bold text-danger my-3 fs-1p4rem">
                            {{ $t("modal-cancel-order-body-text-fail-1") }}
                        </div>
                        <div>
                            {{ $t("modal-cancel-order-body-text-fail-2") }}
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center bottom border-0 d-flex">
                        <button 
                            class="btn-bottom w-m-f main"
                            data-bs-dismiss="modal"
                            v-on:click="refreshPage()"
                            >
                                <span class="text-nowrap">{{ $t('btn-refresh') }}</span>
                        </button>
                    </div>
                </div>
                <div v-if="isCanceling">
                    <div class="modal-body py-3">
                        <div class="fw-bold text-main-color my-3 fs-1p4rem">
                            {{ $t("modal-cancel-order-body-text-loading") }}
                        </div>
                        <div class="spinner-border text-main-color" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="py-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";

export default {
    props: {
        targetID: String,
        orderNumber: String,
        orderInfo: String,
    },
    data() {
        return {
            ts: {},
            isCanceling: false,
            isSuccess: false,
            isFail: false,
        }
    },
    methods: {
        async cancelOrder() {
            this.isCanceling = true ;
            if ( this.orderNumber !== "" && this.orderInfo !== "" ) {
                
                let result = await this.ts.sendCancelRequest(this.orderNumber, this.orderInfo) ;

                if ( result.status === 200 && result.data[0].success ) {
                    this.isSuccess = true ;
                    this.isCanceling = false ;
                }
                else {
                    this.isFail = true ;
                    this.isCanceling = false ; 
                }

            }
        },
        backHomePage() {
            this.$router.push({
                name: "MainPage",
            });
        },
        refreshPage() {
            window.location.href = this.$route.fullPath ;
        }
    },
    mounted() {
        this.ts = new TicketService() ;
    }
}
</script>
<i18n>
{
    "en": {
        "title-cancel-order": "Cancel the order",
        "modal-cancel-order-body-text-1": "Are you sure you want to cancel this order?",
        "modal-cancel-order-body-text-2-1": "Cancellation will exit the window and",
        "modal-cancel-order-body-text-2-2": "cannot restore the order,",
        "modal-cancel-order-body-text-3": "you have to order again.",
        "modal-cancel-order-body-text-loading": "Loading",
        "modal-cancel-order-body-text-success": "Cancel successful",
        "modal-cancel-order-body-text-fail-1": "Cancel failed",
        "modal-cancel-order-body-text-fail-2": "Please try it later"
    },
    "zh-TW": {
        "title-cancel-order": "取消訂單",
        "modal-cancel-order-body-text-1": "您確定要取消訂單嗎？",
        "modal-cancel-order-body-text-2-1": "取消後將退出視窗",
        "modal-cancel-order-body-text-2-2": "無法恢復訂單",
        "modal-cancel-order-body-text-3": "需要重新進行訂票作業。",
        "modal-cancel-order-body-text-loading": "讀取中",
        "modal-cancel-order-body-text-success": "取消成功",
        "modal-cancel-order-body-text-fail": "取消失敗",
        "modal-cancel-order-body-text-fail-2": "請稍後再試"
    }
}
</i18n>