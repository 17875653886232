export default class dateUtils {
    getToday() {
        let date = new Date();
        let yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // 1月為 0 ;
        let dd = date.getDate();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        return yyyy + "-" + mm + "-" + dd;
    }
    getNowTime() {
        let today = new Date() ;
        let hr = today.getHours() ;
        let min = today.getMinutes() ;
        if (hr < 10) {
            hr = '0' + hr ;
        }
        if (min < 10) {
            min = '0' + min ;
        }
        return ( hr + ':' + min ) ; 
    }
} 
