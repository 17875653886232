<template>
    <div class="px-0">
        <h4 class="text-title-color my-4">{{ $t("explore-liuqiu") }}</h4>
        <div class="breakpoint-over-w576px px-0">
            <FadeXComponent
             :main_img="[
                { 
                    imgsrc: 'island_intro_img1',
                },
                { 
                    imgsrc: 'island_intro_img2',
                },
                { 
                    imgsrc: 'island_intro_img3',
                },
                { 
                    imgsrc: 'island_intro_img4',
                },
             ]"
             :items="[
                { 
                    content: {
                        title: $t('section-explore-liuqiu-content.0.title'),
                        desc: $t('section-explore-liuqiu-content.0.desc')
                    },
                    type: 'describe',
                },
                { 
                    content: {
                        title: $t('section-explore-liuqiu-content.1.title'),
                        desc: $t('section-explore-liuqiu-content.1.desc')
                    },
                    type: 'describe',
                },
                { 
                    content: {
                        title: $t('section-explore-liuqiu-content.2.title'),
                        desc: $t('section-explore-liuqiu-content.2.desc')
                    },
                    type: 'describe',
                },
                { 
                    content: {
                        title: $t('section-explore-liuqiu-content.3.title'),
                        desc: $t('section-explore-liuqiu-content.3.desc')
                    },
                    type: 'describe',
                },
            ]"
            />
        </div>
        <div class="breakpoint-lower-w576px px-0">
            <CarouselSlideIndicator
             :items="[
                { 
                    imgsrc_main: imgMPath('news_main_img1'),
                    content: {
                        title: $t('section-explore-liuqiu-content.0.title'),
                        desc: $t('section-explore-liuqiu-content.0.desc')
                    },
                    type: 'describe',
                },
                { 
                    imgsrc_main: imgMPath('news_main_img2'),
                    content: {
                        title: $t('section-explore-liuqiu-content.1.title'),
                        desc: $t('section-explore-liuqiu-content.1.desc')
                    },
                    type: 'describe',
                },
                { 
                    imgsrc_main: imgMPath('news_main_img3'),
                    content: {
                        title: $t('section-explore-liuqiu-content.2.title'),
                        desc: $t('section-explore-liuqiu-content.2.desc')
                    },
                    type: 'describe',
                },
                { 
                    imgsrc_main: imgMPath('news_main_img4'),
                    content: {
                        title: $t('section-explore-liuqiu-content.3.title'),
                        desc: $t('section-explore-liuqiu-content.3.desc')
                    },
                    type: 'describe',
                },
            ]"
            />
        </div>
        <div class="cooperation-store-link">
            <button 
             class="mainbtn" 
             v-on:click="$router.push({
                 name: 'Cooperate-Stores',
             })"
            >
                {{ $t('route-cooperate') }}
            </button>
        </div>
    </div>
</template>
<script>
import FadeXComponent from '@/components/layout-components/fadex-component/fadex-component.vue';
import CarouselSlideIndicator from '@/components/layout-components/carousel-slide-with-indicators/carousel-slide-with-indicators.vue';

export default {
    components: {
        FadeXComponent,
        CarouselSlideIndicator
    },
    data() {
        return {
            
        }
    },
    
}
</script>
<i18n>
{
    "en": {
        "section-explore-liuqiu-content": [
            {
                "title": "Vase Rock",
                "desc": "The Vase Rock is the most salient landmark of Lambai island . It is a coral-limestone, approximately nine meters high. This coral rock has a large head and a narrow body due to sea erosion. With the tip of the rock covered with wild plants like headache trees and turpeths, the rock looks like a vase full of flowers. This is why it’s called the Vase Rock."
            },
            {
                "title": "Shanfu Fishing Harbour",
                "desc": "Shanfu is a natural harbor formed by coral rocks and one of the three fishing ports on the island. Nearby you’ll find several sea-eroded sights along the coastline such as potholes and pillars resembling giant mushrooms."
            },
            {
                "title": "Go Snorkeling with sea turtles",
                "desc": "While you don’t necessarily need to get your feet wet to see sea turtles in Lambai island, a visit to this dreamy island would simply not be complete without a snorkeling adventure!"
            },
            {
                "title": "Catch the sunset at Sunset Pavilion",
                "desc": "Watching an epic sunset is the perfect way to end a day exploring Lambai island. And the Sunset Pavilion on the western side of the island is the best place to watch the sun dwindle over the Taiwan Strait!"
            }
        ]
    },
    "zh-TW": {    
        "section-explore-liuqiu-content": [
            {
                "title": "您絕對不可錯過小琉球最顯眼、最著名的地標－花瓶石",
                "desc": "花瓶石為小琉球島上最著名的景點，其形成原因是原本的海岸珊瑚礁被地殼隆起作用所抬升，然後頸部受到長期的海水侵蝕作用，因此形成上粗下細，類似花瓶的特殊造型。"
            },
            {
                "title": "您絕對不可錯過擁有最多元且完整的海蝕地形－小琉球杉福漁港",
                "desc": "位於小琉球西側的杉福港附近有許多特殊的海蝕小地景，是由於海水長期停留在下方凹陷的位置，長期侵蝕拍打而形成。例如海蝕柱（與花瓶石相似）、海蝕凹壁與海蝕壺穴。"
            },
            {
                "title": "來小琉球必玩的水上活動，親子共游浮潛，與海龜同遊一片",
                "desc": "來到小琉球旅遊如果沒有浮潛就太可惜了，小琉球海域是全世界海龜生態最豐富的地區，每年都有上萬隻海龜遊回到初生地產卵繁衍下一代，一同與綠蠵龜一共遊湛藍大海!"
            },
            {
                "title": "海天一色的美景宜人，夕陽西下時蔚藍天際染上橙黃紫紅的色調",
                "desc": "落日亭位於琉球嶼西側，面臨壯麗的台灣海峽，是島上觀賞落日的好去處。在落日分，火紅的太陽緩緩地落入海水面之下，煞是好看！"
            }
        ]
    }
}
</i18n>
<style scope>
@import './style/explore-liuqiu.css';
</style>