<template>
    <div>
        <SelectDate />
    </div>
</template>
<script>
import SelectDate from '@/components/pages/shop/booking/select-date/select-date.vue';
export default {
    components: {
        SelectDate,
    }
}
</script>