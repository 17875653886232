<template>
  <div
    id="carouselIndicatorsNews"
    class="carousel slide leuco-carousel"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner indicators-with-text-desc">
      <div 
       v-for="(item, index) in items" :key="index"
       class="carousel-item"
       :class="{'active':index===0}"
       v-on:click="clickLink(item.link)"
      >
        <img :src="item.imgsrc_main" class="d-block w-100" alt="..." />
        <div 
         v-if="item.type==='article'"
         class="mx-auto my-4">
          <Article
            :content="item.content"
            :date="item.date"
            :imgsrc="item.imgsrc_sub"
            :alignSide="'left'"
          />
        </div>
        <div class="text-start mx-4" v-else-if="item.type==='describe'">
          <div class="title">{{item.content.title}}</div>
          <div class="desc">{{item.content.desc}}</div>
        </div>
        
      </div>
    </div>
    <div class="carousel-indicators customer-style">
      <button
        v-for="(item, index) in items" :key="index"
        type="button"
        data-bs-target="#carouselIndicatorsNews"
        :data-bs-slide-to="index"
        :class="{'active':index===0}"
        aria-current="true"
        :aria-label="'Slide '+index"
      ></button>
    </div>
  </div>
</template>
<script>
import Article from '../article-component/article.vue';

export default {
    props: {
        items: Array,
    },
    components: {
      Article
    },
    methods: {
        clickLink(url){
            if ( url !== undefined )
                this.$router.push(url);
        }
    }
    /*
    example:
    { 
        imgsrc_main: imgPath(articles[0].imgsrc_main),
        imgsrc_sub: imgPath(articles[0].imgsrc_sub),
        content: articles[0].content,
        date: articles[0].date,
        type: 'article',
    },
    { 
        imgsrc_main: imgPath(articles[0].imgsrc_main),
        content: {
            title: "Title",
            desc: "Desc",
        }
        type: 'describe',
    },
    */
}
</script>
<style scope>
@import './style/carousel-slide-with-indicators.css';
</style>