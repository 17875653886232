<template>
    <div>
        <SelectShip />
    </div>
</template>
<script>
import SelectShip from '@/components/pages/shop/booking/select-ship/select-ship.vue';
export default {
    components: {
        SelectShip,
    }
}
</script>