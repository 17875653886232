<template>
  <div class="row main-banner">
    <div
      id="carouselFade"
      class="carousel slide carousel-fade px-0"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div
         v-for="(item, index) in carouselArray" :key="index"
         class="carousel-item"
         :class="{'active':index===0}"
         data-bs-interval="5000"
        >
          <div class="cover-text">
            <div v-html="item.text"></div>
          </div>
          <img :src="getSizeBanner(item)" class="d-block w-100" />
          
        </div>
      </div>
      <div class="carousel-indicators customer-style">
        <button
          v-for="(item, index) in carouselArray" :key="index"
          type="button"
          data-bs-target="#carouselFade"
          :data-bs-slide-to="index"
          :class="{'active':index===0}"
          aria-current="true"
          :aria-label="'Slide '+index"
        ></button>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      carouselArray: [
        {
          img375: require('@/assets/375/375_banner_1.jpg'),
          img768: require('@/assets/768/768_banner_1.jpg'),
          img1920: require('@/assets/1920/1920_banner_1.jpg'),
          text: this.$t('banner.1'),
        },
        {
          img375: require('@/assets/375/375_banner_2.jpg'),
          img768: require('@/assets/768/768_banner_2.jpg'),
          img1920: require('@/assets/1920/1920_banner_2.jpg'),
          text: this.$t('banner.2'),
        },
        {
          img375: require('@/assets/375/375_banner_3.jpg'),
          img768: require('@/assets/768/768_banner_3.jpg'),
          img1920: require('@/assets/1920/1920_banner_3.jpg'),
          text: this.$t('banner.3'),
        },
        {
          img375: require('@/assets/375/375_banner_4.jpg'),
          img768: require('@/assets/768/768_banner_4.jpg'),
          img1920: require('@/assets/1920/1920_banner_4.jpg'),
          text: this.$t('banner.4'),
        }
      ]
    };
  },
  watch: {
    
  },
  methods: {
    getSizeBanner(item) {
      if ( this.innerWidth > 768 ) {
        return item.img1920 ;
      }
      else if ( this.innerWidth > 576 ) {
        return item.img768 ;
      }
      else {
        return item.img375 ;
      }
    },
  },
  mounted() {
    this.resizeEventHandler() ;
  },
};
</script>
<style scope>
@import "./style/main-banner.css";
</style>
<i18n>
{
  "en": {
    "banner": {
      "1": "<p>Come with me as we explore <br/>beautiful Lambai island together!</p>",
      "2": "<p>Come with me as we explore <br/>beautiful Lambai island together!</p>",
      "3": "<p>Come with me as we explore <br/>beautiful Lambai island together!</p>",
      "4": "<p>Come with me as we explore <br/>beautiful Lambai island together!</p>"
    }
  },
  "zh-TW": {
    "banner": {
      "1": "<p>藍天伴遊白浪助興<br/>藍白航運開啟您的海陸壯遊</p>",
      "2": "<p>藍天伴遊白浪助興<br/>藍白航運開啟您的海陸壯遊</p>",
      "3": "<p>藍天伴遊白浪助興<br/>藍白航運開啟您的海陸壯遊</p>",
      "4": "<p>藍天伴遊白浪助興<br/>藍白航運開啟您的海陸壯遊</p>"
    }
  }
}
</i18n>
