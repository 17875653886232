<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-color header p-0">
            <div class="text-start">
                <span
                class="navbar-toggler border-0" type="button"
                data-bs-toggle="offcanvas"
                :data-bs-target="'#'+navTarget"
                aria-controls="offcanvasNavbar"
                aria-expanded="false" aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </span>
                <a class="navbar-brand" href="#">
                    <img
                    v-on:click="IconClick()"
                    :src="
                     $i18n.locale==='zh-TW'?
                     require('@/assets/1920/1920_header_logo.svg')
                    :require('@/assets/1920/1920_header_logo_en.svg')"
                    class="nav-logo-brand" alt=""
                    >
                </a>
            </div>

            <div class="d-flex">
              <NavCollapse
                  :navItems="navItems"
              />
              <div class="d-flex justify-content-end">
                    <div class="locales align-items-center justify-content-end mx-4">
                        <div
                        v-on:click="ChangeLanguage('zh-TW')"
                        class="language"
                        :class="{'active':($i18n.locale==='zh-TW')?true:false}"
                        >中文</div>
                        <div class="px-2">|</div>
                        <div
                        v-on:click="ChangeLanguage('en')"
                        class="language"
                        :class="{'active':($i18n.locale==='en')?true:false}"
                        >English</div>
                    </div>
                    <a href="#sec-booking" class="no-text-decoration" v-if="!$route.meta.bookingDirect">
                        <div class="main-btn align-items-center justify-content-center">
                            <span class="text fw-bold px-4">{{ $t('header.booking') }}</span>
                        </div>
                    </a>
                    <router-link :to="{ name: 'Booking_Date' }" class="no-text-decoration" v-if="$route.meta.bookingDirect">
                        <div class="main-btn align-items-center justify-content-center">
                            <span class="text fw-bold px-4">{{ $t('header.booking') }}</span>
                        </div>
                    </router-link>

                </div>
            </div>

        </nav>
    </div>

</template>
<script>
import NavCollapse from './components/nav-collapse.vue';
export default {
    props: {
        isHome: Boolean,
    },
    components: { NavCollapse },
    data() {
        return {
            navTarget: "offcanvasNavbar",
            navItems: [
                {
                    name: 'route-news',
                    type: 'route',
                    to: "/#news",
                    disabled: false
                },
                {
                    name: 'route-leucosapphire',
                    type: 'route',
                    to: "/introduce-leucosapphire",
                    disabled: false
                },
                {
                    name: 'route-explore-liuqiu',
                    type: 'route',
                    to: "/explore-liuqiu",
                    disabled: false
                },
                {
                    name: 'route-booking-search',
                    type: 'route',
                    to: "/shop/order/search",
                    disabled: false
                },
                {
                    name: 'route-qa',
                    type: 'route',
                    to: "/q-a",
                    disabled: false
                },
                {
                    name: 'route-pdpa',
                    type: 'route',
                    to: "/pdpa",
                    disabled: false
                }
            ]
        }
    },
    methods: {
        IconClick() {
            this.$router.push({path: '/home'}).catch(
                err => {
                    if ( err )
                        console.log('view same path');
                }
            );
        }
    }
}
</script>
<i18n>
{
    "en": {
        "header": {
            "booking": "Booking",
            "menu": "Menu"
        }
    },
    "zh-TW": {
        "header": {
            "booking": "馬上訂票",
            "menu": "目錄"
        }
    }
}
</i18n>
<style scope>
@import './style/header.css';
</style>
