<template>
    <div>
        <Leuco/>
    </div>
</template>
<script>
import Leuco from '@/components/pages/home/leuco-intro-view/leuco-introduce/leuco-introduce.vue'
export default {
    components: {
        Leuco
    }
}
</script>