<template>
  <div id="app" class="h-min-100">
    <div class="container-fluid bg-content">
      <Header
       :isHome="isHome"
      />
      <router-view />

    </div>
    <Footer/>
    
  </div>
</template>
<script>
import Header from '@/components/layout-components/header/header.vue';
import Footer from '@/components/layout-components/footer/footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isHome: false,
    }
  },
  watch:{
    '$route': function(){
      this.checkRoute() ;
    }
  },
  methods: {
    init() {
      this.checkRoute();
    },
    checkRoute() {
      if ( this.$route.name === 'Home' ) {
        this.isHome = true ;
      }
      else {
        this.isHome = false ;
      }
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
