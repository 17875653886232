<template>
    <div>
        <div
            v-for="(item, key) in takeArray(items, parseInt($route.query.p-1), 4)"
            :key="key"
            class="home-news-article mx-0"
            :class="{ 'hasLink': item.link !== undefined }"
            data-aos-easing="linear"
            v-on:click="clickLink(item.link)"
        >
            <div
              class="text-start my-4"
              :class="{'translateY-hover':item.type === 'article'}"
            >
              <Article
                v-if="item.type === 'article'"
                :content="item.content"
                :date="item.date"
                :imgsrc="item.imgsrc"
                :alignSide="key % 2 === 1?'left':'right'"
              />
            </div>
        </div>
        <Pagination
         :totalCount="items.length"
         :capacity="pageCapacity"
        />
    </div>
</template>
<script>
import Article from "@/components/layout-components/article-component/article.vue";
import Pagination from "@/components/layout-components/pagination/pagination.vue";
export default {
  props: {
    items: Array,
  },
  components: {
    Article,
    Pagination,
  },
  data() {
      return {
          pageCapacity: 4,
      }
  },
  methods: {
    clickLink(url) {
      if (url !== undefined) this.$router.push(url);
    },
    takeArray(array, pageNum, capacity) {
      let result = [];
    //   console.log(page,capacity);
      let page = isNaN(pageNum) ? 0 : pageNum ;
      for (let i = page * capacity; i < (page+1) * capacity; i++) {
        result.push(array[i]);
      }
    //   console.log(result);
      return result;
    },
  },
  mounted() {
  }

};
</script>
