import Vue from 'vue';
import Vuex from 'vuex';
import Lang from './modules/lang.module.js'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
       lang: Lang
    },
  
})

export default store
  