<template>
    <div 
     class="d-sm-flex d-block article justify-content-center justify-content-lg-around align-items-center"
     :class="{ 'left':alignSide==='left', 'right':alignSide==='right' }"
     >
        <div class="img-div" v-if="alignSide==='right'||innerWidth<=576">
            <img
                :src="imgsrc" 
                class="img"
            />
        </div>
        
        <div 
         class="text-start text-field">
            <div 
             class="text-pre-wrap content"
            > 
                <div class="date">{{ date }}
                </div>
                <p class="ellipsis my-4">{{ content }}</p>
            </div>
        </div>
        <div class="img-div" v-if="alignSide==='left'&&innerWidth>576">
            <img 
                :src="imgsrc" 
                class="img"
            >
        </div>
        
    </div>
</template>
<script>
export default {
    props: {
        content: String,
        date: String,
        imgsrc: String,
        alignSide: String,
    },
    mounted() {
        this.resizeEventHandler() ;
    }
}
</script>
<style scope>
@import './style/article.css';
</style>