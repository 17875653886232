<template>
    <div>
        <div class="title-row">
            <span class="title">{{ $t('title-select-ship') }}</span>
            <RouteTitle
             :isRoundTrip="true"
            />
        </div>
        <div 
         class="content"
         v-if="outbound.length>0&&inbound.length>0"
        >
            <div class="d-flex justify-content-start align-items-end">
                <span class="text-main-color fw-bold title">{{ $t("outbound-time") }}</span>
                <span class="text-title-color mx-3 fw-normal sub-title">{{ $route.query.o_d }} ({{ getWeekday($route.query.o_d, $i18n.locale) }})</span>
            </div>
            <ShipList 
             :shiptimeArray="outbound"
             :capacity="capacity"
             :paddingSide="'py'"
             :target="'outbound'"
             @emit-select-time="selectTime"
            />

            <div class="d-flex justify-content-start align-items-end">
                <span class="text-main-color fw-bold title">{{ $t("inbound-time") }}</span>
                <span class="text-title-color mx-3 fw-normal sub-title">{{ $route.query.i_d }} ({{ getWeekday($route.query.i_d, $i18n.locale) }})</span>
            </div>
            <ShipList 
             :shiptimeArray="inbound"
             :capacity="capacity"
             :paddingSide="'pt'"
             :target="'inbound'"
             @emit-select-time="selectTime"
            />
            <hr class="mb-0">
        </div>
        <div 
         class="content my-4"
         v-else
        >
            <div class="spinner-border text-primary my-4" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="bottom">
            <div class="group justify-content-between">
                <button
                 class="btn-bottom w-m-f"
                 v-on:click="$router.push({name:'Booking_Date'})"
                >
                    <span class="text-nowrap">{{ $t('btn-pre') }}</span>
                </button>
                <button 
                 class="btn-bottom main w-m-f"
                 :class="{ 'disabled': this.result.o_t === '' || this.result.i_t === '' }"
                 v-on:click="btn_next()"
                >
                    <span class="text-nowrap">{{ $t('btn-next') }}</span>
                </button>
            </div>
            
        </div>
    </div>
</template>
<script>
import TicketService from "@/services/TicketService/TicketService.js";
import ShipList from "./components/shiplist.vue";
import RouteTitle from "@/components/layout-components/shop-panel/route-title/route-title.vue";

export default {
    components: {
        ShipList,
        RouteTitle,
    },
    data() {
        return {
            capacity: 4,
            outbound: [],
            inbound: [],
            result: {
                o_t: "",
                i_t: ""
            },
            ts: {}
        }
    },
    methods: {
        async getShippingList() {
            if ( this.$route.query.o_d !== null && this.$route.query.i_d !== null
              && this.$route.query.t_a !== null && this.$route.query.t_c !== null ) {
                let ticketNum = parseInt(this.$route.query.t_a) + parseInt(this.$route.query.t_c);
                if ( ticketNum > 0 ) {
                    // this.outbound = await this.ts.getRouteShippingList(this.$route.query.o_d, "DL", ticketNum);
                    // this.inbound = await this.ts.getRouteShippingList(this.$route.query.i_d, "LD", ticketNum);
                    this.outbound = await this.ts.getRouteShippingList(this.$route.query.o_d, "DL");
                    this.inbound = await this.ts.getRouteShippingList(this.$route.query.i_d, "LD");
                }
                if ( this.outbound.length === 0 || this.inbound.length === 0 ) {
                    return false ;
                }
            }
            return true;
        },
        selectTime( emitData ) {
            // console.log(emitData);
            if ( emitData.target === 'outbound' ) {
                this.outbound = this.getActiveTimeBtn(emitData.data.data, this.outbound, emitData.target);
                this.setDisable();
            }
            else if ( emitData.target === 'inbound' ) {
                this.inbound = this.getActiveTimeBtn(emitData.data.data, this.inbound, emitData.target);
            }
        },
        setDisable() {
            if ( this.$route.query.o_d >= this.$route.query.i_d ) {
                for ( let i = 0 ; i < this.inbound.length ; i++ ) {

                    this.inbound[i].disable = this.ts.checkTime( this.$route.query.o_d, this.result.o_t, this.$route.query.i_d , this.inbound[i].data.time ) ;
                    // if ( this.inbound[i].data.booking + ( this.$route.query.t_a + this.$route.query.t_c ) >= this.inbound[i].data.quota ) {
                    //     this.inbound[i].disable = true;
                    // }
                    if ( this.inbound[i].disable ) {
                        this.inbound[i].isSelected = false;
                        this.result.i_t = "";
                    }

                }
            }
            

        },
        getActiveTimeBtn( data, array, target ) {
            for ( let i = 0 ; i < array.length ; i++ ) {
                // console.log('array:',array[i].data.time);
                if ( data.time === array[i].data.time ) {
                    // console.log(array[i]);
                    array[i].isSelected = true ;
                    if ( target === 'outbound' ) {
                        this.result.o_t = data.time ;
                    }
                    else if ( target === 'inbound' ) {
                        this.result.i_t = data.time ;
                    }
                }
                else {
                    array[i].isSelected = false ;
                }
            }
            return array;
        },
        btn_next() {
            if ( this.result.o_t !== "" && this.result.i_t !== "" )
                this.$router.push({
                    name: "Booking_FillCustomerInfo",
                    query: {
                        o_d: this.$route.query.o_d,
                        i_d: this.$route.query.i_d,
                        t_a: this.$route.query.t_a,
                        t_c: this.$route.query.t_c,
                        o_t: this.result.o_t,
                        i_t: this.result.i_t,
                    }
                }) ;
        },
    },
    async mounted() {
        this.ts = new TicketService();

        if ( await this.getShippingList() ) {
            // console.log(this.outbound, this.outbound.length);
            // console.log(this.inbound, this.inbound.length);
            console.log('get shiptime');
        }

    }
}
</script>
<i18n>
{
    "en": {
        "title-select-ship": "Choose time",
        "round-trip": "Roundtrip",
        "station-donggang": "Donggang",
        "station-liuqiu": "Liuqiu",
        "outbound-time": "Departure",
        "inbound-time": "Return",
        "btn-pre": "Back",
        "btn-next": "Next"
    },
    "zh-TW": {
        "title-select-ship": "選擇船班時間",
        "round-trip": "來回票",
        "station-donggang": "東港",
        "station-liuqiu": "小琉球",
        "outbound-time": "去程時間",
        "inbound-time": "回程時間",
        "btn-pre": "上一步",
        "btn-next": "下一步"
    }
}
</i18n>